<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Notre histoire Salésienne</li>
            </ul>
            <h2 class="cu-font-lg">Notre histoire Salésienne</h2>
        </div>
        <img src="assets/img/illustrations/in_thought.svg" alt="" style="height: 180px; width: auto"
            class="cs-display-bottomright pt-10 cs-hide-small" />
        <!-- <img src="assets/img/related/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10"> -->
    </div>

    <div class="shape9">
        <img src="assets/img/shape8.svg" alt="Lime color Plus Sign Icon" />
    </div>
</div>

<section class="products-details-area ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">

                    <img src="../assets/img/site/history/img_1.jpg" alt="image" />

                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="products-details-desc">
                    <h3>LE COLLEGE DON BOSCO, UNE LONGUE HISTOIRE</h3>
                    <p style="text-align: justify">C’est en Septembre 2018 que le collège Don Bosco s’est installé dans
                        les locaux de l’ESTIC. Mais ce déménagement est le fruit d’une longue histoire, car, durant 88
                        ans, il était auparavant installé au Prieuré de Binson, une propriété mise, par l’archevêque de
                        Reims, à la disposition des salésiens de Don Bosco pour l’ouverture d’une école avec internat.
                    </p>
                    <p style="text-align: justify">La première rentrée eut lieu le 13 Octobre 1930, avec seulement 4
                        élèves scolarisés en CM2 !... mais ils étaient déjà 28 à la fin de l’année scolaire….et 133 à la
                        rentrée de 1933, répartis en 3 niveaux (CM2, 6ème, 5ème ) et provenant des environs et de la
                        région parisienne. Les ouvertures de classe se succédèrent chaque année, et une classe de
                        Première est ouverte en 1937 !</p>
                    <p style="text-align: justify">En 1939, le Prieuré est réquisitionné par l’armée française pour être
                        transformé en hôpital militaire, puis à partir de Juin 40, il sera brièvement occupé par les
                        allemands. Dès 1941, une quarantaine d’internes reviennent, et bien des jeunes salésiens qui les
                        encadraient le jour entraient en résistance la nuit, si bien que le Prieuré reçut la Croix de
                        Guerre en 1945.</p>
                    <p style="text-align: justify">Après la guerre, le Prieuré se développe, pour attendre l’effectif de
                        212 élèves en 1966, dont la quasi-totalité est interne. A la rentrée de 1967, le second cycle
                        est supprimé, et le Prieuré devient uniquement un collège, mixte à partir de 1990. Avec
                        l’ouverture d’un collège public dans chaque canton, et le développement des transports
                        scolaires, les effectifs se mirent alors à chuter de manière régulière.</p>
                    <p style="text-align: justify">Aussi, lorsque le collège du Prieuré prend en 2016 le nom de
                        « Collège Don Bosco », ne sont-ils plus qu’une cinquantaine d’élèves, quasiment tous internes, à
                        le fréquenter. L’équipe éducative se spécialise dans l’accueil de jeunes, ayant eu un parcours
                        difficile dans les établissements scolaires fréquentés précédemment.</p>
                    <p style="text-align: justify">Les locaux du Prieuré étant devenus trop vastes pour un effectif
                        aussi réduit, et l’entretien des bâtiments trop lourd pour les finances du collège, la décision
                        a été prise, pour la rentrée 2018, de le déménager dans les locaux de l’ESTIC à Saint Dizier. Un
                        véritable lien de confiance ayant été établi avec les familles, la plupart des internes ont
                        suivi ce déménagement. Et l’aventure de cet établissement, se poursuit aujourd’hui…</p>
                    <p class="mb-0" style="text-align: justify">JM Petitclerc</p>
                    <p class="" style="text-align: justify">Salésien de Don Bosco</p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="products-area pt-100 pb-70 bg-f5f1ed">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Shop</span>
            <h2>Related Products</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a href="#">
                            <img
                                src="../assets/img/products/img4.jpg"
                                class="main-image"
                                alt="image"
                            />
                        </a>
                    </div>
                    <div class="products-content">
                        <h3>
                            <a routerLink="/single-products"
                                >Stop and Take a Second</a
                            >
                        </h3>
                        <div class="price">
                            <span class="new-price">$150</span>
                        </div>
                        <div class="star-rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart"
                            >Add to Cart</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/single-products">
                            <img
                                src="../assets/img/products/img5.jpg"
                                class="main-image"
                                alt="image"
                            />
                        </a>
                    </div>
                    <div class="products-content">
                        <h3>
                            <a routerLink="/single-products"
                                >Real Life Fairytale</a
                            >
                        </h3>
                        <div class="price">
                            <span class="new-price">$240</span>
                        </div>
                        <div class="star-rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart"
                            >Add to Cart</a
                        >
                    </div>
                </div>
            </div>

            <div
                class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"
            >
                <div class="single-products-box">
                    <div class="products-image">
                        <a routerLink="/single-products">
                            <img
                                src="../assets/img/products/img6.jpg"
                                class="main-image"
                                alt="image"
                            />
                        </a>
                        <div class="new-tag">New!</div>
                    </div>
                    <div class="products-content">
                        <h3>
                            <a routerLink="/single-products">Running From Me</a>
                        </h3>
                        <div class="price">
                            <span class="old-price">$150</span>
                            <span class="new-price">$100</span>
                        </div>
                        <div class="star-rating">
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                            <i class="bx bxs-star"></i>
                        </div>
                        <a routerLink="/cart" class="add-to-cart"
                            >Add to Cart</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->