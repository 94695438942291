<div class="kindergarten-main-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="kindergarten-banner-content">
                    <div class="image">
                        <img src="../assets/img/bird1.png" alt="image">
                    </div>
                    <h1>A lifetime of <span>Confidence</span> starts here</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/profile-authentication" class="default-btn-style-two"><i class="flaticon-user"></i>Join For Free</a>
                    <div class="circle-shape"><img src="../assets/img/circle.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="kindergarten-banner-image">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="image">
                                <img src="../assets/img/kids1.png" alt="image" data-speed="0.02" data-revert="true">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="image">
                                <img src="../assets/img/kids2.png" alt="image" data-speed="0.04" data-revert="true">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="image">
                                <img src="../assets/img/kids3.png" alt="image" data-speed="0.06" data-revert="true">
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="image text-left">
                                <img src="../assets/img/kids4.png" class="mt-4" alt="image" data-speed="0.08" data-revert="true">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="kindergarten-shape1"><img src="../assets/img/kindergarten-shape/k-shape1.png" alt="image"></div>
    <div class="kindergarten-shape2"><img src="../assets/img/kindergarten-shape/k-shape2.png" alt="image"></div>
    <div class="kindergarten-shape3"><img src="../assets/img/kindergarten-shape/k-shape3.png" alt="image"></div>
    <div class="kindergarten-shape4"><img src="../assets/img/kindergarten-shape/k-shape4.png" alt="image"></div>
    <div class="kindergarten-shape5"><img src="../assets/img/kindergarten-shape/k-shape5.png" alt="image"></div>
    <div class="kindergarten-shape6"><img src="../assets/img/kindergarten-shape/k-shape6.png" alt="image"></div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="kindergarten-about-image">
                    <div class="main-image">
                        <img src="../assets/img/kindergarten-about-img1.png" alt="image">
                        <img src="../assets/img/kindergarten-about-img2.png" alt="image">
                    </div>
                    <div class="shape"><img src="../assets/img/kite1.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2 class="font-weight-black">We are Kindergarten & Childhood is our Passion</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <ul class="about-list">
                        <li><span><i class='bx bx-check'></i> Transportation</span></li>
                        <li><span><i class='bx bx-check'></i> Outdoor Games</span></li>
                        <li><span><i class='bx bx-check'></i> Nutritious Food</span></li>
                        <li><span><i class='bx bx-check'></i> Best Care</span></li>
                    </ul>
                    <a routerLink="/about-5" class="default-btn-style-two"><i class="flaticon-user"></i>More About Us</a>
                </div>
            </div>
        </div>
    </div>

    <div class="kindergarten-shape7"><img src="../assets/img/kindergarten-shape/k-shape7.png" alt="image"></div>
    <div class="kindergarten-shape8"><img src="../assets/img/kindergarten-shape/k-shape8.png" alt="image"></div>
</section>

<section class="kindergarten-services-area bg-f7ebeb pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2 class="font-weight-black">Best Services for Kids</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>

        <div class="services-slides owl-carousel owl-theme">
            <div class="single-kindergarten-services-box">
                <img src="../assets/img/box-shape1.png" alt="image">

                <div class="content">
                    <div class="icon">
                        <i class="flaticon-guitar"></i>
                    </div>
                    <h3 class="font-weight-black">Music Training</h3>
                    <p>Lorem ipsum dolor sit amet, ipsum adipiscing elit elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum.</p>
                </div>
            </div>

            <div class="single-kindergarten-services-box">
                <img src="../assets/img/box-shape2.png" alt="image">

                <div class="content">
                    <div class="icon">
                        <i class="flaticon-experience"></i>
                    </div>
                    <h3 class="font-weight-black">Sports Training</h3>
                    <p>Lorem ipsum dolor sit amet, ipsum adipiscing elit elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum.</p>
                </div>
            </div>

            <div class="single-kindergarten-services-box">
                <img src="../assets/img/box-shape3.png" alt="image">

                <div class="content">
                    <div class="icon">
                        <i class="flaticon-artist"></i>
                    </div>
                    <h3 class="font-weight-black">Art Training</h3>
                    <p>Lorem ipsum dolor sit amet, ipsum adipiscing elit elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum.</p>
                </div>
            </div>

            <div class="single-kindergarten-services-box">
                <img src="../assets/img/box-shape2.png" alt="image">

                <div class="content">
                    <div class="icon">
                        <i class="flaticon-translation"></i>
                    </div>
                    <h3 class="font-weight-black">Language Training</h3>
                    <p>Lorem ipsum dolor sit amet, ipsum adipiscing elit elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum.</p>
                </div>
            </div>
        </div>

        <div class="kids-kite-image">
            <img src="../assets/img/kids-with-kite.png" alt="image">
        </div>
    </div>

    <div class="kindergarten-shape9"><img src="../assets/img/kindergarten-shape/k-shape9.png" alt="image"></div>
    <div class="kindergarten-shape10"><img src="../assets/img/kindergarten-shape/k-shape10.png" alt="image"></div>
</section>

<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Learn At Your Own Pace</span>
            <h2 class="font-weight-black">Top Selling Courses</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>

        <div class="courses-slides-two owl-carousel owl-theme">
            <div class="single-kindergarten-courses-box">
                <div class="courses-image">
                    <div class="image">
                        <img src="../assets/img/courses/kindergarten-img1.jpg" alt="image">
                    </div>
                    <div class="price"><img src="../assets/img/price-bg.png" alt="image"><span>$39</span></div>
                    <a routerLink="/single-courses-1" class="link-btn"></a>
                </div>

                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                        <span>Alex Morgan</span>
                    </div>
                    <h3 class="font-weight-black"><a routerLink="/single-courses-1">Equivalent fractions and comparing fractions</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>

            <div class="single-kindergarten-courses-box">
                <div class="courses-image">
                    <div class="image">
                        <img src="../assets/img/courses/kindergarten-img2.jpg" alt="image">
                    </div>
                    <div class="price"><img src="../assets/img/price-bg.png" alt="image"><span>$49</span></div>
                    <a routerLink="/single-courses-1" class="link-btn"></a>
                </div>

                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                        <span>Sarah Taylor</span>
                    </div>
                    <h3 class="font-weight-black"><a routerLink="/single-courses-1">Arithmetic patterns and problem solving</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>

            <div class="single-kindergarten-courses-box">
                <div class="courses-image">
                    <div class="image">
                        <img src="../assets/img/courses/kindergarten-img3.jpg" alt="image">
                    </div>
                    <div class="price"><img src="../assets/img/price-bg.png" alt="image"><span>$59</span></div>
                    <a routerLink="/single-courses-1" class="link-btn"></a>
                </div>

                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image">
                        <span>David Warner</span>
                    </div>
                    <h3 class="font-weight-black"><a routerLink="/single-courses-1">The unit circle definition of sine, and cosine</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>

            <div class="single-kindergarten-courses-box">
                <div class="courses-image">
                    <div class="image">
                        <img src="../assets/img/courses/kindergarten-img4.jpg" alt="image">
                    </div>
                    <div class="price"><img src="../assets/img/price-bg.png" alt="image"><span>$69</span></div>
                    <a routerLink="/single-courses-1" class="link-btn"></a>
                </div>

                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="../assets/img/user4.jpg" class="rounded-circle" alt="image">
                        <span>James Andy</span>
                    </div>
                    <h3 class="font-weight-black"><a routerLink="/single-courses-1">Thinking about multivariable functions</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="kindergarten-shape11"><img src="../assets/img/kindergarten-shape/k-shape11.png" alt="image"></div>
    <div class="kindergarten-shape12"><img src="../assets/img/kindergarten-shape/k-shape12.png" alt="image"></div>
</section>

<section class="feedback-area bg-6dbbbd pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2 class="font-weight-black">Our Guardian Feedback</h2>
            <p>We always give extra care to our student's skills improvements and feel excited to share our latest research and learnings!</p>
        </div>
    </div>

    <div class="container-fluid">
        <div class="feedback-slides-three owl-carousel owl-theme">
            <div class="single-kindergarten-feedback-item">
                <div class="content">
                    <img src="../assets/img/feedback-shape.png" alt="image">
                    <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                </div>

                <div class="client-info">
                    <img src="../assets/img/user1.jpg" alt="image">
                    <h3 class="font-weight-black">John Smith</h3>
                    <span>Guardian</span>
                </div>
            </div>

            <div class="single-kindergarten-feedback-item">
                <div class="content">
                    <img src="../assets/img/feedback-shape.png" alt="image">
                    <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                </div>

                <div class="client-info">
                    <img src="../assets/img/user2.jpg" alt="image">
                    <h3 class="font-weight-black">Sarah Taylor</h3>
                    <span>Guardian</span>
                </div>
            </div>

            <div class="single-kindergarten-feedback-item">
                <div class="content">
                    <img src="../assets/img/feedback-shape.png" alt="image">
                    <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                </div>

                <div class="client-info">
                    <img src="../assets/img/user3.jpg" alt="image">
                    <h3 class="font-weight-black">David Smith</h3>
                    <span>Guardian</span>
                </div>
            </div>

            <div class="single-kindergarten-feedback-item">
                <div class="content">
                    <img src="../assets/img/feedback-shape.png" alt="image">
                    <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                </div>

                <div class="client-info">
                    <img src="../assets/img/user4.jpg" alt="image">
                    <h3 class="font-weight-black">James Andy</h3>
                    <span>Guardian</span>
                </div>
            </div>

            <div class="single-kindergarten-feedback-item">
                <div class="content">
                    <img src="../assets/img/feedback-shape.png" alt="image">
                    <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                </div>

                <div class="client-info">
                    <img src="../assets/img/user5.jpg" alt="image">
                    <h3 class="font-weight-black">Max Lucy</h3>
                    <span>Guardian</span>
                </div>
            </div>

            <div class="single-kindergarten-feedback-item">
                <div class="content">
                    <img src="../assets/img/feedback-shape.png" alt="image">
                    <p>Lorem ipsum dolor sit aadamet45, consectetur adipiscing elit elit3, sed do eiusmod tempor incididunt ut labore et dolore magna. Eiusmod incididunt.</p>
                </div>

                <div class="client-info">
                    <img src="../assets/img/user6.jpg" alt="image">
                    <h3 class="font-weight-black">Harry Zayn</h3>
                    <span>Guardian</span>
                </div>
            </div>
        </div>
    </div>

    <div class="kindergarten-shape13"><img src="../assets/img/kindergarten-shape/k-shape13.png" alt="image"></div>
    <div class="kindergarten-shape14"><img src="../assets/img/kindergarten-shape/k-shape14.png" alt="image"></div>
</section>

<section class="selected-ages-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go At Your Own Pace</span>
            <h2 class="font-weight-black">Ages We Meet Kids Where They Are</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-selected-ages-box">
                    <div class="image">
                        <img src="../assets/img/selected-ages/img1.png" alt="image">
                    </div>

                    <div class="content">
                        <h3>Infants</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="ages-number">3 - 12 Months</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-selected-ages-box">
                    <div class="image">
                        <img src="../assets/img/selected-ages/img2.png" alt="image">
                    </div>

                    <div class="content">
                        <h3>Toddler</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="ages-number">1 - 3 Years</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-selected-ages-box">
                    <div class="image">
                        <img src="../assets/img/selected-ages/img3.png" alt="image">
                    </div>

                    <div class="content">
                        <h3>Preschool</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="ages-number">3 - 5 Years</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-selected-ages-box">
                    <div class="image">
                        <img src="../assets/img/selected-ages/img4.png" alt="image">
                    </div>

                    <div class="content">
                        <h3>Flex-Care</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <span class="ages-number">5 - 12 Years</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="kindergarten-shape15"><img src="../assets/img/kindergarten-shape/k-shape15.png" alt="image"></div>
    <div class="kindergarten-shape16"><img src="../assets/img/kindergarten-shape/k-shape16.png" alt="image"></div>
</section>

<section class="events-area bg-shape bg-eee8df">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Events</span>
            <h2 class="font-weight-black">Our Upcoming Events</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-4 col-md-5 p-0">
                            <div class="image bg1">
                                <img src="../assets/img/events/kindergarten-img1.jpg" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <img src="../assets/img/rectangle1.png" alt="image">
                                    <span>Wed, 03 June, 2020</span>
                                </div>
                                <h3 class="font-weight-black"><a routerLink="/single-events">Music Conference</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-map"></i>Vancover, Canada</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-4 col-md-5 p-0">
                            <div class="image bg2">
                                <img src="../assets/img/events/kindergarten-img2.jpg" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <img src="../assets/img/rectangle2.png" alt="image">
                                    <span>Thu, 04 June, 2020</span>
                                </div>
                                <h3 class="font-weight-black"><a routerLink="/single-events">Paper Plates Art</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-map"></i>Sydney, Australia</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-4 col-md-5 p-0">
                            <div class="image bg3">
                                <img src="../assets/img/events/kindergarten-img3.jpg" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <img src="../assets/img/rectangle3.png" alt="image">
                                    <span>Fri, 05 June, 2020</span>
                                </div>
                                <h3 class="font-weight-black"><a routerLink="/single-events">Imagination Classes</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-map"></i>Istanbul, Turkey</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-6">
                <div class="events-box">
                    <div class="row m-0">
                        <div class="col-lg-4 col-md-5 p-0">
                            <div class="image bg4">
                                <img src="../assets/img/events/kindergarten-img4.jpg" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-7 p-0">
                            <div class="content">
                                <div class="date">
                                    <img src="../assets/img/rectangle4.png" alt="image">
                                    <span>Sat, 06 June, 2020</span>
                                </div>
                                <h3 class="font-weight-black"><a routerLink="/single-events">Number Matching</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                <span class="location"><i class="bx bx-map"></i>New York, USA</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="kindergarten-shape17"><img src="../assets/img/kindergarten-shape/k-shape17.png" alt="image"></div>
    <div class="kindergarten-shape18"><img src="../assets/img/kindergarten-shape/k-shape18.png" alt="image"></div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our News</span>
            <h2 class="font-weight-black">Check Out Our Latest Blog</h2>
            <p>We always give extra care to our student's skills improvements and feel excited to share our latest research and learnings!</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-blog-item">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/blog/kindergarten-img1.jpg" alt="image">
                        </a>
                        <a routerLink="/single-blog-1" class="link-btn"></a>
                    </div>

                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Preschool</a>
                        <h3 class="font-weight-black"><a routerLink="/single-blog-1">Why Play Is Important in Preschool and Early</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-blog-item">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/blog/kindergarten-img2.jpg" alt="image">
                        </a>
                        <a routerLink="/single-blog-1" class="link-btn"></a>
                    </div>

                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Books</a>
                        <h3 class="font-weight-black"><a routerLink="/single-blog-1">Best Three Billy Goats Gruff Books for Preschool</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-blog-item">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/blog/kindergarten-img3.jpg" alt="image">
                        </a>
                        <a routerLink="/single-blog-1" class="link-btn"></a>
                    </div>

                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Theme</a>
                        <h3 class="font-weight-black"><a routerLink="/single-blog-1">Flashlight Literacy Activity for Your Camping Theme</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area bg-eee8df ptb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-image">
                    <img src="../assets/img/subscribe-img3.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="subscribe-content text-left">
                    <h2 class="font-weight-black">Subscribe To Our Newsletter</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <form class="newsletter-form">
                        <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                        <button type="submit" class="default-btn"><i class="flaticon-user"></i>Subscribe Now<span></span></button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="kindergarten-shape19"><img src="../assets/img/kindergarten-shape/k-shape19.png" alt="image"></div>
    <div class="kindergarten-shape20"><img src="../assets/img/kindergarten-shape/k-shape20.png" alt="image"></div>
</section>