<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>

    <div class="shape9"><img src="../assets/img/shape8.svg" alt="image"></div>
</div>

<section class="about-area-two pt-70 pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Distance Learning</span>
                    <h2>Build Your Project Management Skills Online, Anytime</h2>
                    <p>Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new skill quickly like, project team leadership or agile? Browse our most popular online courses.</p>
                    <p><strong>Grow your knowledge and your opportunities with thought leadership, training and tools.</strong></p>
                    <a href="#" class="link-btn">Explore Learning</a>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <div class="image">
                        <img src="../assets/img/about-img5.jpg" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                    <div class="shape10" data-speed="0.06" data-revert="true"><img src="../assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
</section>

<section class="funfacts-area bg-f5f7fa">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="1926">00</span></h3>
                    <p>Finished Sessions</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="3279">00</span></h3>
                    <p>Enrolled Learners</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="250">00</span></h3>
                    <p>Online Instructors</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="100">00</span>%</h3>
                    <p>Satisfaction Rate</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What People Say About Odemy</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="testimonials-slides owl-carousel owl-theme">
            <div class="single-testimonials-item">
                <img src="../assets/img/user1.jpg" class="client-img" alt="image">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <h3>John Smith</h3>
                <span>Python Developer</span>
                <div class="shape-img">
                    <img src="../assets/img/shape4.png" class="shape-1" alt="image">
                    <img src="../assets/img/shape14.png" class="shape-2" alt="image">
                    <img src="../assets/img/shape7.png" class="shape-3" alt="image">
                </div>
            </div>

            <div class="single-testimonials-item">
                <img src="../assets/img/user2.jpg" class="client-img" alt="image">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <h3>Sarah Taylor</h3>
                <span>PHP Developer</span>
                <div class="shape-img">
                    <img src="../assets/img/shape4.png" class="shape-1" alt="image">
                    <img src="../assets/img/shape14.png" class="shape-2" alt="image">
                    <img src="../assets/img/shape7.png" class="shape-3" alt="image">
                </div>
            </div>

            <div class="single-testimonials-item">
                <img src="../assets/img/user3.jpg" class="client-img" alt="image">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <h3>David Warner</h3>
                <span>QA Developer</span>
                <div class="shape-img">
                    <img src="../assets/img/shape4.png" class="shape-1" alt="image">
                    <img src="../assets/img/shape14.png" class="shape-2" alt="image">
                    <img src="../assets/img/shape7.png" class="shape-3" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="advisor-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Instructor</span>
            <h2>Course Advisor</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="advisor-slides owl-carousel owl-theme">
            <div class="single-advisor-box">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="advisor-image">
                            <img src="../assets/img/advisor/img1.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8">
                        <div class="advisor-content">
                            <h3><a routerLink="/profile">William James</a></h3>
                            <span class="sub-title">Project Management Expert</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-advisor-box">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="advisor-image">
                            <img src="../assets/img/advisor/img2.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8">
                        <div class="advisor-content">
                            <h3><a routerLink="/profile">Sarah Taylor</a></h3>
                            <span class="sub-title">Agile Project Expert</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-advisor-box">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="advisor-image">
                            <img src="../assets/img/advisor/img3.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8">
                        <div class="advisor-content">
                            <h3><a routerLink="/profile">James Andy</a></h3>
                            <span class="sub-title">QA Project Expert</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-area ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</section>