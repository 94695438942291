import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { getNewsAndEventsDetails } from 'src/app/models/endpoints';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var setInnerHtml;

@Component({
    selector: 'app-news-and-event-details',
    templateUrl: './news-and-event-details.component.html',
    styleUrls: ['./news-and-event-details.component.scss']
})
export class NewsAndEventDetailsComponent implements OnInit {

    url_data: any;
    news_id: any;
    news_title: any;
    sanitizer: DomSanitizer;

    newsEventData: any;
    newsEventDataCount: any;

    noData = true;
    loading = true;

    constructor(
        private router: Router,
        private http: HttpClient,
        sanitizer: DomSanitizer,
        private snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.params.subscribe(
            (params) => (this.url_data = params)
        );
        this.sanitizer = sanitizer;
    }

    cleanURL(oldURL: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
    }


    ngOnInit(): void {
        this.news_id = this.url_data.news_id;
        this.news_title = this.url_data.news_title;
        this.loadData();
    }


    loadData() {
        var newsId = this.news_id;
        var dataList;
        this.http.get<any>(environment.baseUrl + getNewsAndEventsDetails + newsId).subscribe({
            next: (data) => {
                if (data.status == 1) {
                    dataList = data.data;

                    const newArr = dataList.map(videoId);
                    function videoId(x) {
                        if (x.imageUrl == "" && x.videoUrl != "") {

                            if (x.videoUrl.includes("https://www.youtube.com/embed/")) {
                                var videoId = x.videoUrl.replace('https://www.youtube.com/embed/', '');
                                x.videoId = videoId;
                                x.videoThumbnail = "https://img.youtube.com/vi/" + videoId + "/0.jpg";
                                x.trueVideoUrl = "https://www.youtube.com/watch?v=" + videoId;
                                x.videoSource = "youtube";

                            } else if (x.videoUrl.includes("https://drive.google.com/file/d/")) {
                                var videoId = x.videoUrl.replace('https://drive.google.com/file/d/', '');
                                videoId = videoId.split("/preview")[0]
                                x.videoId = videoId;
                                x.videoThumbnail = "https://drive.google.com/thumbnail?id=" + videoId;
                                x.videoSource = "drive";
                            }

                        }
                        return x;
                    }

                    if (dataList.length != 0) {
                        this.noData = false;
                        this.loading = false;
                    } else {
                        this.noData = true;
                        this.loading = false;
                    }

                    this.newsEventData = newArr[0];


                    setTimeout(() => {
                        setInnerHtml('content_html_div', newArr[0].description);
                    }, 100);
                }
            },
            error: (error) => {
                this.noData = true;
                this.loading = false;
                this.openSnackBar('Oops, Something went wrong. please try again later. ', 1);
            },
        });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, 'Dismiss', { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, 'Dismiss');
        }
    }

    fixurl(txt) {
        txt = txt.toLowerCase();
        txt = txt.replace(" ", "-");
        return txt;
    }

    fixDate(dateValue) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

        const d = new Date(dateValue.replace(" ", "T") + "Z");
        var year = d.getFullYear();
        var month = months[d.getMonth()];
        var date = d.getDate();
        var day = days[d.getDay()];

        var fixed_date = day + ', ' + date + ' ' + month + ', ' + year;
        return fixed_date;
    }

    setData(dataList) {
        this.newsEventData = dataList;
        this.newsEventDataCount = this.newsEventData.length;

        if (this.newsEventDataCount == 0) {
            this.noData = true;
        }

        this.loading = false;
    }

    setTime(dateTime) {
        // let preDateTime = new Date(dateTime);
        var preDateTime = new Date(dateTime.replace(" ", "T") + "Z");
        let newTime = preDateTime.toLocaleTimeString('en-US');

        let hour = newTime.split(":")[0];
        let amPm = newTime.split(" ")[1];
        let minutes = newTime.split(":")[2].replace(amPm, '');;

        let noAmPm = newTime.replace(amPm, '');
        let noAmPmMinutes = noAmPm.replace(":" + minutes, '');
        let noMinutes = newTime.replace(":" + minutes, ' ');

        if (parseInt(hour) < 9) {
            newTime = "0" + newTime;
            noAmPm = "0" + noAmPm
            noMinutes = "0" + noMinutes
            noAmPmMinutes = "0" + noAmPmMinutes;
        }

        return noMinutes;
    }
}
