import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getAllTimetables } from 'src/app/models/endpoints';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent implements OnInit {

  flyerDetails: any;
  baseFileUrl = "http://st.dizier-admin.cagliero.eu/app/assets/img/uploads/ps-timetables/";

  noData = true;
  loading = true;

  constructor(
    private router: Router,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.callTo_timetables();
  }

  callTo_timetables() {
    let timetableObj = [];
    let DefaultImgUrl = "assets/img/defaultImage.jpg";
    let thumbImgUrl = "http://st.dizier-admin.cagliero.eu/app/assets/img/uploads/ps-timetables/thumbnails/";


    this.http.get<any>(environment.baseUrl + getAllTimetables).subscribe({
      next: (data) => {
        if (data.status == 1) {

          timetableObj = data.data;

          if (timetableObj.length != 0) {
            this.noData = false;
          }

          const timetableData = timetableObj.map(fileType);

          function fileType(x) {
            let fileExt = x.file_name.split('.').pop();
            const timetableThumb = thumbImgUrl + x.thumbnail;

            if (fileExt == "pdf" || fileExt == "PDF") {
              x.type = "Document";

              if (x.thumbnail == null || x.thumbnail == "") {
                x.thumbnail = DefaultImgUrl;
              }
              else {
                x.thumbnail = timetableThumb;
              }
            } else {
              x.type = "Image";
              if (x.thumbnail == null || x.thumbnail == "") {
                x.thumbnail = DefaultImgUrl;
              } else {
                x.thumbnail = timetableThumb;
              }
            }

            return x;
          }

          this.flyerDetails = timetableData;
          this.loading = false;
        } else {
          this.loading = false;
        }
      },
      error: (error) => {
        // this.openSnackBar('There was an error! ,' + error.message, 0);
        console.log(error.message);
      },
    });
  }

}
