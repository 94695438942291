<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>
                    <a routerLink="/terms-and-conditions">Terms & Conditions</a>
                </li>
            </ul>
            <h2>Terms & Conditions</h2>
        </div>
        <img src="assets/img/illustrations/terms.svg" alt="" style="height: 190px;width: auto;"
            class="cs-display-bottomright pt-10 cs-hide-small">
        <img src="assets/img/shapes/DBIMA_Butterfly.png" alt="Butterfly Icon" style="height: 100px;width: auto;"
            class="cs-display-bottomleft pt-10 cs-hide-small">
    </div>

    <div class="shape9"><img src="assets/img/shapes/shape8.svg" alt="Lime color Plus Sign Icon"></div>

</div>

<div class="privacy-policy-area pb-100">
    <div class="container">
        <div class="row cu-dbima-bg" style="background-size: 200px 200px;">
            <div class="col-lg-8 col-md-12">
                <div class="terms-of-service-content">
                    <p class="cu-clr-main"><i>This Terms of Service was last updated on <strong>June 01,
                                2022</strong>.</i></p>

                    <h3>1. Our website</h3>
                    <p><a href="http://st.dizier-public.cagliero.eu/">http://st.dizier-public.cagliero.eu/</a>

                    </p>
                    <blockquote class="blockquote cs-white">
                        <p>We collect certain data from you directly, like information you enter yourself, data about
                            your participation in courses. We also collect some data automatically, like information
                            about your device and what parts of our Services you interact with or spend time using.
                        </p>
                    </blockquote>

                    <h3>2. Introduction</h3>
                    <p>These Website Standard Terms And Conditions (these “Terms” or these “Website Standard Terms And
                        Conditions”) contained herein on this webpage, shall govern your use of this website, including
                        all pages within this website (collectively referred to herein below as this “Website”). These
                        Terms apply in full force and effect to your use of this Website and by using this Website, you
                        expressly accept all terms and conditions contained herein in full. You must not use this
                        Website, if you have any objection to any of these Website Standard Terms And Conditions.
                    </p>

                    <h3>3. Intellectual Property Rights</h3>
                    <p>Other than content you own, which you may have opted to include on this Website, under these
                        Terms, Collège Don Bosco Saint-Dizier and/or its licensors own all rights to the intellectual
                        property and material
                        contained in this Website, and all such rights are reserved. You are granted a limited license
                        only, subject to the restrictions provided in these Terms, for purposes of viewing the material
                        contained on this Website.</p>

                    <h3>4. Restrictions</h3>
                    <p>You are expressly and emphatically restricted from all of the following:</p>
                    <ol>
                        <li>Publishing any Website material in any media.</li>
                        <li>Selling, sublicensing and/or otherwise commercializing any Website material.</li>
                        <li>Publicly performing and/or showing any Website material.</li>
                        <li>Using this Website in any way that is, or may be, damaging to this Website.</li>
                        <li>Using this Website in any way that impacts user access to this Website.</li>
                        <li>Using this Website contrary to applicable laws and regulations, or in a way that causes, or
                            may cause, harm to the Website, or to any person or business entity.</li>
                        <li>Engaging in any data mining, data harvesting, data extracting or any other similar activity
                            in relation to this Website, or while using this Website.</li>
                        <li>Using this Website to engage in any advertising or marketing.</li>
                    </ol>
                    <p>Certain areas of this Website are restricted from access by you and Collège Don Bosco
                        Saint-Dizier may further restrict
                        access by you to any areas of this Website, at any time, in its sole and absolute discretion.
                        Any user ID and password you may have for this Website are confidential and you must maintain
                        confidentiality of such information.
                    </p>

                    <h3>5. Your Content</h3>
                    <p>In these Website Standard Terms And Conditions, “Your Content” shall mean any audio, video, text,
                        images or other material you choose to display on this Website. With respect to Your Content, by
                        displaying it, you grant Collège Don Bosco Saint-Dizier a non-exclusive, worldwide, irrevocable,
                        royalty-free,
                        sublicensable license to use, reproduce, adapt, publish, translate and distribute it in any and
                        all media.</p>
                    <p>Your Content must be your own and must not be infringing on any third party’s rights. Collège Don
                        Bosco Saint-Dizier
                        reserves the right to remove any of Your Content from this Website at any time, and for any
                        reason, without notice.</p>

                    <h3>6. No warranties</h3>
                    <p>This Website is provided “as is,” with all faults, and Collège Don Bosco Saint-Dizier makes no
                        express or implied
                        representations or warranties, of any kind related to this Website or the materials contained on
                        this Website. Additionally, nothing contained on this Website shall be construed as providing
                        consult or advice to you.</p>

                    <h3>7. Limitation of liability</h3>
                    <p>In no event shall Collège Don Bosco Saint-Dizier , nor any of its officers, directors and
                        employees, be liable to you for
                        anything arising out of or in any way connected with your use of this Website, whether such
                        liability is under contract, tort or otherwise, and Collège Don Bosco Saint-Dizier , including
                        its officers, directors
                        and employees shall not be liable for any indirect, consequential or special liability arising
                        out of or in any way related to your use of this Website.</p>

                    <h3>8. Indemnification</h3>
                    <p>You hereby indemnify to the fullest extent Collège Don Bosco Saint-Dizier from and against any
                        and all liabilities, costs,
                        demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising
                        out of or in any way related to your breach of any of the provisions of these Terms.</p>

                    <h3>9. Severability</h3>
                    <p>If any provision of these Terms is found to be unenforceable or invalid under any applicable law,
                        such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a
                        whole, and such provisions shall be deleted without affecting the remaining provisions herein.
                    </p>

                    <h3>10. Variation of Terms</h3>
                    <p>Collège Don Bosco Saint-Dizier is permitted to revise these Terms at any time as it sees fit, and
                        by using this Website
                        you are expected to review such Terms on a regular basis to ensure you understand all terms and
                        conditions governing use of this Website.
                    </p>

                    <h3>11. Assignment</h3>
                    <p>Collège Don Bosco Saint-Dizier shall be permitted to assign, transfer, and subcontract its rights
                        and/or obligations under
                        these Terms without any notification or consent required. However, .you shall not be permitted
                        to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>

                    <h3>12. Entire Agreement</h3>
                    <p>These Terms, including any legal notices and disclaimers contained on this Website, constitute
                        the entire agreement between Collège Don Bosco Saint-Dizier and you in relation to your use of
                        this Website, and
                        supersede all prior agreements and understandings with respect to the same.</p>

                    <h3>13. Governing Law & Jurisdiction</h3>
                    <p>These Terms will be governed by and construed in accordance with the laws of France, and you
                        submit to the non-exclusive jurisdiction of the federal courts located in France for the
                        resolution of any disputes.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_insight">
                        <ul>
                            <li><a routerLink="/privacy-policy">Cookie & Privacy Policy</a></li>
                            <li class="active"><a routerLink="/terms-and-conditions">Terms & Conditions</a></li>
                        </ul>
                    </section>

                    <!-- <app-widget-recent-programs></app-widget-recent-programs> -->

                </aside>
            </div>
        </div>
    </div>
</div>