<div class="banner-wrapper jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="banner-wrapper-text">
                    <h1>Build Skills With Experts Any Time, Anywhere</h1>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <a routerLink="/courses-4" class="default-btn"><i class="flaticon-user"></i>View All
                        Courses<span></span></a>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape11" data-speed="0.06" data-revert="true">
        <img src="../assets/img/banner-shape12.png" alt="image" />
    </div>
    <div class="banner-shape12" data-speed="0.06" data-revert="true">
        <img src="../assets/img/banner-shape13.png" alt="image" />
    </div>
    <div class="banner-shape13" data-speed="0.06" data-revert="true">
        <img src="../assets/img/banner-shape14.png" alt="image" />
    </div>
</div>

<section class="boxes-area bg-f5f7fa">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box-item">
                    <div class="icon">
                        <i class="flaticon-brain-process"></i>
                        <img src="../assets/img/icon-shape.png" alt="image" />
                    </div>
                    <h3>Learn the Latest Skills</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.
                    </p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box-item">
                    <div class="icon">
                        <i class="flaticon-computer"></i>
                        <img src="../assets/img/icon-shape.png" alt="image" />
                    </div>
                    <h3>Go at Your Own Pace</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.
                    </p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box-item">
                    <div class="icon">
                        <i class="flaticon-shield-1"></i>
                        <img src="../assets/img/icon-shape.png" alt="image" />
                    </div>
                    <h3>Learn from Industry Experts</h3>
                    <p>
                        Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.
                    </p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses-area bg-f5f7fa pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go At Your Own Pace</span>
            <h2>The World’s Largest Selection Of Courses</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="shorting-menu">
            <button class="filter" data-filter="all">All</button>
            <button class="filter" data-filter=".development">
                Development
            </button>
            <button class="filter" data-filter=".business">Business</button>
            <button class="filter" data-filter=".itsoftware">
                IT & Software
            </button>
            <button class="filter" data-filter=".design">Design</button>
            <button class="filter" data-filter=".animation">Animation</button>
            <button class="filter" data-filter=".illustration">
                Illustration
            </button>
        </div>

        <div class="shorting">
            <div class="row">
                <div class="col-lg-4 col-md-6 mix development business">
                    <div class="single-courses-item-box">
                        <div class="courses-image">
                            <a routerLink="/single-courses-2" class="d-block image">
                                <img src="../assets/img/courses/img12.jpg" alt="image" />
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$39</div>
                        </div>
                        <div class="courses-content">
                            <h3>
                                <a routerLink="/single-courses-2">Agile Crash Course: Agile Project
                                    Management</a>
                            </h3>
                            <div class="rating">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <div class="rating-total">5.0 (1 rating)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix business design">
                    <div class="single-courses-item-box">
                        <div class="courses-image">
                            <a routerLink="/single-courses-2" class="d-block image">
                                <img src="../assets/img/courses/img13.jpg" alt="image" />
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$59</div>
                        </div>
                        <div class="courses-content">
                            <h3>
                                <a routerLink="/single-courses-2">Vue JS 2 - The Complete Guide (incl. Vue
                                    Router & Vuex)</a>
                            </h3>
                            <div class="rating">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <div class="rating-total">5.0 (2 rating)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix itsoftware development">
                    <div class="single-courses-item-box">
                        <div class="courses-image">
                            <a routerLink="/single-courses-2" class="d-block image">
                                <img src="../assets/img/courses/img14.jpg" alt="image" />
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$69</div>
                        </div>
                        <div class="courses-content">
                            <h3>
                                <a routerLink="/single-courses-2">The Python Bible™ | Everything You Need to
                                    Program in Python</a>
                            </h3>
                            <div class="rating">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <div class="rating-total">5.0 (3 rating)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix design animation">
                    <div class="single-courses-item-box">
                        <div class="courses-image">
                            <a routerLink="/single-courses-2" class="d-block image">
                                <img src="../assets/img/courses/img15.jpg" alt="image" />
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$79</div>
                        </div>
                        <div class="courses-content">
                            <h3>
                                <a routerLink="/single-courses-2">Mathematical Foundation For Machine
                                    Learning and AI</a>
                            </h3>
                            <div class="rating">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <div class="rating-total">5.0 (4 rating)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix animation illustration">
                    <div class="single-courses-item-box">
                        <div class="courses-image">
                            <a routerLink="/single-courses-2" class="d-block image">
                                <img src="../assets/img/courses/img16.jpg" alt="image" />
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$89</div>
                        </div>
                        <div class="courses-content">
                            <h3>
                                <a routerLink="/single-courses-2">The Ultimate Drawing Course - Beginner to
                                    Advanced</a>
                            </h3>
                            <div class="rating">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <div class="rating-total">5.0 (5 rating)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 mix illustration itsoftware">
                    <div class="single-courses-item-box">
                        <div class="courses-image">
                            <a routerLink="/single-courses-2" class="d-block image">
                                <img src="../assets/img/courses/img17.jpg" alt="image" />
                            </a>
                            <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$99</div>
                        </div>
                        <div class="courses-content">
                            <h3>
                                <a routerLink="/single-courses-2">PyTorch: Deep Learning and Artificial
                                    Intelligence</a>
                            </h3>
                            <div class="rating">
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <i class="bx bxs-star"></i>
                                <div class="rating-total">5.0 (6 rating)</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape16">
        <img src="../assets/img/shape15.png" alt="image" />
    </div>
</section>

<section class="about-area-three ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Distance Learning</span>
                    <h2>Build Your Skills Online, Anytime</h2>
                    <p>
                        Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new skill quickly like, project team leadership or agile? Browse our most popular online courses.
                    </p>
                    <p>
                        <strong>Grow your knowledge and your opportunities with
                            thought leadership, training and tools.</strong>
                    </p>
                    <a routerLink="/courses-4" class="default-btn"><i class="flaticon-user"></i>View All
                        Courses<span></span></a>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="about-img">
                    <div class="image">
                        <img src="../assets/img/about-img7.png" alt="image" />
                        <img src="../assets/img/about-img8.png" alt="image" />
                    </div>
                    <div class="shape17" data-speed="0.06" data-revert="true">
                        <img src="../assets/img/shape16.png" alt="image" />
                    </div>
                    <div class="shape18" data-speed="0.06" data-revert="true">
                        <img src="../assets/img/shape17.png" alt="image" />
                    </div>
                    <div class="shape19" data-speed="0.06" data-revert="true">
                        <img src="../assets/img/shape18.png" alt="image" />
                    </div>
                    <div class="shape20" data-speed="0.06" data-revert="true">
                        <img src="../assets/img/shape19.png" alt="image" />
                    </div>
                    <div class="shape21" data-speed="0.06" data-revert="true">
                        <img src="../assets/img/shape20.png" alt="image" />
                    </div>
                    <div class="shape22" data-speed="0.06" data-revert="true">
                        <img src="../assets/img/shape21.png" alt="image" />
                    </div>
                    <div class="shape23" data-speed="0.06" data-revert="true">
                        <img src="../assets/img/shape22.png" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape3.png" alt="image" />
    </div>
    <div class="shape4" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape4.png" alt="image" />
    </div>
</section>

<section class="funfacts-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="../assets/img/funfacts-shape2.png" alt="image" />
                    <h3><span class="odometer" data-count="1926">00</span></h3>
                    <p>Finished Sessions</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="../assets/img/funfacts-shape2.png" alt="image" />
                    <h3><span class="odometer" data-count="3279">00</span></h3>
                    <p>Enrolled Learners</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="../assets/img/funfacts-shape2.png" alt="image" />
                    <h3><span class="odometer" data-count="250">00</span></h3>
                    <p>Online Instructors</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <img src="../assets/img/funfacts-shape2.png" alt="image" />
                    <h3><span class="odometer" data-count="100">00</span>%</h3>
                    <p>Satisfaction Rate</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go At Your Own Pace</span>
            <h2>Our Popular Courses</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="courses-slides owl-carousel owl-theme">
            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/single-courses-2" class="d-block image">
                        <img src="../assets/img/courses/img1.jpg" alt="image" />
                    </a>
                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                    <div class="price shadow">$39</div>
                </div>
                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image" />
                        <span>Alex Morgan</span>
                    </div>
                    <h3>
                        <a routerLink="/single-courses-2">The Data Science Course 2020: Complete Data Science
                            Bootcamp</a>
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                    </p>
                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                        <li><i class="flaticon-agenda"></i> 15 Lessons</li>
                        <li><i class="flaticon-people"></i> 145 Students</li>
                    </ul>
                </div>
            </div>

            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/single-courses-2" class="d-block image">
                        <img src="../assets/img/courses/img2.jpg" alt="image" />
                    </a>
                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                    <div class="price shadow">$49</div>
                </div>
                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image" />
                        <span>Sarah Taylor</span>
                    </div>
                    <h3>
                        <a routerLink="/single-courses-2">Java Programming Masterclass for Software
                            Developers</a>
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                    </p>
                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                        <li><i class="flaticon-agenda"></i> 20 Lessons</li>
                        <li><i class="flaticon-people"></i> 100 Students</li>
                    </ul>
                </div>
            </div>

            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/single-courses-2" class="d-block image">
                        <img src="../assets/img/courses/img3.jpg" alt="image" />
                    </a>
                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                    <div class="price shadow">$59</div>
                </div>
                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image" />
                        <span>David Warner</span>
                    </div>
                    <h3>
                        <a routerLink="/single-courses-2">Deep Learning A-Z™: Hands-On Artificial Neural
                            Networks</a>
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                    </p>
                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                        <li><i class="flaticon-agenda"></i> 20 Lessons</li>
                        <li><i class="flaticon-people"></i> 150 Students</li>
                    </ul>
                </div>
            </div>

            <div class="single-courses-box">
                <div class="courses-image">
                    <a routerLink="/single-courses-2" class="d-block image">
                        <img src="../assets/img/courses/img4.jpg" alt="image" />
                    </a>
                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                    <div class="price shadow">$54</div>
                </div>
                <div class="courses-content">
                    <div class="course-author d-flex align-items-center">
                        <img src="../assets/img/user4.jpg" class="rounded-circle" alt="image" />
                        <span>Andy Smith</span>
                    </div>
                    <h3>
                        <a routerLink="/single-courses-2">Python for Finance: Investment Fundamentals & Data
                            Analytics</a>
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.
                    </p>
                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                        <li><i class="flaticon-agenda"></i> 14 Lessons</li>
                        <li><i class="flaticon-people"></i> 211 Students</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="courses-info">
            <p>
                Get the most dedicated consultation for your life-changing course. Earn a certification for your effort and passion
                <a routerLink="/profile-authentication">Join Free Now</a>.
            </p>
        </div>
    </div>
</section>

<section class="feedback-area bg-fffaf3 ptb-100">
    <div class="container">
        <div class="feedback-slides-two owl-carousel owl-theme">
            <div class="single-feedback-box">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed tempor incididunt ut labore et dolore.
                </p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image" />
                    <div class="title">
                        <h3>John Smith</h3>
                        <span>Python Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-box">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed tempor incididunt ut labore et dolore.
                </p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image" />
                    <div class="title">
                        <h3>Sarah Taylor</h3>
                        <span>PHP Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-box">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit, sed tempor incididunt ut labore et dolore.
                </p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image" />
                    <div class="title">
                        <h3>David Warner</h3>
                        <span>QA Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="divider2"></div>
    <div class="divider3"></div>
    <div class="shape2" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape2.png" alt="image" />
    </div>
    <div class="shape3" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape3.png" alt="image" />
    </div>
    <div class="shape4" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape4.png" alt="image" />
    </div>
    <div class="shape9" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape8.svg" alt="image" />
    </div>
</section>

<section class="advisor-area bg-f9f9f9 pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Course Advisor</span>
            <h2>Meet Our World-class Instructors</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="advisor-slides-two owl-carousel owl-theme">
            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img4.jpg" alt="image" />
                    <ul class="social-link">
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-facebook"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a>
                        </li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">James Andy</a></h3>
                    <span>Project Management Expert</span>
                </div>
            </div>

            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img5.jpg" alt="image" />
                    <ul class="social-link">
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-facebook"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a>
                        </li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">Jassica Hische</a></h3>
                    <span>Illustrator Expert</span>
                </div>
            </div>

            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img6.jpg" alt="image" />
                    <ul class="social-link">
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-facebook"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a>
                        </li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">Alister Cock</a></h3>
                    <span>QA Project Expert</span>
                </div>
            </div>

            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img7.jpg" alt="image" />
                    <ul class="social-link">
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-facebook"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a>
                        </li>
                        <li>
                            <a href="#" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a>
                        </li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">Lina Ninja</a></h3>
                    <span>QA Project Expert</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="premium-access-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-premium-access></app-premium-access>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape3.png" alt="image" />
    </div>
    <div class="shape4" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape4.png" alt="image" />
    </div>
    <div class="shape8" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape7.png" alt="image" />
    </div>
</section>

<section class="partner-area bg-f0f2f5 ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">News</span>
            <h2>Check Out Our Latest Blog</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="blog-slides owl-carousel owl-theme">
            <div class="single-blog-post-box">
                <div class="post-image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="../assets/img/blog/img1.jpg" alt="image" />
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blog-4" class="category">Education</a>
                    <h3>
                        <a routerLink="/single-blog-1">University Admissions Could Face Emergency
                            Controls</a>
                    </h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image" />
                                <span>Alex Morgan</span>
                            </div>
                        </li>
                        <li>
                            <i class="flaticon-calendar"></i> April 30, 2020
                        </li>
                    </ul>
                </div>
            </div>

            <div class="single-blog-post-box">
                <div class="post-image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="../assets/img/blog/img2.jpg" alt="image" />
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blog-4" class="category">Online</a>
                    <h3>
                        <a routerLink="/single-blog-1">Online Learning Can Prepare Students For A Fast</a>
                    </h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image" />
                                <span>Sarah Taylor</span>
                            </div>
                        </li>
                        <li>
                            <i class="flaticon-calendar"></i> April 29, 2020
                        </li>
                    </ul>
                </div>
            </div>

            <div class="single-blog-post-box">
                <div class="post-image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="../assets/img/blog/img3.jpg" alt="image" />
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blog-4" class="category">Learning</a>
                    <h3>
                        <a routerLink="/single-blog-1">As Learning Moves Online, Trigger Warnings Must
                            Too</a>
                    </h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image" />
                                <span>David Warner</span>
                            </div>
                        </li>
                        <li>
                            <i class="flaticon-calendar"></i> April 28, 2020
                        </li>
                    </ul>
                </div>
            </div>

            <div class="single-blog-post-box">
                <div class="post-image">
                    <a routerLink="/single-blog-1" class="d-block">
                        <img src="../assets/img/blog/img3.jpg" alt="image" />
                    </a>
                </div>
                <div class="post-content">
                    <a routerLink="/blog-4" class="category">Learning</a>
                    <h3>
                        <a routerLink="/single-blog-1">As Learning Moves Online, Trigger Warnings Must
                            Too</a>
                    </h3>
                    <ul class="post-content-footer d-flex justify-content-between align-items-center">
                        <li>
                            <div class="post-author d-flex align-items-center">
                                <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image" />
                                <span>David Warner</span>
                            </div>
                        </li>
                        <li>
                            <i class="flaticon-calendar"></i> April 28, 2020
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="blog-post-info">
            <p>
                Get into details now?​
                <a routerLink="/blog-4">View all posts</a>
            </p>
        </div>
    </div>
</section>

<section class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>

    <div class="shape4" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape4.png" alt="image" />
    </div>
    <div class="shape13" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape12.png" alt="image" />
    </div>
    <div class="shape14" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape13.png" alt="image" />
    </div>
    <div class="shape15" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape14.png" alt="image" />
    </div>
</section>
