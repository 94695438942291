<div class="navbar-area navArea">
    <div class="odemy-responsive-nav">
        <div class="container">
            <div class="odemy-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo/logo_500_500.png" alt="logo" class=" cs-padding"
                            style="height: 60px;width: auto;" /></a>
                </div>
            </div>
        </div>
    </div>

    <div class="odemy-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/logo_500_500.png" alt="logo"
                        style="height: 60px;width: auto;" /></a>

                <div class="collapse navbar-collapse mean-menu">

                    <!-- <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Home <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/e-learning-school"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >eLearning School</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/vendor-certification-etraining"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Vendor Certification eTraining</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/online-training-school"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Online Training School</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/distance-learning"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Distance Learning</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/language-school"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Language School</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/modern-schooling"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Modern Schooling</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/yoga-training"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Yoga Training</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/health-coaching"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Health Coaching</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/kindergarten"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Kindergarten</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Pages <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        href="javascript:void(0)"
                                        class="nav-link"
                                        >About Us
                                        <i class="bx bx-chevron-right"></i
                                    ></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a
                                                routerLink="/about-1"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >About Us 01</a
                                            >
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                routerLink="/about-2"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >About Us 02</a
                                            >
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                routerLink="/about-3"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >About Us 03</a
                                            >
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                routerLink="/about-4"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >About Us 04</a
                                            >
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/success-story"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Success Story</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/teacher"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Teacher</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/gallery"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Gallery</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/faq"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FAQ</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/contact"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Contact Us</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/profile-authentication"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Login/Register</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/error"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >404 Error</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/coming-soon"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Coming Soon</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/purchase-guide"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Purchase Guide</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/privacy-policy"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Privacy Policy</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/terms-of-service"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Terms of Service</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item megamenu">
                            <a href="javascript:void(0)" class="nav-link"
                                >Courses <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col">
                                                <ul class="megamenu-submenu">
                                                    <li>
                                                        <a
                                                            routerLink="/courses-1"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Grid 01</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-2"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Grid 02</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-3"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Grid 03</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-4"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Grid 04</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-5"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses List</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-6"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Masonry</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-7"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Right
                                                            Sidebar</a
                                                        >
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="col">
                                                <ul class="megamenu-submenu">
                                                    <li>
                                                        <a
                                                            routerLink="/single-courses-1"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Single Layout 01</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/single-courses-2"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Single Layout 02</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/categories"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses
                                                            Categories</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/membership-levels"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Membership
                                                            Levels</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/become-a-teacher"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Become a Teacher</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/profile"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Profile</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/profile-quiz"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Profile Quiz</a
                                                        >
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="col">
                                                <ul class="megamenu-submenu">
                                                    <li>
                                                        <a
                                                            routerLink="/courses-1"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Grid 01</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-2"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Grid 02</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-3"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Grid 03</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-4"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Grid 04</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-5"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses List</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-6"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Masonry</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/courses-7"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses Right
                                                            Sidebar</a
                                                        >
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="col">
                                                <ul class="megamenu-submenu">
                                                    <li>
                                                        <a
                                                            routerLink="/single-courses-1"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Single Layout 01</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/single-courses-2"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Single Layout 02</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/categories"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Courses
                                                            Categories</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/membership-levels"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Membership
                                                            Levels</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/become-a-teacher"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Become a Teacher</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/profile"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Profile</a
                                                        >
                                                    </li>

                                                    <li>
                                                        <a
                                                            routerLink="/profile-quiz"
                                                            routerLinkActive="active"
                                                            [routerLinkActiveOptions]="{
                                                                exact: true
                                                            }"
                                                            >Profile Quiz</a
                                                        >
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="row m-0">
                                            <div
                                                class="col-lg-2 col-sm-4 col-md-4 col-6 p-0"
                                            >
                                                <div
                                                    class="single-category-widget"
                                                >
                                                    <div class="icon">
                                                        <i
                                                            class="bx bx-code-alt"
                                                        ></i>
                                                    </div>
                                                    <h3>Development</h3>
                                                    <span class="sub-title"
                                                        >60 Courses</span
                                                    >
                                                    <a
                                                        routerLink="/courses-1"
                                                        class="link-btn"
                                                    ></a>
                                                </div>
                                            </div>

                                            <div
                                                class="col-lg-2 col-sm-4 col-md-4 col-6 p-0"
                                            >
                                                <div
                                                    class="single-category-widget"
                                                >
                                                    <div class="icon">
                                                        <i
                                                            class="bx bx-camera"
                                                        ></i>
                                                    </div>
                                                    <h3>Photography</h3>
                                                    <span class="sub-title"
                                                        >21 Courses</span
                                                    >
                                                    <a
                                                        routerLink="/courses-2"
                                                        class="link-btn"
                                                    ></a>
                                                </div>
                                            </div>

                                            <div
                                                class="col-lg-2 col-sm-4 col-md-4 col-6 p-0"
                                            >
                                                <div
                                                    class="single-category-widget"
                                                >
                                                    <div class="icon">
                                                        <i
                                                            class="bx bx-layer"
                                                        ></i>
                                                    </div>
                                                    <h3>Design</h3>
                                                    <span class="sub-title"
                                                        >58 Courses</span
                                                    >
                                                    <a
                                                        routerLink="/courses-3"
                                                        class="link-btn"
                                                    ></a>
                                                </div>
                                            </div>

                                            <div
                                                class="col-lg-2 col-sm-4 col-md-4 col-6 p-0"
                                            >
                                                <div
                                                    class="single-category-widget"
                                                >
                                                    <div class="icon">
                                                        <i
                                                            class="bx bxs-flag-checkered"
                                                        ></i>
                                                    </div>
                                                    <h3>Language</h3>
                                                    <span class="sub-title"
                                                        >99 Courses</span
                                                    >
                                                    <a
                                                        routerLink="/courses-4"
                                                        class="link-btn"
                                                    ></a>
                                                </div>
                                            </div>

                                            <div
                                                class="col-lg-2 col-sm-4 col-md-4 col-6 p-0"
                                            >
                                                <div
                                                    class="single-category-widget"
                                                >
                                                    <div class="icon">
                                                        <i
                                                            class="bx bx-health"
                                                        ></i>
                                                    </div>
                                                    <h3>Fitness</h3>
                                                    <span class="sub-title"
                                                        >21 Courses</span
                                                    >
                                                    <a
                                                        routerLink="/courses-5"
                                                        class="link-btn"
                                                    ></a>
                                                </div>
                                            </div>

                                            <div
                                                class="col-lg-2 col-sm-4 col-md-4 col-6 p-0"
                                            >
                                                <div
                                                    class="single-category-widget"
                                                >
                                                    <div class="icon">
                                                        <i
                                                            class="bx bx-line-chart"
                                                        ></i>
                                                    </div>
                                                    <h3>Business</h3>
                                                    <span class="sub-title"
                                                        >49 Courses</span
                                                    >
                                                    <a
                                                        routerLink="/courses-6"
                                                        class="link-btn"
                                                    ></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Events <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/events"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Events</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-events"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Events Details</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Shop <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/products-list-1"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Product List 01</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/products-list-2"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Product List 02</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/cart"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Cart</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/checkout"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Checkout</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/single-products"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Product Details</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Blog <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-1"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Grid (2 in Row)</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-2"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Grid (3 in Row)</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-3"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Grid (Full Width)</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-4"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Right Sidebar</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/blog-5"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Masonry (3 in Row)</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        href="javascript:void(0)"
                                        class="nav-link"
                                        >Single Post
                                        <i class="bx bx-chevron-right"></i
                                    ></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a
                                                routerLink="/single-blog-1"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >Default</a
                                            >
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                routerLink="/single-blog-2"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >With Video</a
                                            >
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                routerLink="/single-blog-3"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >With Image Slider</a
                                            >
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul> -->

                    <!-- navigation menu -->

                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="history" class="nav-link">Notre histoire salésienne</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/" (click)="Nav_scroll('structure')" class="nav-link">Collège Don Bosco
                                Saint-Dizier</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/" (click)="Nav_scroll('internat')" class="nav-link">Internat</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/" (click)="Nav_scroll('news')" class="nav-link">Actualités</a>
                        </li>
                        <li class="nav-item">
                            <a href="contact-us" class="nav-link">Contactez-nous</a>
                        </li>
                        <li class="nav-item">
                            <a href="gallery" class="nav-link">Galerie</a>
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <a class="default-btn" routerLink='registration' style="width: 100%;">
                                <i class="fa fa-pencil-square-o" style="font-size: 18px; padding-top: 0.3rem;"></i>
                                Pré-inscription
                                <span style="top: 313.556px; left: 86.1666px;"></span>
                            </a>
                        </div>
                    </div>

                    <!-- <div class="others-option d-flex align-items-center">
                        
                        <div class="option-item">
                            <div class="cart-btn">
                                <a routerLink="/cart"><i class="flaticon-shopping-cart"></i><span>3</span></a>
                            </div>
                        </div>

                        <div class="option-item">
                            <a routerLink="/registration" class="default-btn"><i class="flaticon-user"></i>Register<span
                                ></span
                            ></a>
                        </div>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="option-inner">
                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <a routerLink="/registration" class="default-btn"><i
                                    class="flaticon-user"></i>Register<span></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- The Modal -->
<div id="firstTimeAccessChecker_modal" class="cs-modal cookie-model" style="z-index: 9999999999999999999;">
    <div class="cs-modal-content cs-animate-top cs-card-4 cs-round">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    <div class="cs-row cs-hide-small cs-hide-medium">
                        <div class="cs-col me-lg-1" style="width: 50px;">
                            <img src="assets/img/logo/logoNew.png" alt="Saint-Dizier logo" style="height: 50px;">
                        </div>
                        <div class="cs-rest p-1">
                            <span class="logo-text cs-medium">Collège Don Bosco Saint-Dizier</span>
                            <p class="logo-sub-text cs-tiny">Établissement d'Enseignement Supérieur Privé</p>
                        </div>
                    </div>
                    <div class="logo cs-hide-large">
                        <a routerLink="/" class="cs-wide" style=" font-size: 20px;"><img
                                src="assets/img/logo/logoNew.png" alt="Saint-Dizier logo"
                                style="height: 30px;margin-right: 10px;"><b class="logo-text">Saint-Dizier</b></a>
                    </div>
                </h5>
                <!-- <button type="button" class="close cs-btn cs-large cs-round cs-border" onclick="closeModal('firstTimeAccessChecker_modal')">
                    <span aria-hidden="true">&times;</span>
                </button> -->
            </div>
            <div class="modal-body">
                <h5><b>Cookie & Privacy Policy</b></h5>

                <p>Collège Don Bosco Saint-Dizier use cookies or similar technologies and process personal data that may
                    be cross-referenced with
                    information provided as part of our services for the following purposes: To improve your user
                    experience, To produce audience statistics, To measure the performance of these advertisements, To
                    use precise geolocation data, To offer you functionalities relating to social networks, to fight
                    against fraud. Some cookies are necessary for the operation of the site and our services. You can
                    accept or continue browsing without accepting.<br>

                    <a class="policyTitles" href="privacy-policy">Cookie & Privacy Policy</a> and <a
                        class="policyTitles" href="terms-and-conditions">Terms &
                        Conditions</a>
                </p>
                <hr>
                <div>
                    <a onclick="removeFTAC_Modal('accept');" class=" mx-0 default-btn cu-pointer m-auto"
                        style="padding-left:30px;">
                        Accept<span></span>
                    </a>
                    <a onclick="removeFTAC_Modal('continue-without-accept');"
                        class=" mx-3 default-btn ms-lg-2 cu-pointer"
                        style="background-color: #b3833c;  padding-left:30px;">
                        Continue Without Accept
                        <span></span>
                    </a>
                </div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" onclick="closeModal('firstTimeAccessChecker_modal')">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
        </div>
    </div>
</div>