<footer class="footer-area" style="padding-top: 50px">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-7 col-sm-7">
                <div class="single-footer-widget">
                    <img
                        src="../assets/img/logo/Signature 2.png"
                        alt="logo"
                        class="pb-2"
                        style="width: 250px"
                    />
                    <p>
                        Le collège Salésien Don Bosco de Saint-Dizier, acceuille
                        des élèves à besoins éducatifs de différentes régions ce
                        qui est possible grâce à un internat.
                    </p>
                    <ul class="social-link cs-hide">
                        <li>
                            <a
                                href="https://www.facebook.com/ESTIC-1064677090280769/"
                                class="d-block"
                                target="_blank"
                                ><i class="bx bxl-facebook"></i
                            ></a>
                        </li>
                        <li>
                            <a class="d-block" target="_blank"
                                ><i class="bx bxl-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a
                                href="https://www.instagram.com/estic_officiel/"
                                class="d-block"
                                target="_blank"
                                ><i class="bx bxl-instagram"></i
                            ></a>
                        </li>
                        <li>
                            <a class="d-block" target="_blank"
                                ><i class="bx bxl-linkedin"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-4">
                <hr class="separator" />
            </div>
            <div class="col-lg-5 col-md-7 col-sm-7">
                <div class="single-footer-widget">
                    <h3>Contact</h3>
                    <ul class="footer-contact-info">
                        <li class="mb-1">
                            <i class="bx bx-map"></i>1 bis rue du maréchal de
                            lattre de tassigny 52100 saint dizier
                        </li>
                        <li class="mb-1">
                            <i class="bx bx-phone-call"></i
                            ><a>03.25.56.51.73</a>
                        </li>
                        <li class="mb-1">
                            <i class="bx bx-envelope"></i
                            ><a>contact@estic.fr</a>
                        </li>
                        <li class="mb-1">
                            <i class="bx bxs-inbox"></i><a>03.25.56.45.46</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area cs-hide">
            <div class="row align-items-center footerText">
                <div class="col-lg-8 col-md-8">
                    <p class="footerText">
                        <i class="bx bx-copyright"></i>2021 Collège Don Bosco
                        Saint-Dizier. All Rights Reserved. All videos and shows
                        on this platform are trademarks of, and all related
                        images and content are the property of, Streamit Inc.
                        Duplication and copy of this is strictly prohibited. All
                        rights reserved.
                    </p>
                </div>

                <div class="col-lg-4 col-md-4">
                    <ul>
                        <li>
                            <a>Privacy Policy</a>
                        </li>
                        <li>
                            <a>Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="lines cs-hide">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>

<div class="go-top"><i class="bx bx-chevron-up"></i></div>
