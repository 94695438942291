<div class="container-fluid secNavContainer">
    <div class="row secNavBar">
        <a routerLink="/" (click)="Nav_scroll('structure')"
            class="col-md-4 py-3 navBtn secNavBar-div text-white">Micro-collège</a>
        <a href="pedagogy" class="col-md-4 py-3 navBtn secNavBar-div text-white">École inclusive</a>
        <a href="opening" class="col-md-4 py-3 navBtn text-white">Ouverture internationale</a>
    </div>
</div>
<div class="main-banner-wrapper cs-display-container"
    style="background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),url('../assets/img/site/MD1_3.jpg');height: 700px;background-repeat: no-repeat;background-size: cover;background-position: center;">

    <div class="cs-display-left cs-container">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-wrapper-content">
                        <h1 class="cs-text-white">Collège Don Bosco Saint-Dizier</h1>
                        <p class="cs-text-white">
                            Si vous voulez que les jeunes fassent ce que vous aimez, aimez ce que les jeunes aiment. Don
                            Bosco
                        </p>
                        <a routerLink='registration' class="default-btn"><i
                                class="flaticon-user"></i>Pré-inscription<span></span></a>
                    </div>
                </div>
            </div>
        </div>

    </div>



    <div class="banner-shape14">
        <img src="../assets/img/banner-shape15.png" alt="image" />
    </div>
    <div class="banner-shape15">
        <img src="../assets/img/banner-shape16.png" alt="image" />
    </div>
    <div class="banner-shape16">
        <img src="../assets/img/banner-shape17.png" alt="image" />
    </div>
    <div class="banner-shape17">
        <img src="../assets/img/banner-shape18.png" alt="image" />
    </div>
    <div class="banner-shape18">
        <img src="../assets/img/banner-shape19.png" alt="image" />
    </div>
</div>