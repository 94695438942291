<section class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img src="../assets/img/error.png" alt="image">
                    <h3>Data Not Found</h3>
                </div>
            </div>
        </div>
    </div>
</section>
