import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temporary-navbar',
  templateUrl: './temporary-navbar.component.html',
  styleUrls: ['./temporary-navbar.component.scss']
})
export class TemporaryNavbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

  ggwp() {
    const element = document.getElementById("tempNavBar");
    element.classList.add("hideNotification");
    setTimeout(() => { element.classList.add("cs-hide"); }, 600);

  }

}
