<section class="main-banner">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <h1>The World’s Leading Distance Learning Provider</h1>
                    <p>Flexible easy to access learning opportunities can bring a significant change in how individuals prefer to learn! The Odemy can offer you to enjoy the beauty of eLearning!</p>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="main-banner-courses-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-1" class="d-block image">
                                        <img src="../assets/img/courses/img4.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$39</div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="../assets/img/user6.jpg" class="rounded-circle" alt="image">
                                        <span>Alex Morgan</span>
                                    </div>
                                    <h3><a routerLink="/single-courses-1">Python for Finance: Fundamentals & Data Analytics</a></h3>
                                    <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                        <li><i class='flaticon-people'></i> 145 Students</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-1" class="d-block image">
                                        <img src="../assets/img/courses/img5.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$49</div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="../assets/img/user5.jpg" class="rounded-circle" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                    <h3><a routerLink="/single-courses-1">Machine Learning A-Z™: Hands-On Python & R In Data Science</a></h3>
                                    <p>Lorem ipsum dolor sit amet, constetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                    <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                        <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                        <li><i class='flaticon-people'></i> 100 Students</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="banner-shape1" data-speed="0.06" data-revert="true"><img src="../assets/img/banner-shape1.png" alt="image"></div>
                    <div class="banner-shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/banner-shape2.png" alt="image"></div>
                    <div class="banner-shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/banner-shape3.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="partner-area pt-100 pb-70 border-bottom">
    <div class="container">
        <app-partner></app-partner>
    </div>
</section>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Education for everyone</span>
            <h2>Affordable Online Courses and Learning Opportunities​</h2>
            <p>Finding your own space and utilize better learning options can result in faster than the traditional ways. Enjoy the beauty of eLearning!</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-brain-process"></i>
                    </div>
                    <h3>Learn the Latest Top Skills</h3>
                    <p>Learning top skills can bring an extra-ordinary outcome in a career.</p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-computer"></i>
                    </div>
                    <h3>Learn in Your Own Pace</h3>
                    <p>Everyone prefers to enjoy learning at their own pace & that gives a great result.</p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-shield-1"></i>
                    </div>
                    <h3>Learn From Industry Experts</h3>
                    <p>Experienced teachers can assist in learning faster with their best approaches!</p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-world"></i>
                    </div>
                    <h3>Enjoy Learning From Anywhere</h3>
                    <p>We are delighted to give you options to enjoy learning from anywhere in the world.</p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-area bg-fef8ef ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInLeft">
                                <img src="../assets/img/about-img1.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInDown">
                                <img src="../assets/img/about-img2.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInUp">
                                <img src="../assets/img/about-img3.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInRight">
                                <img src="../assets/img/about-img4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Online learning</span>
                    <h2>Develop Your Skills, Learn Something New, and Grow Your Skills From Anywhere in the World!</h2>
                    <p>We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes!</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Expert Trainers</span></li>
                        <li><span><i class="flaticon-time-left"></i> Lifetime Acces</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Remote Learning</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Self Development</span></li>
                    </ul>
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1" data-speed="0.06" data-revert="true"><img src="../assets/img/shape1.png" alt="image"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
</section>

<section class="courses-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Learn At Your Own Pace</span>
            <h2>Odemy Popular Courses</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="../assets/img/courses/img1.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <span>Alex Morgan</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Deep Learning a-z™: Hands-on Artificial Neural Networks</a></h3>
                        <p>This master level course is for you if you are looking to learn the DL & ANN topics in and out within a short time!</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                            <li><i class='flaticon-people'></i> 145 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="../assets/img/courses/img2.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Java Programming Masterclass for Software Developers</a></h3>
                        <p>Java is the most stable and vastly uses a top programming language for mobile, web, and desktop environments.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 100 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="../assets/img/courses/img3.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">The Data Science Course 2020: Complete Data Science Bootcamp</a></h3>
                        <p>We designed the Data Science Bootcamp course to give you the best learning experience within a short time.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 150 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <p>Enjoy the top notch learning methods and achieve next level skills! You are the creator of your own career & we will guide you through that. <a routerLink="/profile-authentication">Register Free Now!</a>.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-and-feedback-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>Flexible Study at Your Own Pace, According to Your Own Needs</h2>
                    <p>With the Odemy, you can study whenever and wherever you choose. We have students in over 175 countries and a global reputation as a pioneer in the field of flexible learning. Our teaching also means, if you travel often or need to
                        relocate, you can continue to study wherever you go.</p>

                    <div class="feedback-slides owl-carousel owl-theme">
                        <div class="single-feedback-item">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="client-info d-flex align-items-center">
                                <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                <div class="title">
                                    <h3>John Smith</h3>
                                    <span>Python Developer</span>
                                </div>
                            </div>
                        </div>

                        <div class="single-feedback-item">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="client-info d-flex align-items-center">
                                <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                                <div class="title">
                                    <h3>Sarah Taylor</h3>
                                    <span>PHP Developer</span>
                                </div>
                            </div>
                        </div>

                        <div class="single-feedback-item">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="client-info d-flex align-items-center">
                                <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                <div class="title">
                                    <h3>David Warner</h3>
                                    <span>QA Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="feedback-info">
                        <p>Not a member yet?​ <a routerLink="/profile-authentication">Register now</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span class="odometer" data-count="1926">00</span></h3>
                                <p>Finished Sessions</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span class="odometer" data-count="3279">00</span></h3>
                                <p>Enrolled Learners</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span class="odometer" data-count="250">00</span></h3>
                                <p>Online Instructors</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span class="odometer" data-count="100">00</span>%</h3>
                                <p>Satisfaction Rate</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="video-box">
                    <div class="image">
                        <img src="../assets/img/video-img1.jpg" class="shadow" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                    <div class="shape10" data-speed="0.06" data-revert="true"><img src="../assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/shape8.svg" alt="image"></div>
</section>

<section class="get-instant-courses-area">
    <div class="container">
        <div class="get-instant-courses-inner-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="get-instant-courses-content">
                        <span class="sub-title">Get Instant Access to The Free</span>
                        <h2>Self Development Course</h2>
                        <p>Odemy Self Development Course can assist you in bringing the significant changes in personal understanding and reshaping the confidence to achieve the best from your career! We trust that learning should be enjoyable, and only
                            that can make substantial changes to someone!</p>
                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Start For Free<span></span></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="get-instant-courses-image">
                        <img src="../assets/img/man.jpg" alt="image">
                        <div class="shape7" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
                        <div class="shape6" data-speed="0.06" data-revert="true"><img src="../assets/img/shape6.png" alt="image"></div>
                    </div>
                </div>
            </div>

            <div class="shape5" data-speed="0.06" data-revert="true"><img src="../assets/img/shape5.png" alt="image"></div>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">News and Blogs</span>
            <h2>Our Latest Publications</h2>
            <p>We always give extra care to our student's skills improvements and feel excited to share our latest research and learnings!</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/blog/img1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Education</a>
                        <h3><a routerLink="/single-blog-1">University Admissions Could Face Emergency Controls</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/blog/img2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Online</a>
                        <h3><a routerLink="/single-blog-1">Online Learning Can Prepare Students For A Fast-Changing</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 29, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/blog/img3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Learning</a>
                        <h3><a routerLink="/single-blog-1">As Learning Moves Online, Trigger Warnings Must Too</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image">
                                    <span>David Warner</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 28, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-post-info">
                    <p>Get into details now?​ <a routerLink="/blog-1">View all posts</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="view-all-courses-area bg-fef8ef">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>Feel Like You Are Attending Your Classes Physically!</h2>
                    <p>Odemy training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives
                        will help you to learn better and quicker than the traditional ways of learning skills.</p>
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-agenda"></i>View Courses<span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-image">
                    <img src="../assets/img/man-with-laptop.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="shape1" data-speed="0.06" data-revert="true"><img src="../assets/img/shape1.png" alt="image"></div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/shape8.svg" alt="image"></div>
</section>

<section class="premium-access-area ptb-100">
    <div class="container">
        <app-premium-access></app-premium-access>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape8" data-speed="0.06" data-revert="true"><img src="../assets/img/shape7.png" alt="image"></div>
</section>