<section class="pricing-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">


        <div class="row justify-content-center">

            <div class="col-lg-6 col-md-8">
                <div class="section-title" style="margin-top: 55px;">
                    <h2 class="playfair-display-font">Our Flexible Pricing Plan</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et
                        dolore magna aliqua.</p>
                    <a href="https://preinscriptions.ecoledirecte.com/?RNE=0830863M" target="_blank"
                        class="default-btn"><i class="flaticon-right"></i>S’inscrire maintenant<span></span></a>
                </div>
            </div>

        </div>
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-8 mt-auto mb-auto">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="profile-courses-quizzes mt-0">
                            <div class="tab-content" id="myTabContent">
                                <h2 class="tableTitle mt-0">INTERNAT (PENSION COMPLÈTE)</h2>
                                <div class="tab-pane fade show active" role="tabpanel">
                                    <div class="tab-content" id="myTab5Content">
                                        <div class="tab-pane fade show active" role="tabpanel">
                                            <div class="table-responsive">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th class="cu-tb-th">Nombre de jours</th>
                                                            <th class="cu-tb-th">Coût</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td class="cu-tb-td">Coût mensuel </td>
                                                            <td class="cu-tb-td">302.50 €</td>
                                                        </tr>

                                                        <tr class="bg-danger">
                                                            <td class="text-white" colspan="3">
                                                                Exemple pour un élève de 3e interne : 75.70 € + 7.20 € +
                                                                302.50
                                                                € =
                                                                385.40 € par mois.
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12">
                <div class="profile-courses-quizzes mt-0">
                    <div class="tab-content" id="myTabContent">
                        <h2 class="tableTitle mt-5">CONTRIBUTION FAMILIALE (SCOLARITÉ)</h2>
                        <div class="tab-pane fade show active" role="tabpanel">
                            <div class="tab-content" id="myTab5Content">
                                <div class="tab-pane fade show active" role="tabpanel">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th class="cu-tb-th">Classe</th>
                                                    <th class="cu-tb-th">Sixième</th>
                                                    <th class="cu-tb-th">Cinquième</th>
                                                    <th class="cu-tb-th">Quatrième Troisième </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td class="cu-tb-td">Coût mensuel </td>
                                                    <td class="cu-tb-td">60.30 €</td>
                                                    <td class="cu-tb-td">64.10 €</td>
                                                    <td class="cu-tb-td">75.70 €</td>
                                                </tr>
                                                <tr>
                                                    <td class="cu-tb-td">Frais divers</td>
                                                    <td class="cu-tb-td">7.20 €</td>
                                                    <td class="cu-tb-td">7.20 €</td>
                                                    <td class="cu-tb-td">7.20 €</td>
                                                </tr>
                                                <tr class="bg-danger">
                                                    <td class="text-white" colspan="4">
                                                        Exemple pour un élève de 3e interne : 75.70 € + 7.20 € +
                                                        302.50
                                                        € =
                                                        385.40 € par mois.
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-12">
                <div class="profile-courses-quizzes mt-0">
                    <div class="tab-content" id="myTabContent">
                        <h2 class="tableTitle mt-3">DEMI-PENSION</h2>
                        <div class="tab-pane fade show active" role="tabpanel">
                            <div class="tab-content" id="myTab5Content">
                                <div class="tab-pane fade show active" role="tabpanel">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th class="cu-tb-th">Nombre de jours</th>
                                                    <th class="cu-tb-th">1 jour </th>
                                                    <th class="cu-tb-th">2 jours</th>
                                                    <th class="cu-tb-th">3 jours</th>
                                                    <th class="cu-tb-th">4 jours</th>
                                                    <th class="cu-tb-th">5 jours</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td class="cu-tb-td">Coût mensuel</td>
                                                    <td class="cu-tb-td">22.32 €</td>
                                                    <td class="cu-tb-td">44.64 €</td>
                                                    <td class="cu-tb-td">66.96 €</td>
                                                    <td class="cu-tb-td">89.28 €</td>
                                                    <td class="cu-tb-td">111.60 €</td>
                                                </tr>
                                                <tr class="bg-danger">
                                                    <td class="text-white" colspan="6">
                                                        Exemple pour un élève de 3e interne : 75.70 € + 7.20 € +
                                                        302.50
                                                        € =
                                                        385.40 € par mois.
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>