<app-home-banner></app-home-banner>

<!-- <section class="slogan-area mtb-100 bg-fffaf3 ptb-100 cs-hide">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="slogan-image">
                    <img src="../assets/img/man2.jpg" alt="image" />
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Why choose us</span>
                    <h2>Edito</h2>
                    <p>
                        We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes! We understand better that online-based learning can make a significant
                        change to reach students from all over the world! Giving options to learn better always can offer the best outcomes! We understand better that online-based learning can make a significant change to reach students from all over
                        the world! Giving options to learn better always can offer the best outcomes!
                    </p>
                    <ul class="features-list cs-hide">
                        <li>
                            <span><i class="flaticon-experience"></i> Skiled
                                Teachers</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="flaticon-time-left"></i> Afordable
                                Courses</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="flaticon-tutorials"></i> Efficient &
                                Flexible</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="flaticon-self-growth"></i> Lifetime
                                Access</span
                            >
                        </li>
                    </ul>
                </div>
                <div class="slogan-content">
                    <h3>William James</h3>
                    <span class="sub-title">CEO at Odemy</span>
                </div>
            </div>
        </div>
    </div>

    <div class="divider2"></div>
    <div class="divider3"></div>
    <div class="shape2" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape2.png" alt="image" />
    </div>
    <div class="shape3" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape3.png" alt="image" />
    </div>
    <div class="shape4" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape4.png" alt="image" />
    </div>
    <div class="shape9" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape8.svg" alt="image" />
    </div>
</section> -->

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <div class="about-image text-center">
                    <img src="../assets/img/site/MD3_1.jpg" alt="image" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">POURQUOI NOUS ?</span>
                    <h2>Edito</h2>
                    <p style="text-align: justify">Le micro-collège Salésien Don Bosco Saint-Dizier est un établissement
                        ouvert à tous.</p>
                    <p style="text-align: justify">Au collège Don Bosco Saint-Dizier nous adaptons une pédagogie
                        différenciée aux besoins de chaque élève en portant une attention plus particulière aux
                        adolescents les plus vulnérables. Nous veillons à ce que l’élève reste toujours le centre de nos
                        projets éducatifs. </p>
                    <p style="text-align: justify">Toute personne est accueillie et respectée dans sa dignité d’être
                        humain, avec la richesse de son histoire personnelle. Le savoir-être est le cœur de ce
                        développement éducatif. Notre approche pédagogique permet de développer le gout de l’effort dans
                        un environnement où le respect et la tolérance sont de circonstances et se fait en
                        co-responsabilisant les jeunes, les adultes, les parents et les professeurs.</p>
                    <p style="text-align: justify">Nous vous invitons à découvrir notre projet éducatif et pastoral et
                        les nombreux atouts que notre collège propose en continuant votre visite…</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-12">
                <div class="about-image text-center">
                    <img src="../assets/img/site/MD3_2.jpg" alt="image" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="health-services-area bg-fcf7f3 pt-100 pb-100" id="structure">
    <div class="container">
        <div class="section-title">
            <span class="sub-title cu-sub-title">A CHACUN SON RYTHME</span>
            <h2 class=" cs-text-white">Le collège Don Bosco Saint-Dizier</h2>
            <p class=" cs-text-white cs-hide">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua.
            </p>
        </div>

        <div class="row text-center justify-content-center">

            <div class="col-md-6 col-lg-2">
                <a routerLink="pedagogy" class="dbs1_trans"><i class="flaticon-right"></i>Pédagogie<span></span></a>
            </div>
            <div class="col-md-6 col-lg-3">
                <a routerLink="timetable" class="dbs1_trans"><i class="flaticon-right"></i>Emploi du
                    temps<span></span></a>
            </div>
            <div class="col-md-6 col-lg-3">
                <a routerLink="opening" class="dbs1_trans"><i class="flaticon-right"></i>Ouverture
                    Internationale<span></span></a>
            </div>
        </div>
    </div>
</section>

<section class="about-area-two pb-100 pt-100" id="internat">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="about-content-box">
                    <h2>Internat</h2>
                    <p>
                        L’internat constitue un mode de scolarisation qui favorise la réussite scolaire. Il est aussi un
                        espace de socialisation qui doit permettre de développer les conditions de travail de qualité
                        dans un environnement agréable favorisant l’épanouissement global de l’élève.
                    </p>
                    <p>
                        C’est à la fois un lieu d’éducation, un lieu d’apprentissage, un lieu de vie.
                    </p>
                    <p>
                        L’internat contribue à créer les conditions d’une meilleure réussite scolaire et d’une autonomie
                        plus assurée. Il doit être :

                    </p>
                    <ul class="pl-4">
                        <li>un espace de respect et de civilité</li>
                        <li>un espace fraternel de citoyenneté et d’entraide</li>
                        <li>un espace de confiance entre adultes et élèves, fondé sur l’écoute, la bienveillance,
                            l’exigence.</li>
                    </ul>
                    <a routerLink="/internat" class="default-btn px-5">Pour en savoir
                        plus…<span></span></a>
                </div>
            </div>

            <div class="col-lg-5 col-md-12">
                <div class="experience-image">
                    <img src="../assets/img/site/MD5.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i
                            class="flaticon-play"></i></a>
                    <span class="title">Internat</span>
                </div>
            </div>
        </div>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape3.png" alt="image" />
    </div>
    <div class="shape4" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape4.png" alt="image" />
    </div>
    <div class="shape2" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape2.png" alt="image" />
    </div>
</section>

<section class="view-all-courses-area bg-fef8ef" id="news">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-content">
                    <h2>Actualités</h2>
                    <app-recent-news-n-events></app-recent-news-n-events>
                    <a routerLink="/news-and-events" class="default-btn"><i class="flaticon-agenda"></i>Pour en savoir
                        plus<span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-image">
                    <img src="../assets/img/site/MD6.jpg" alt="image" />
                </div>
            </div>
        </div>
    </div>

    <div class="shape9" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape8.svg" alt="image" />
    </div>
</section>

<section class="partner-area ptb-70" style="background-color: white;">
    <div class="container">
        <app-partner></app-partner>
    </div>
</section>

<section class="about-area-two pb-100">
    <div class="container">
        <div class="row align-items-center" style="padding-top: 5rem;">
            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <div class="image">
                        <img src="../assets/img/about-img6.jpg" alt="image" />
                    </div>
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i
                            class="flaticon-play"></i></a>
                    <div class="shape10" data-speed="0.06" data-revert="true">
                        <img src="../assets/img/shape9.png" alt="image" />
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <h2>Trouvez-nous</h2>
                    <p>
                        Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new
                        skill quickly like, project team leadership or agile? Browse our most popular online courses.
                    </p>
                    <a routerLink="/contact" class="link-btn">Contactez-nous</a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape3.png" alt="image" />
    </div>
    <div class="shape4" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape4.png" alt="image" />
    </div>
    <div class="shape2" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape2.png" alt="image" />
    </div>
</section>

<div id="map">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d260370.4624153429!2d-74.21074577271956!3d40.67735855419542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25090129c363d%3A0x40c6a5770d25022b!2sStatue%20of%20Liberty%20National%20Monument!5e0!3m2!1sen!2sbd!4v1599386259810!5m2!1sen!2sbd"></iframe>
</div>

<section class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span class="sub-title">Go At Your Own Pace</span>
            <h2>Contactez-nous</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.
            </p>
            <a routerLink="/contact-us" class="default-btn"><i
                    class="flaticon-right"></i>Contactez-nous<span></span></a>
        </div>
    </div>

    <div class="shape4" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape4.png" alt="image" />
    </div>
    <div class="shape13" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape12.png" alt="image" />
    </div>
    <div class="shape14" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape13.png" alt="image" />
    </div>
    <div class="shape15" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape14.png" alt="image" />
    </div>
</section>