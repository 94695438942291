<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Become A Teacher</li>
            </ul>
            <h2>Become A Teacher</h2>
        </div>
    </div>

    <div class="shape9"><img src="../assets/img/shape8.svg" alt="image"></div>
</div>

<section class="apply-instructor-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="apply-instructor-image">
                    <h2>Apply As Instructor</h2>
                    <img src="../assets/img/apply-instructor.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="apply-instructor-content">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item"><a class="nav-link active" id="becomeAnInstructor-tab" data-toggle="tab" href="#becomeAnInstructor" role="tab" aria-controls="becomeAnInstructor">Become an Instructor</a></li>
                        <li class="nav-item"><a class="nav-link" id="instructorRules-tab" data-toggle="tab" href="#instructorRules" role="tab" aria-controls="instructorRules">Instructor Rules</a></li>
                        <li class="nav-item"><a class="nav-link" id="startWithCourses-tab" data-toggle="tab" href="#startWithCourses" role="tab" aria-controls="startWithCourses">Start with Courses</a></li>
                    </ul>

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="becomeAnInstructor" role="tabpanel">
                            <h3>Course Description</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <h3>Certification</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>

                        <div class="tab-pane fade" id="instructorRules" role="tabpanel">
                            <h3>Course Description</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <h3>Certification</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>

                        <div class="tab-pane fade" id="startWithCourses" role="tabpanel">
                            <h3>Course Description</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            <h3>Certification</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="teacher-register-area ptb-100">
    <div class="container">
        <div class="teacher-register-box">
            <h2>Register to Become an Intructor</h2>
            <p>Your email address will not be published. Required fields are marked *</p>

            <form>
                <div class="form-group">
                    <input type="text" placeholder="Your Name *">
                </div>
                <div class="form-group">
                    <input type="email" placeholder="Your Email *">
                </div>
                <div class="form-group">
                    <input type="text" placeholder="Your Phone *">
                </div>
                <div class="form-group">
                    <textarea placeholder="Your Message *" cols="30" rows="5"></textarea>
                </div>
                <button type="submit" class="default-btn">Submit Message<span></span></button>
            </form>
        </div>
    </div>
</section>