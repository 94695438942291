<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Blog Masonry (3 in Row)</li>
            </ul>
            <h2>Blog Masonry (3 in Row)</h2>
        </div>
    </div>

    <div class="shape9"><img src="../assets/img/shape8.svg" alt="image"></div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="blog-items">
            <div class="col-lg-4 col-md-6 grid-item">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/single-blog-20" class="d-block">
                            <img src="../assets/img/blog/img1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-5" class="category">Education</a>
                        <h3><a routerLink="/single-blog-20">It’s Time To Think Differently About Homeschooling</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 grid-item">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-20" class="d-block">
                            <img src="../assets/img/blog/img2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-5" class="category">Education</a>
                        <h3><a routerLink="/single-blog-20">Best destination for travelers in Iceland</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 grid-item">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/single-blog-20" class="d-block">
                            <img src="../assets/img/blog/img4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/single-blog-20">University Admissions Could Face Emergency Controls</a></h3>
                        <ul class="post-content-footer d-flex align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 grid-item">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/single-blog-20" class="d-block">
                            <img src="../assets/img/blog/img3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/single-blog-20">It’s Time To Think Differently About Homeschooling</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 grid-item">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-20" class="d-block">
                            <img src="../assets/img/blog/img12.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-5" class="category">Learning</a>
                        <h3><a routerLink="/single-blog-20">2020 L&D On A Shoestring Online Conference</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image">
                                    <span>David Warner</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 28, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 grid-item">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/single-blog-20" class="d-block">
                            <img src="../assets/img/blog/img8.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-5" class="category">Online</a>
                        <h3><a routerLink="/single-blog-20">100 Blended Learning Resources For Teachers</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 29, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 grid-item">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-20" class="d-block">
                            <img src="../assets/img/blog/img9.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-5" class="category">Learning</a>
                        <h3><a routerLink="/single-blog-20">20 Examples Of Project-Based Learning</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image">
                                    <span>David Warner</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 28, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 grid-item">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/single-blog-20" class="d-block">
                            <img src="../assets/img/blog/img5.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/single-blog-20">Online Learning Can Prepare Students For A Fast-Changing</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 29, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 grid-item">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/single-blog-20" class="d-block">
                            <img src="../assets/img/blog/img6.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/single-blog-20">As Learning Moves Online, Trigger Warnings Must Too</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 29, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
