<div class="hero-banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="hero-banner-content">
                    <h1>Build Development Skills With Odemy Any Time, Anywhere</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="hero-banner-image">
                    <img src="../assets/img/main-banner3.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape19"><img src="../assets/img/banner-shape20.png" alt="image"></div>
    <div class="divider"></div>
</div>

<section class="boxes-area boxes-style-two bg-f5f7fa">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box-item">
                    <div class="image">
                        <img src="../assets/img/boxes-img1.png" alt="image">
                    </div>
                    <h3>Web Development</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box-item">
                    <div class="image">
                        <img src="../assets/img/boxes-img2.png" alt="image">
                    </div>
                    <h3>UX/UI Design</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-box-item">
                    <div class="image">
                        <img src="../assets/img/boxes-img3.png" alt="image">
                    </div>
                    <h3>App Development</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now!</a>
                </div>
            </div>
        </div>

        <div class="boxes-info">
            <p>If you want more? <a routerLink="/courses-1">View More Courses</a></p>
        </div>
    </div>
</section>

<section class="about-area bg-f5f7fa ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image text-center">
                    <img src="../assets/img/about-img10.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>Develop Your Skills, Learn Something New, and Grow Your Skills From Anywhere in the World!</h2>
                    <p>We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes!</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Skiled Teachers</span></li>
                        <li><span><i class="flaticon-time-left"></i> Afordable Courses</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Efficient & Flexible</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Lifetime Access</span></li>
                    </ul>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go at your own pace</span>
            <h2>The World’s Largest Selection Of Courses</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img1.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$39</span>
                                <h3><a routerLink="/single-courses-1">Agile Crash Course: Agile Project Management</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                    <li><i class='flaticon-people'></i> 145 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img2.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$99</span>
                                <h3><a routerLink="/single-courses-1">Vue JS 2 - The Complete Guide (incl. Vue Router & Vuex)</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img3.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$49</span>
                                <h3><a routerLink="/single-courses-1">The Python Bible™ | Everything You Need to Program in Python</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 11 Lessons</li>
                                    <li><i class='flaticon-people'></i> 104 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img4.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$79</span>
                                <h3><a routerLink="/single-courses-1">Mathematical Foundation For Machine Learning and AI</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img5.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$59</span>
                                <h3><a routerLink="/single-courses-1">The Ultimate Drawing Course - Beginner to Advanced</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 09 Lessons</li>
                                    <li><i class='flaticon-people'></i> 150 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img6.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$89</span>
                                <h3><a routerLink="/single-courses-1">PyTorch: Deep Learning and Artificial Intelligence</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                    <li><i class='flaticon-people'></i> 200 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                    <p>Get into details now?​ <a routerLink="/courses-1">PM Master’s Program</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area bg-fffaf3 ptb-100">
    <div class="container">
        <div class="feedback-slides-two owl-carousel owl-theme">
            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>John Smith</h3>
                        <span>Python Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>Sarah Taylor</h3>
                        <span>PHP Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>David Warner</h3>
                        <span>QA Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="divider2"></div>
    <div class="divider3"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/shape8.svg" alt="image"></div>
</section>

<section class="categories-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Categories</span>
            <h2>Top Categories</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img1.jpg" alt="image">
                    <div class="content">
                        <h3>Development</h3>
                        <span>10 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img2.jpg" alt="image">
                    <div class="content">
                        <h3>Business</h3>
                        <span>20 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img3.jpg" alt="image">
                    <div class="content">
                        <h3>IT & Software</h3>
                        <span>15 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img4.jpg" alt="image">
                    <div class="content">
                        <h3>Design</h3>
                        <span>11 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img5.jpg" alt="image">
                    <div class="content">
                        <h3>Lifestyle</h3>
                        <span>10 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img6.jpg" alt="image">
                    <div class="content">
                        <h3>Photo & Flim</h3>
                        <span>12 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img7.jpg" alt="image">
                    <div class="content">
                        <h3>Animation</h3>
                        <span>05 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img8.jpg" alt="image">
                    <div class="content">
                        <h3>Writing</h3>
                        <span>20 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="categories-btn-box">
                    <a routerLink="/categories" class="default-btn"><i class="flaticon-user"></i>View All Categories<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area bg-fffaf3">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="1926">00</span></h3>
                    <p>Finished Sessions</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="3279">00</span></h3>
                    <p>Enrolled Learners</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="250">00</span></h3>
                    <p>Online Instructors</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="100">00</span>%</h3>
                    <p>Satisfaction Rate</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overview-area ptb-100">
    <div class="container">
        <div class="overview-box">
            <div class="overview-content">
                <span class="sub-title">Distance Learning</span>
                <h2>Feel Like You Are Attending Your Classes Physically!</h2>
                <p>Odemy training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives
                    will help you to learn better and quicker than the traditional ways of learning skills.</p>
                <a routerLink="/contact" class="default-btn"><i class="flaticon-user"></i>Get Started Now<span></span></a>
            </div>

            <div class="overview-image">
                <img src="../assets/img/overview-img1.png" alt="image">
            </div>
        </div>

        <div class="overview-box">
            <div class="overview-image">
                <img src="../assets/img/overview-img2.png" alt="image">
            </div>

            <div class="overview-content">
                <span class="sub-title">Odemy Mobile App</span>
                <h2>Access From Your Mobile, Learn Any Time Any Where</h2>
                <p>Odemy training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives
                    will help you to learn better and quicker than the traditional ways of learning skills.</p>
                <div class="btn-box">
                    <a href="#" class="playstore-btn">
                        <img src="../assets/img/playstore.png" alt="image"> GET IT ON
                        <span>Google Play</span>
                    </a>
                    <a href="#" class="applestore-btn">
                        <img src="../assets/img/applestore.png" alt="image"> GET IT ON
                        <span>Apple Store</span>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/shape8.svg" alt="image"></div>
</section>

<section class="events-area bg-fffaf3 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Events</span>
            <h2>Our Upcoming Events</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-events-box">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="../assets/img/events/img1.jpg" alt="image">
                        </a>
                        <span class="date">Wed, 20 May, 2020</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/single-events">Global Conference on Business Management</a></h3>
                        <span class="location"><i class="bx bx-map"></i>Vancover, Canada</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-events-box">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="../assets/img/events/img2.jpg" alt="image">
                        </a>
                        <span class="date">Tue, 19 May, 2020</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/single-events">International Conference on Teacher Education</a></h3>
                        <span class="location"><i class="bx bx-map"></i>Sydney, Australia</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-events-box">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="../assets/img/events/img3.jpg" alt="image">
                        </a>
                        <span class="date">Mon, 18 May, 2020</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/single-events">International Conference on Special Needs Education</a></h3>
                        <span class="location"><i class="bx bx-map"></i>Istanbul, Turkey</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-events-box">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="../assets/img/events/img4.jpg" alt="image">
                        </a>
                        <span class="date">Sun, 17 May, 2020</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/single-events">International Conference on Literacy Teaching</a></h3>
                        <span class="location"><i class="bx bx-map"></i>Athens, Greece</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-events-box">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="../assets/img/events/img5.jpg" alt="image">
                        </a>
                        <span class="date">Sat, 16 May, 2020</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/single-events">International Conference on Educational Administration</a></h3>
                        <span class="location"><i class="bx bx-map"></i>Rome, Italy</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-events-box">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="../assets/img/events/img6.jpg" alt="image">
                        </a>
                        <span class="date">Fri, 15 May, 2020</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/single-events">International Conference on Education and Pedagogy</a></h3>
                        <span class="location"><i class="bx bx-map"></i>Athens, Greece</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>

    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape13" data-speed="0.06" data-revert="true"><img src="../assets/img/shape12.png" alt="image"></div>
    <div class="shape14" data-speed="0.06" data-revert="true"><img src="../assets/img/shape13.png" alt="image"></div>
    <div class="shape15" data-speed="0.06" data-revert="true"><img src="../assets/img/shape14.png" alt="image"></div>
</section>

<section class="partner-area bg-fe4a55 ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</section>