<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>

    <div class="shape9"><img src="../assets/img/shape8.svg" alt="image"></div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="row">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInLeft">
                                <img src="../assets/img/about-img1.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInDown">
                                <img src="../assets/img/about-img2.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInUp">
                                <img src="../assets/img/about-img3.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                            <div class="image wow fadeInRight">
                                <img src="../assets/img/about-img4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>89% of people learning for professional development report career benefits like getting a promotion, starting a new career</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Expert Instruction</span></li>
                        <li><span><i class="flaticon-time-left"></i> Lifetime Acces</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Remote Learning</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Self Development</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1" data-speed="0.06" data-revert="true"><img src="../assets/img/shape1.png" alt="image"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
</section>

<section class="funfacts-and-feedback-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="feedback-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>Flexible study at your own pace, according to your own needs</h2>
                    <p>With The Open University you can study whenever and wherever you choose. We have students in over 128 countries, and a global reputation as a pioneer in the field of flexible learning. Our flexible teaching also means, if you travel
                        often or need to relocate, you can continue to study wherever you go.</p>

                    <div class="feedback-slides owl-carousel owl-theme">
                        <div class="single-feedback-item">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="client-info d-flex align-items-center">
                                <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                <div class="title">
                                    <h3>John Smith</h3>
                                    <span>Python Developer</span>
                                </div>
                            </div>
                        </div>

                        <div class="single-feedback-item">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="client-info d-flex align-items-center">
                                <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                                <div class="title">
                                    <h3>Sarah Taylor</h3>
                                    <span>PHP Developer</span>
                                </div>
                            </div>
                        </div>

                        <div class="single-feedback-item">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <div class="client-info d-flex align-items-center">
                                <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                <div class="title">
                                    <h3>David Warner</h3>
                                    <span>QA Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="feedback-info">
                        <p>Have questions?​ <a routerLink="/profile-authentication">Sign Up for Free</a></p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="funfacts-list">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span class="odometer" data-count="1926">00</span></h3>
                                <p>Finished Sessions</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span class="odometer" data-count="3279">00</span></h3>
                                <p>Enrolled Learners</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span class="odometer" data-count="250">00</span></h3>
                                <p>Online Instructors</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-funfacts-box">
                                <h3><span class="odometer" data-count="100">00</span>%</h3>
                                <p>Satisfaction Rate</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="video-box">
                    <div class="image">
                        <img src="../assets/img/video-img1.jpg" class="shadow" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                    <div class="shape10" data-speed="0.06" data-revert="true"><img src="../assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/shape8.svg" alt="image"></div>
</section>

<section class="features-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Education for everyone</span>
            <h2>Online Coaching Lessons For Remote Learning</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-brain-process"></i>
                    </div>
                    <h3>Learn the Latest Skills</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-computer"></i>
                    </div>
                    <h3>Go at Your Own Pace</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-shield-1"></i>
                    </div>
                    <h3>Learn from Industry Experts</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-features-box">
                    <div class="icon">
                        <i class="flaticon-world"></i>
                    </div>
                    <h3>Learn Anywhere in the World</h3>
                    <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                    <a routerLink="/membership-levels" class="link-btn">Start Now!</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="advisor-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Instructor</span>
            <h2>Course Advisor</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="advisor-slides owl-carousel owl-theme">
            <div class="single-advisor-box">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="advisor-image">
                            <img src="../assets/img/advisor/img1.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8">
                        <div class="advisor-content">
                            <h3><a routerLink="/profile">William James</a></h3>
                            <span class="sub-title">Project Management Expert</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-advisor-box">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="advisor-image">
                            <img src="../assets/img/advisor/img2.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8">
                        <div class="advisor-content">
                            <h3><a routerLink="/profile">Sarah Taylor</a></h3>
                            <span class="sub-title">Agile Project Expert</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-advisor-box">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="advisor-image">
                            <img src="../assets/img/advisor/img3.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8">
                        <div class="advisor-content">
                            <h3><a routerLink="/profile">James Andy</a></h3>
                            <span class="sub-title">QA Project Expert</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="premium-access-area bg-fef8ef ptb-100">
    <div class="container">
        <app-premium-access></app-premium-access>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape8" data-speed="0.06" data-revert="true"><img src="../assets/img/shape7.png" alt="image"></div>
</section>

<section class="partner-area ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</section>