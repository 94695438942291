import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modern-schooling',
  templateUrl: './modern-schooling.component.html',
  styleUrls: ['./modern-schooling.component.scss']
})
export class ModernSchoolingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
