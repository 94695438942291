<footer class="footer-area" style="padding-top: 10px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">

                    <a routerLink="e-learning-school" class="logo">
                        <img src="assets/img/logo/Signature 2.png" alt="logo" />
                    </a>

                    <p>
                        Le collège Salésien Don Bosco de Saint-Dizier, acceuille des élèves à besoins éducatifs de
                        différentes régions ce qui est possible grâce à un internat.
                    </p>
                    <a routerLink="e-learning-school" class="logo">
                        <img src="assets/img/partner/SDB.png" style="width: 70px; height: auto;" alt="sdb" />
                    </a>


                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5" style="margin-top: 30px;">
                    <h3>Explorer</h3>
                    <ul class="footer-links-list">
                        <li>
                            <a routerLink="history">Notre histoire Salésienne
                            </a>
                        </li>
                        <li>
                            <a routerLink="/" (click)="Nav_scroll('structure')">Collège Don Bosco Saint-Dizier</a>
                        </li>
                        <li><a routerLink="/" (click)="Nav_scroll('internat')">Internat</a></li>
                        <li><a routerLink=" contact-us">Contactez-nous</a></li>
                        <li>
                            <a routerLink="registration">Pré-inscription</a>
                        </li>
                        <li><a routerLink="gallery">Galerie</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Student Success</a></li>
                        <li><a routerLink="/">Scholarships</a></li>
                        <li><a routerLink="/">For Business</a></li>
                        <li><a routerLink="/">Go Premium</a></li>
                        <li><a routerLink="/">Team Plans</a></li>
                    </ul>
                </div>
            </div> -->

            <div class="col-lg-4 col-md-6 col-sm-6" style="margin-top: 30px;">
                <div class="single-footer-widget">
                    <h3>Coordonnées</h3>
                    <ul class="footer-contact-info">
                        <li>
                            <i class="bx bx-map"></i>1bis, rue du Maréchal de Lattre de Tassigny 52100 Saint-Dizier
                        </li>
                        <li>
                            <i class="bx bx-phone-call"></i><a href="tel:03.25.56.51.73">03.25.56.51.73</a>
                        </li>
                        <li>
                            <i class="bx bx-envelope"></i><a href="mailto:hello@odemy.com">hello@odemy.com</a>
                        </li>
                        <li>
                            <i class="bx bxs-inbox"></i><a href="tel:03.25.56.45.46">03.25.56.45.46</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer-bottom-area" style="margin-top: 0px; padding: 5px;">
            <div class="row align-items-center footerText">
                <div class="col-lg-8 col-md-8">
                    <p class="footerText">
                        <i class="bx bx-copyright"></i>2021 Collège Don Bosco Saint-Dizier. All Rights Reserved. All
                        videos and shows on this platform are trademarks of, and all related images and content are the
                        property of,
                        Streamit Inc. Duplication and copy of this is strictly prohibited. All rights reserved.
                    </p>
                </div>

                <div class="col-lg-4 col-md-4">
                    <ul>
                        <li>
                            <a href="privacy-policy">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="terms-and-conditions">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>

<div class="go-top"><i class="bx bx-chevron-up"></i></div>