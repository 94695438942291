<div class="banner-wrapper-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-wrapper-content">
                    <h1>Build Skills With Experts Any Time, Anywhere</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <form>
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="input-search" placeholder="What do you want to learn?">
                        <button type="submit">Search Now</button>
                    </form>
                    <ul class="popular-search-list">
                        <li><span>Popular:</span></li>
                        <li><a routerLink="/">Development</a></li>
                        <li><a routerLink="/">Marketing</a></li>
                        <li><a routerLink="/">Illustration</a></li>
                        <li><a routerLink="/">UX/UI</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="banner-wrapper-image">
                    <img src="../assets/img/banner-img2.png" alt="image">
                    <div class="banner-shape8" data-speed="0.06" data-revert="true"><img src="../assets/img/banner-shape8.png" alt="image"></div>
                    <div class="banner-shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/banner-shape9.png" alt="image"></div>
                    <div class="banner-shape10" data-speed="0.06" data-revert="true"><img src="../assets/img/banner-shape10.png" alt="image"></div>
                </div>
            </div>
        </div>

        <div class="banner-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-banner-box">
                        <div class="icon">
                            <i class="flaticon-brain-process"></i>
                        </div>
                        <h3>10,000 Online Courses</h3>
                        <p>Lorem ipsum dolor sit amet consectets.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-banner-box">
                        <div class="icon">
                            <i class="flaticon-people"></i>
                        </div>
                        <h3>Experts Teachers</h3>
                        <p>Lorem ipsum dolor sit amet consectets.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-banner-box">
                        <div class="icon">
                            <i class="flaticon-world"></i>
                        </div>
                        <h3>Lifetime Access</h3>
                        <p>Lorem ipsum dolor sit amet consectets.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="divider"></div>
</div>

<section class="courses-area ptb-100 bg-f5f7fa">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go at your own pace</span>
            <h2>The World’s Largest Selection Of Courses</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img1.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$39</span>
                                <h3><a routerLink="/single-courses-1">Agile Crash Course: Agile Project Management</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                    <li><i class='flaticon-people'></i> 145 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img2.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$99</span>
                                <h3><a routerLink="/single-courses-1">Vue JS 2 - The Complete Guide (incl. Vue Router & Vuex)</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img3.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$49</span>
                                <h3><a routerLink="/single-courses-1">The Python Bible™ | Everything You Need to Program in Python</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 11 Lessons</li>
                                    <li><i class='flaticon-people'></i> 104 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img4.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$79</span>
                                <h3><a routerLink="/single-courses-1">Mathematical Foundation For Machine Learning and AI</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img5.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$59</span>
                                <h3><a routerLink="/single-courses-1">The Ultimate Drawing Course - Beginner to Advanced</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 09 Lessons</li>
                                    <li><i class='flaticon-people'></i> 150 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="../assets/img/courses-small/img6.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$89</span>
                                <h3><a routerLink="/single-courses-1">PyTorch: Deep Learning and Artificial Intelligence</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                    <li><i class='flaticon-people'></i> 200 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                    <p>Get into details now?​ <a routerLink="/courses-1">PM Master’s Program</a></p>
                </div>
            </div>
        </div>
    </div>

    <div class="shape16"><img src="../assets/img/shape15.png" alt="image"></div>
</section>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Education for everyone</span>
            <h2>Online Coaching Lessons For Remote Learning</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box without-padding">
                    <div class="icon">
                        <i class="flaticon-brain-process"></i>
                    </div>
                    <h3>Learn the Latest Skills</h3>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box without-padding">
                    <div class="icon">
                        <i class="flaticon-computer"></i>
                    </div>
                    <h3>Go at Your Own Pace</h3>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-features-box without-padding">
                    <div class="icon">
                        <i class="flaticon-shield-1"></i>
                    </div>
                    <h3>Learn from Industry Experts</h3>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-area-two pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Distance Learning</span>
                    <h2>Build Your Project Management Skills Online, Anytime</h2>
                    <p>Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new skill quickly like, project team leadership or agile? Browse our most popular online courses.</p>
                    <p><strong>Grow your knowledge and your opportunities with thought leadership, training and tools.</strong></p>
                    <a routerLink="/contact" class="link-btn">Explore Learning</a>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <div class="image">
                        <img src="../assets/img/about-img6.jpg" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                    <div class="shape10" data-speed="0.06" data-revert="true"><img src="../assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
</section>

<section class="feedback-area bg-fffaf3 ptb-100">
    <div class="container">
        <div class="feedback-slides-two owl-carousel owl-theme">
            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>John Smith</h3>
                        <span>Python Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>Sarah Taylor</h3>
                        <span>PHP Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>David Warner</h3>
                        <span>QA Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="divider2"></div>
    <div class="divider3"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/shape8.svg" alt="image"></div>
</section>

<section class="categories-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Categories</span>
            <h2>Top Categories</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img1.jpg" alt="image">
                    <div class="content">
                        <h3>Development</h3>
                        <span>10 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img2.jpg" alt="image">
                    <div class="content">
                        <h3>Business</h3>
                        <span>20 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img3.jpg" alt="image">
                    <div class="content">
                        <h3>IT & Software</h3>
                        <span>15 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img4.jpg" alt="image">
                    <div class="content">
                        <h3>Design</h3>
                        <span>11 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img5.jpg" alt="image">
                    <div class="content">
                        <h3>Lifestyle</h3>
                        <span>10 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img6.jpg" alt="image">
                    <div class="content">
                        <h3>Photo & Flim</h3>
                        <span>12 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img7.jpg" alt="image">
                    <div class="content">
                        <h3>Animation</h3>
                        <span>05 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-categories-box">
                    <img src="../assets/img/categories/img8.jpg" alt="image">
                    <div class="content">
                        <h3>Writing</h3>
                        <span>20 Courses</span>
                    </div>
                    <a routerLink="/courses-1" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="categories-btn-box">
                    <a routerLink="/categories" class="default-btn"><i class="flaticon-user"></i>View All Categories<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area bg-fffaf3">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="1926">00</span></h3>
                    <p>Finished Sessions</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="3279">00</span></h3>
                    <p>Enrolled Learners</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="250">00</span></h3>
                    <p>Online Instructors</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="100">00</span>%</h3>
                    <p>Satisfaction Rate</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="advisor-area bg-f9f9f9 pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Course Advisor</span>
            <h2>Meet Our World-class Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="advisor-slides-two owl-carousel owl-theme">
            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img4.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">James Andy</a></h3>
                    <span>Project Management Expert</span>
                </div>
            </div>

            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img5.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">Jassica Hische</a></h3>
                    <span>Illustrator Expert</span>
                </div>
            </div>

            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img6.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">Alister Cock</a></h3>
                    <span>QA Project Expert</span>
                </div>
            </div>

            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img7.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
                <div class="advisor-content">
                    <h3><a routerLink="/profile">Lina Ninja</a></h3>
                    <span>QA Project Expert</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="premium-access-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-premium-access></app-premium-access>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape8" data-speed="0.06" data-revert="true"><img src="../assets/img/shape7.png" alt="image"></div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">News</span>
            <h2>Check Out Our Latest Blog</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="single-blog-post-item">
                    <div class="post-image">
                        <a routerLink="/single-blog-3" class="d-block">
                            <img src="../assets/img/blog/img4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-3" class="category">Education</a>
                        <h3><a routerLink="/single-blog-3">University Admissions Could Face Emergency Controls</a></h3>
                        <ul class="post-content-footer d-flex align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="blog-post-list">
                    <div class="row">
                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="single-blog-post-item">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="../assets/img/blog/img5.jpg" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <h3><a routerLink="/single-blog-3">Online Learning Can Prepare Students For A Fast-Changing</a></h3>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-sm-6 col-md-6">
                            <div class="single-blog-post-item">
                                <div class="post-image">
                                    <a routerLink="/single-blog-3" class="d-block">
                                        <img src="../assets/img/blog/img6.jpg" alt="image">
                                    </a>
                                </div>
                                <div class="post-content">
                                    <h3><a routerLink="/single-blog-3">As Learning Moves Online, Trigger Warnings Must Too</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-post-info">
                    <p>Get into details now?​ <a routerLink="/blog-3">View all posts</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>

    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape13" data-speed="0.06" data-revert="true"><img src="../assets/img/shape12.png" alt="image"></div>
    <div class="shape14" data-speed="0.06" data-revert="true"><img src="../assets/img/shape13.png" alt="image"></div>
    <div class="shape15" data-speed="0.06" data-revert="true"><img src="../assets/img/shape14.png" alt="image"></div>
</section>

<section class="partner-area ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</section>