<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>FAQ's</li>
            </ul>
            <h2>Frequently Asked Questions</h2>
        </div>
    </div>

    <div class="shape9"><img src="../assets/img/shape8.svg" alt="image"></div>
</div>

<section class="faq-area ptb-100">
    <div class="container">
        <div class="faq-accordion accordion" id="faqAccordion">
            <div class="card">
                <div class="card-header">
                    <button type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        What are the hours that technical support is available?
                    </button>
                </div>

                <div id="collapseOne" class="collapse show" data-parent="#faqAccordion">
                    <div class="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                        <p><strong>1.</strong> Sony PlayStation 4 Pro – Best Overall<br>
                            <strong>2.</strong> Xbox One S – Best Gaming Console for Multimedia<br>
                            <strong>3.</strong> Nintendo Switch – Best Hybrid Gaming Console<br>
                            <strong>4.</strong> Nintendo Switch Lite – Best for Portable Play</p>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How far in advance do I need to make an equipment request?
                    </button>
                </div>

                <div id="collapseTwo" class="collapse" data-parent="#faqAccordion">
                    <div class="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                        <ul>
                            <li>a console</li>
                            <li>Two Joy-Con controllers that are detachable</li>
                            <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                            <li>Two straps for turning the Joy-Cons into individual controllers</li>
                            <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        What is the wireless network availability on Glin?
                    </button>
                </div>

                <div id="collapseThree" class="collapse" data-parent="#faqAccordion">
                    <div class="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                        How do I connect my device to the Covenant network?
                    </button>
                </div>

                <div id="collapseFour" class="collapse" data-parent="#faqAccordion">
                    <div class="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                        <ul>
                            <li>a console</li>
                            <li>Two Joy-Con controllers that are detachable</li>
                            <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                            <li>Two straps for turning the Joy-Cons into individual controllers</li>
                            <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                        What is my Banner username?
                    </button>
                </div>

                <div id="collapseFive" class="collapse" data-parent="#faqAccordion">
                    <div class="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                        My account says it is locked. How can I unlock it?
                    </button>
                </div>

                <div id="collapseSix" class="collapse" data-parent="#faqAccordion">
                    <div class="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                        <ul>
                            <li>a console</li>
                            <li>Two Joy-Con controllers that are detachable</li>
                            <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                            <li>Two straps for turning the Joy-Cons into individual controllers</li>
                            <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="card">
                <div class="card-header">
                    <button class="collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                        How often do I have to change my password?
                    </button>
                </div>

                <div id="collapseSeven" class="collapse" data-parent="#faqAccordion">
                    <div class="card-body">
                        <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                            sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>

    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape13" data-speed="0.06" data-revert="true"><img src="../assets/img/shape12.png" alt="image"></div>
    <div class="shape14" data-speed="0.06" data-revert="true"><img src="../assets/img/shape13.png" alt="image"></div>
    <div class="shape15" data-speed="0.06" data-revert="true"><img src="../assets/img/shape14.png" alt="image"></div>
</section>