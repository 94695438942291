<div class="yoga-main-banner">
    <div class="container-fluid">
        <div class="yoga-banner-content">
            <img src="../assets/img/yoga-banner.png" alt="image" class="main-image">

            <div class="content">
                <img src="../assets/img/top-img.png" class="top-image" alt="image">
                <h1 class="playfair-display-font">Accredited Online Yoga Teacher Training</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                <br>
                <img src="../assets/img/bottom-img.png" class="bottom-image" alt="image">
            </div>
        </div>
    </div>

    <div class="banner-shape2"><img src="../assets/img/banner-shape2.png" alt="image"></div>
    <div class="banner-shape3"><img src="../assets/img/banner-shape3.png" alt="image"></div>
</div>

<section class="training-area bg-f5f7fa pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="playfair-display-font">Why Online Yoga Training</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-training-box">
                    <img src="../assets/img/training-img1.png" alt="image">
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Flexibility</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-training-box">
                    <img src="../assets/img/training-img2.png" alt="image">
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Comfort</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-training-box">
                    <img src="../assets/img/training-img3.png" alt="image">
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Resources</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="link-btn">Start Now</a>
                </div>
            </div>
        </div>
    </div>

    <div class="tree-shape2"><img src="../assets/img/tree-shape2.png" alt="image"></div>
    <div class="tree-shape3"><img src="../assets/img/tree-shape3.png" alt="image"></div>
</section>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image text-center">
                    <img src="../assets/img/about-img11.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2 class="playfair-display-font">Feel Like You Are Attending Your Classes Physically!</h2>
                    <p>Odemy training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives
                        will help you to learn better and quicker than the traditional ways of learning skills.</p>
                    <a routerLink="/profile-authentication" class="default-btn mt-2"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses-area pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go at your own pace</span>
            <h2 class="playfair-display-font">Become a Certified Yoga Teacher</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-yoga-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block">
                            <img src="../assets/img/courses/yoga-img1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="courses-content">
                        <h3 class="playfair-display-font"><a routerLink="/single-courses-1">200 Hour YTT Course</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a routerLink="/single-courses-1" class="default-btn">View Details<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-yoga-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block">
                            <img src="../assets/img/courses/yoga-img2.jpg" alt="image">
                        </a>
                    </div>

                    <div class="courses-content">
                        <h3 class="playfair-display-font"><a routerLink="/single-courses-1">300 Hour YTT Course</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a routerLink="/single-courses-1" class="default-btn">View Details<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-yoga-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block">
                            <img src="../assets/img/courses/yoga-img3.jpg" alt="image">
                        </a>
                    </div>

                    <div class="courses-content">
                        <h3 class="playfair-display-font"><a routerLink="/single-courses-1">500 Hour YTT Course</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a routerLink="/single-courses-1" class="default-btn">View Details<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area bg-6ba292 ptb-100">
    <div class="container">
        <div class="feedback-slides-two owl-carousel owl-theme">
            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>John Smith</h3>
                        <span>Python Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>Sarah Taylor</h3>
                        <span>PHP Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>David Warner</h3>
                        <span>QA Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="divider2"></div>
    <div class="divider3"></div>
    <div class="tree-shape"><img src="../assets/img/tree-shape.png" alt="image"></div>
</section>

<section class="experience-area ptb-100 extra-padding">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="experience-content">
                    <span class="sub-title">Join our World Famous Yoga Teacher Training Course... Online!</span>
                    <h2 class="playfair-display-font">200hr, 300hr & 500hr Certified Online Yoga courses</h2>
                    <p>Odemy training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives
                        will help you to learn better and quicker than the traditional ways of learning skills.</p>
                    <ul class="features-list">
                        <li><i class="bx bx-check"></i> Receive a Yoga Alliance Certificate</li>
                        <li><i class="bx bx-check"></i> 100hrs of Live Streaming Zoom Classes</li>
                        <li><i class="bx bx-check"></i> Watch Video Modules in Your Free Time</li>
                        <li><i class="bx bx-check"></i> Flex Schedule with Extended Time from Yoga Alliance</li>
                        <li><i class="bx bx-check"></i> Practice Traditional Hatha and vinyasa Yoga</li>
                        <li><i class="bx bx-check"></i> Bonus Aerial & Acro Yoga Workshops</li>
                    </ul>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Get Started Now<span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="experience-image">
                    <img src="../assets/img/experience-img1.jpg" alt="image">
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                    <span class="title"><span>15</span> Years of Experience</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area bg-fffaf3">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="1926">00</span></h3>
                    <p>Finished Sessions</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="3279">00</span></h3>
                    <p>Enrolled Learners</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="250">00</span></h3>
                    <p>Online Instructors</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="100">00</span>%</h3>
                    <p>Satisfaction Rate</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="download-syllabus-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="download-syllabus-image">
                    <img src="../assets/img/download.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="download-syllabus-form">
                    <h2 class="playfair-display-font">Download Courses Syllabus</h2>
                    <p>Your email address will not be published. Required fields are marked *</p>

                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Name *">
                        </div>

                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Your Email *">
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Phone *">
                        </div>

                        <div class="form-group">
                            <select>
                                <option>200 Hour YTT Course *</option>
                                <option>300 Hour YTT Course *</option>
                                <option>400 Hour YTT Course *</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <select>
                                <option>How Did You Hear About Us? *</option>
                                <option>How Did You Hear About Us? *</option>
                                <option>How Did You Hear About Us? *</option>
                            </select>
                        </div>

                        <button class="default-btn"><i class="flaticon-tick"></i>Submit Now<span></span></button>
                    </form>

                    <div class="syllabus-shape1"><img src="../assets/img/syllabus-shape1.png" alt="image"></div>
                    <div class="syllabus-shape2"><img src="../assets/img/syllabus-shape2.png" alt="image"></div>
                    <div class="syllabus-shape3"><img src="../assets/img/syllabus-shape3.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Pricing</span>
            <h2 class="playfair-display-font">Our Flexible Pricing Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <img src="../assets/img/yoga-pricing-img1.png" alt="image">
                        <h3>200 Hour TTC</h3>
                    </div>

                    <div class="pricing-features">
                        <ul>
                            <li>Examine traditional yoga asanas</li>
                            <li>Learn meditation and philosophy</li>
                            <li>Connect energetics and anatomy</li>
                            <li>Practice teaching for confidence</li>
                            <li>Become a Yoga Alliance RYT200</li>
                        </ul>
                    </div>

                    <div class="price">
                        $499.99
                        <span>Yoga Alliance Certified</span>
                    </div>

                    <a routerLink="/" class="default-btn">Book Now<span></span></a>

                    <div class="pricing-shape1"><img src="../assets/img/pricing-shape1.png" alt="image"></div>
                    <div class="pricing-shape2"><img src="../assets/img/pricing-shape2.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <img src="../assets/img/yoga-pricing-img2.png" alt="image">
                        <h3>200 Hour TTC</h3>
                    </div>

                    <div class="pricing-features">
                        <ul>
                            <li>Fine tune yoga alignment and form</li>
                            <li>Explore the fine art of sequencing</li>
                            <li>Delve deeper into ancient scripture</li>
                            <li>Study therapeutic based anatomy</li>
                            <li>Become a Yoga Alliance RYT300</li>
                        </ul>
                    </div>

                    <div class="price">
                        $599.99
                        <span>Yoga Alliance Certified</span>
                    </div>

                    <a routerLink="/" class="default-btn">Book Now<span></span></a>

                    <div class="pricing-shape1"><img src="../assets/img/pricing-shape1.png" alt="image"></div>
                    <div class="pricing-shape2"><img src="../assets/img/pricing-shape2.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <img src="../assets/img/yoga-pricing-img3.png" alt="image">
                        <h3>500 Hour TTC</h3>
                    </div>

                    <div class="pricing-features">
                        <ul>
                            <li>Get the highest level certification</li>
                            <li>Become a respected yoga master</li>
                            <li>Work at luxury resorts and retreats</li>
                            <li>Lead your own teacher trainings</li>
                            <li>Become a Yoga Alliance RYT500</li>
                        </ul>
                    </div>

                    <div class="price">
                        $699.99
                        <span>Yoga Alliance Certified</span>
                    </div>

                    <a routerLink="/" class="default-btn">Book Now<span></span></a>

                    <div class="pricing-shape1"><img src="../assets/img/pricing-shape1.png" alt="image"></div>
                    <div class="pricing-shape2"><img src="../assets/img/pricing-shape2.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our New</span>
            <h2 class="playfair-display-font">Check Out Our Latest Blog</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/yoga-blog-img1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Yoga</a>
                        <h3 class="playfair-display-font"><a routerLink="/single-blog-1">Surprising SUP Yoga Poses You’ll Want to Try This Summer</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/yoga-blog-img2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">YTT</a>
                        <h3 class="playfair-display-font"><a routerLink="/single-blog-1">7 Things I Learned From Doing One of Those Social Media Yoga</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 29, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/yoga-blog-img3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Yoga</a>
                        <h3 class="playfair-display-font"><a routerLink="/single-blog-1">10 Ways to Get Real About Your Body’s Limitations & Avoid</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image">
                                    <span>David Warner</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 28, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area bg-f5f7fa ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-image">
                    <img src="../assets/img/subscribe-img1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="subscribe-content">
                    <span class="sub-title">Go At Your Own Pace</span>
                    <h2 class="playfair-display-font">Subscribe To Our Newsletter</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <form class="newsletter-form">
                        <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                        <button type="submit" class="default-btn"><i class="flaticon-user"></i>Subscribe Now<span></span></button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape13" data-speed="0.06" data-revert="true"><img src="../assets/img/shape12.png" alt="image"></div>
</section>

<section class="partner-area bg-6ba292 ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</section>