<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Accueil</a></li>
                <li>Emploi du temps</li>
            </ul>
            <h2>Emploi du temps</h2>
        </div>
    </div>

    <div class="shape9"><img src="../assets/img/shape8.svg" alt="image" /></div>
</div>


<section class="courses-area pb-100">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="row">
                    <div class="col-lg-4 col-md-6" *ngFor="let flyer of flyerDetails;">
                        <div class=" single-courses-box" style="height: 90%;">
                            <div class="courses-image">
                                <a href="{{baseFileUrl + flyer.file_name}}" target="_blank" class="d-block image">
                                    <img src="{{flyer.thumbnail}}" alt="image"
                                        style="width: 750px;height: 250px;object-fit: cover;"
                                        onerror="onErrChangeSrc(this)">
                                </a>
                                <a href="{{baseFileUrl + flyer.file_name}}" target="_blank">
                                    <div class="btn-download shadow"><i class="bx bx-download"></i></div>
                                </a>
                            </div>

                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center mb-1" style="line-height: 1;">
                                    <span>{{flyer.type}}</span>
                                </div>
                                <h3><a href="{{baseFileUrl + flyer.file_name}}" target="_blank">{{flyer.name}}</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</section>

<!-- <section class="profile-area pb-70 mx-0">
    <div class="container" style="max-width: 1393px;">
        <div class="profile-courses-quizzes mt-0">
            <div class="tab-content" id="myTabContent">
                <h2 class="tableTitle">EDT 6ème</h2>
                <div class="tab-pane fade show active" role="tabpanel" style="width: 86.8rem;">
                    <div class="tab-content" id="myTab5Content">
                        <div class="tab-pane fade show active" role="tabpanel">
                            <div class="table-responsive">

                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>temps</th>
                                            <th>Lundi</th>
                                            <th>Mardi</th>
                                            <th>Mercredi</th>
                                            <th>Jeudi</th>
                                            <th>Vendredi</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>8h00 - 8h50</td>
                                            <td>-</td>
                                            <td>MATHS</td>
                                            <td>EDUCATION PHYSIQUE <br> SPORTIVE</td>
                                            <td>ESPAGNOL</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                        </tr>
                                        <tr>
                                            <td>8h50 - 9h40</td>
                                            <td>-</td>
                                            <td>ATELIER NUMERIQUE</td>
                                            <td>EDUCATION PHYSIQUE <br> SPORTIVE</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                            <td>ESPAGNOL s1 D <br> DEVOIR SURVEILLE s2</td>
                                        </tr>
                                        <tr>
                                            <td>9h40 - 10h30</td>
                                            <td>-</td>
                                            <td>PHYSIQUE CHIMIE</td>
                                            <td>BULLE DE LECTURE</td>
                                            <td>FRANCAIS</td>
                                            <td>FRANCAIS</td>
                                        </tr>
                                        <tr>
                                            <td>10h45 - 11h35</td>
                                            <td>EDUCATION PHYSIQUE <br> SPORTIVE</td>
                                            <td>ARTS PLASTIQUES</td>
                                            <td>EN CHEMIN</td>
                                            <td>ANGLAIS</td>
                                            <td>HEURE DE VIE <br> CLASSE</td>
                                        </tr>
                                        <tr>
                                            <td>11h35 - 12h25</td>
                                            <td>ESPAGNOL</td>
                                            <td>ACCOMPAGNEMENT</td>
                                            <td>FRANCAIS</td>
                                            <td>PE:***</td>
                                            <td>REPAS</td>
                                        </tr>
                                        <tr>
                                            <td>12h25 - 13h45</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>13h45 - 14h35</td>
                                            <td>ANGLAIS</td>
                                            <td>SVT</td>
                                            <td>SCIENCES ET <br> TECHNOLOGIE</td>
                                            <td>REPAS</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>14h35 - 15h25</td>
                                            <td>MATHS</td>
                                            <td>ANGLAIS</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                            <td>EDUCATION MUSICALE</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>15h40 - 16h30</td>
                                            <td>APPRENDRE AUTREMENT <br> ACCOMPAGNEMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>MATHS</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>16h30 - 17h20</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>17h20 - 17h45</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>17h45 - 18H45</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr class="bg-danger">
                                            <td class="text-white">Remarques</td>
                                            <td class="text-white" colspan="5">
                                                <b>s1</b> - Premier semestre &nbsp;
                                                | &nbsp; <b>s2</b> - Deuxième semestre
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 class="tableTitle mt-5">EDT 5ème</h2>
                <div class="tab-pane fade show active" role="tabpanel" style="width: 86.8rem;">
                    <div class="tab-content" id="myTab5Content">
                        <div class="tab-pane fade show active" role="tabpanel">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>temps</th>
                                            <th>Lundi</th>
                                            <th>Mardi</th>
                                            <th>Mercredi</th>
                                            <th>Jeudi</th>
                                            <th>Vendredi</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>8h00 - 8h50</td>
                                            <td>-</td>
                                            <td>FRANCAIS sa <br> SCIENCES ET TECHNO sb</td>
                                            <td>EDUCATION PHYSIQUE <br> SPORTIVE</td>
                                            <td>ESPAGNOL</td>
                                            <td>FRANCAIS</td>
                                        </tr>
                                        <tr>
                                            <td>8h50 - 9h40</td>
                                            <td>-</td>
                                            <td>ATELIER NUMERIQUE</td>
                                            <td>-</td>
                                            <td>MATHS</td>
                                            <td>ESPAGNOL s1 <br> DEVOIR SURVEILLE s2</td>
                                        </tr>
                                        <tr>
                                            <td>9h40 - 10h30</td>
                                            <td>-</td>
                                            <td>BULLE DE LECTURE</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                            <td>MATHS</td>
                                        </tr>
                                        <tr>
                                            <td>10h45 - 11h35</td>
                                            <td>EDUCATION PHYSIQUE <br> SPORTIVE</td>
                                            <td>ARTS PLASTIQUES</td>
                                            <td>EN CHEMIN</td>
                                            <td>ANGLAIS</td>
                                            <td>HEURE DE VIE CLASSE</td>
                                        </tr>
                                        <tr>
                                            <td>11h35 - 12h25</td>
                                            <td>ESPAGNOL</td>
                                            <td>SVT</td>
                                            <td>PHYSIQUE CHIMIE sa <br> SVT sb</td>
                                            <td>PHYSIQUE CHIMIE</td>
                                            <td>REPAS</td>
                                        </tr>
                                        <tr>
                                            <td>12h25 - 13h45</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>13h45 - 14h35</td>
                                            <td>ANGLAIS</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                            <td>MATHS</td>
                                            <td>EDUCATION MUSICALE</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>14h35 - 15h25</td>
                                            <td>FRANCAIS</td>
                                            <td>ANGLAIS</td>
                                            <td>SCIENCES ET <br> TECHNOLOGIE</td>
                                            <td>FRANCAIS</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>15h40 - 16h30</td>
                                            <td>APPRENDRE AUTREMENT <br> ACCOMPAGNEMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>16h30 - 17h20</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>17h20 - 17h45</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>17h45 - 18H45</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr class="bg-danger">
                                            <td class="text-white">Remarques</td>
                                            <td class="text-white" colspan="5">
                                                <b>sa</b> - Semaine A &nbsp;
                                                | &nbsp; <b>sb</b> - Semaine B &nbsp;
                                                | &nbsp; <b>s1</b> - Premier semestre &nbsp;
                                                | &nbsp; <b>s2</b> - Deuxième semestre &nbsp;
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 class="tableTitle mt-5">EDT 4ème</h2>
                <div class="tab-pane fade show active" role="tabpanel" style="width: 86.8rem;">
                    <div class="tab-content" id="myTab5Content">
                        <div class="tab-pane fade show active" role="tabpanel">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>temps</th>
                                            <th>Lundi</th>
                                            <th>Mardi</th>
                                            <th>Mercredi</th>
                                            <th>Jeudi</th>
                                            <th>Vendredi</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>8h00 - 8h50</td>
                                            <td>-</td>
                                            <td>ESPAGNOL</td>
                                            <td>MATHS</td>
                                            <td>ARTS PLASTIQUES</td>
                                            <td>EDUCATION PHYSIQUE <br> SPORTIVE</td>
                                        </tr>
                                        <tr>
                                            <td>8h50 - 9h40</td>
                                            <td>-</td>
                                            <td>MATHS</td>
                                            <td>ANGLAIS</td>
                                            <td>FRANCAIS</td>
                                            <td>EDUCATION PHYSIQUE <br> SPORTIVE</td>
                                        </tr>
                                        <tr>
                                            <td>9h40 - 10h30</td>
                                            <td>-</td>
                                            <td>FRANCAIS</td>
                                            <td>BULLE DE LECTURE</td>
                                            <td>MATHS</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                        </tr>
                                        <tr>
                                            <td>10h45 - 11h35</td>
                                            <td>ESPAGNOL</td>
                                            <td>SVT</td>
                                            <td>FRANCAIS sa <br> EDUCATION PHYSIQUE sb</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                            <td>EN CHEMIN</td>
                                        </tr>
                                        <tr>
                                            <td>11h35 - 12h25</td>
                                            <td>SCIENCES ET TECHNO sa <br> SCIENCES ET TECHNO sb</td>
                                            <td>ATELIER NUMERIQUE</td>
                                            <td>ESPAGNOL sa <br> EDUCATION PHYSIQUE sb</td>
                                            <td>HEURE DE VIE <br> CLASSE</td>
                                            <td>REPAS</td>
                                        </tr>
                                        <tr>
                                            <td>12h25 - 13h45</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>13h45 - 14h35</td>
                                            <td>FRANCAIS</td>
                                            <td>ANGLAIS</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                            <td>SCIENCES ET TECHNO sa <br> DEVOIR SURVEILLE sb</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>14h35 - 15h25</td>
                                            <td>ANGLAIS</td>
                                            <td>PHYSIQUE CHIMIE</td>
                                            <td>SVT sa <br> PHYSIQUE CHIMIE sb</td>
                                            <td>EDUCATION MUSICALE</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>15h40 - 16h30</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>16h30 - 17h20</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT <br> ACCOMPAGNEMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>17h20 - 17h45</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>17h45 - 18H45</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr class="bg-danger">
                                            <td class="text-white">Remarques</td>
                                            <td class="text-white" colspan="5">
                                                <b>sa</b> - Semaine A &nbsp;
                                                | &nbsp; <b>sb</b> - Semaine B &nbsp;
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <h2 class="tableTitle mt-5">EDT 3ème</h2>
                <div class="tab-pane fade show active" role="tabpanel" style="width: 86.8rem;">
                    <div class="tab-content" id="myTab5Content">
                        <div class="tab-pane fade show active" role="tabpanel">
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>temps</th>
                                            <th>Lundi</th>
                                            <th>Mardi</th>
                                            <th>Mercredi</th>
                                            <th>Jeudi</th>
                                            <th>Vendredi</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>8h00 - 8h50</td>
                                            <td>-</td>
                                            <td>ANGLAIS</td>
                                            <td>FRANCAIS sa <br> ESPAGNOL sb</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                            <td>EDUCATION PHYSIQUE <br> SPORTIVE</td>
                                        </tr>
                                        <tr>
                                            <td>8h50 - 9h40</td>
                                            <td>-</td>
                                            <td>FRANCAIS</td>
                                            <td>MATHS</td>
                                            <td>ESPAGNOL</td>
                                            <td>EDUCATION PHYSIQUE <br> SPORTIVE</td>
                                        </tr>
                                        <tr>
                                            <td>9h40 - 10h30</td>
                                            <td>-</td>
                                            <td>ARTS PLASTIQUES</td>
                                            <td>ANGLAIS</td>
                                            <td>EDUCATION MUSICALE</td>
                                            <td>EN CHEMIN</td>
                                        </tr>
                                        <tr>
                                            <td>10h45 - 11h35</td>
                                            <td>ANGLAIS</td>
                                            <td>MATHS</td>
                                            <td>HISTOIRE GEOGRAPHI sa <br> EDUCATION PHYSIQUE sb</td>
                                            <td>FRANCAIS</td>
                                            <td>HEURE DE VIE <br> CLASSE</td>
                                        </tr>
                                        <tr>
                                            <td>11h35 - 12h25</td>
                                            <td>FRANCAIS</td>
                                            <td>ESPAGNOL</td>
                                            <td>SCIENCES ET TECHNO sa <br> EDUCATION PHYSIQUE sb</td>
                                            <td>SCIENCES ET TECHNOLOGIE</td>
                                            <td>REPAS</td>
                                        </tr>
                                        <tr>
                                            <td>12h25 - 13h45</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>REPAS</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>13h45 - 14h35</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                            <td>PHYSIQUE CHIMIE</td>
                                            <td>SVT</td>
                                            <td>MATHS</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>14h35 - 15h25</td>
                                            <td>BULLE DE LECTURE</td>
                                            <td>HISTOIRE GEOGRAPHIE</td>
                                            <td>PHYSIQUE CHIMIE sa <br> SVT sb</td>
                                            <td>DEVOIR SURVEILLE</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>15h40 - 16h30</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>ATELIER NUMERIQUE</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>16h30 - 17h20</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT <br> ACCOMPAGNEMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>APPRENDRE AUTREMENT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>17h20 - 17h45</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>RECREATION GOUTER</td>
                                            <td>-</td>
                                        </tr>
                                        <tr>
                                            <td>17h45 - 18H45</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>ETUDE INTERNAT</td>
                                            <td>-</td>
                                        </tr>
                                        <tr class="bg-danger">
                                            <td class="text-white">Remarques</td>
                                            <td class="text-white" colspan="5">
                                                <b>sa</b> - Semaine A &nbsp;
                                                | &nbsp; <b>sb</b> - Semaine B &nbsp;
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->