<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
            <h2>About Us</h2>
        </div>
    </div>

    <div class="shape9"><img src="../assets/img/shape8.svg" alt="image"></div>
</div>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Education for everyone</span>
            <h2>Online Coaching Lessons For Remote Learning</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box without-padding">
                    <div class="icon">
                        <i class="flaticon-brain-process"></i>
                    </div>
                    <h3>Learn the Latest Skills</h3>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features-box without-padding">
                    <div class="icon">
                        <i class="flaticon-computer"></i>
                    </div>
                    <h3>Go at Your Own Pace</h3>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-features-box without-padding">
                    <div class="icon">
                        <i class="flaticon-shield-1"></i>
                    </div>
                    <h3>Learn from Industry Experts</h3>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-area-two pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Distance Learning</span>
                    <h2>Build Your Project Management Skills Online, Anytime</h2>
                    <p>Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new skill quickly like, project team leadership or agile? Browse our most popular online courses.</p>
                    <p><strong>Grow your knowledge and your opportunities with thought leadership, training and tools.</strong></p>
                    <a routerLink="/" class="link-btn">Explore Learning</a>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <div class="image">
                        <img src="../assets/img/about-img6.jpg" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                    <div class="shape10" data-speed="0.06" data-revert="true"><img src="../assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
</section>

<section class="feedback-area bg-fffaf3 ptb-100">
    <div class="container">
        <div class="feedback-slides-two owl-carousel owl-theme">
            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>John Smith</h3>
                        <span>Python Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>Sarah Taylor</h3>
                        <span>PHP Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                    <div class="title">
                        <h3>David Warner</h3>
                        <span>QA Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="divider2"></div>
    <div class="divider3"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/shape8.svg" alt="image"></div>
</section>

<section class="advisor-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Course Advisor</span>
            <h2>Meet Our World-class Instructors</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="advisor-slides-two owl-carousel owl-theme">
            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img4.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>

                <div class="advisor-content">
                    <h3><a routerLink="/profile">James Andy</a></h3>
                    <span>Project Management Expert</span>
                </div>
            </div>

            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img5.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>

                <div class="advisor-content">
                    <h3><a routerLink="/profile">Jassica Hische</a></h3>
                    <span>Illustrator Expert</span>
                </div>
            </div>

            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img6.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>

                <div class="advisor-content">
                    <h3><a routerLink="/profile">Alister Cock</a></h3>
                    <span>QA Project Expert</span>
                </div>
            </div>

            <div class="single-advisor-item">
                <div class="advisor-image">
                    <img src="../assets/img/advisor/img7.jpg" alt="image">
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>

                <div class="advisor-content">
                    <h3><a routerLink="/profile">Lina Ninja</a></h3>
                    <span>QA Project Expert</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area bg-fffaf3">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="1926">00</span></h3>
                    <p>Finished Sessions</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="3279">00</span></h3>
                    <p>Enrolled Learners</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="250">00</span></h3>
                    <p>Online Instructors</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="100">00</span>%</h3>
                    <p>Satisfaction Rate</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="premium-access-area ptb-100">
    <div class="container">
        <app-premium-access></app-premium-access>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape8" data-speed="0.06" data-revert="true"><img src="../assets/img/shape7.png" alt="image"></div>
</section>