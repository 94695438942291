<div class="main-banner-wrapper">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="main-banner-wrapper-content">
                    <h1>Achieve the Best Results with Academy</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="main-banner-wrapper-image text-center">
                    <img src="../assets/img/banner-img3.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape14"><img src="../assets/img/banner-shape15.png" alt="image"></div>
    <div class="banner-shape15"><img src="../assets/img/banner-shape16.png" alt="image"></div>
    <div class="banner-shape16"><img src="../assets/img/banner-shape17.png" alt="image"></div>
    <div class="banner-shape17"><img src="../assets/img/banner-shape18.png" alt="image"></div>
    <div class="banner-shape18"><img src="../assets/img/banner-shape19.png" alt="image"></div>
</div>

<section class="courses-area pt-100 pb-70 bg-f5f7fa">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Welcome to Odemy</span>
            <h2>Our Language Courses</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-language-courses-box">
                    <img src="../assets/img/language-courses/img1.png" alt="image">
                    <h3>Chinese</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-right"></i>View More<span></span></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-language-courses-box">
                    <img src="../assets/img/language-courses/img2.png" alt="image">
                    <h3>Spanish</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-right"></i>View More<span></span></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-language-courses-box">
                    <img src="../assets/img/language-courses/img3.png" alt="image">
                    <h3>Japanese</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-right"></i>View More<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image text-center">
                    <img src="../assets/img/about-img9.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Why choose us</span>
                    <h2>Language Courses to Help You Explore The World</h2>
                    <p>We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes!</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Skiled Teachers</span></li>
                        <li><span><i class="flaticon-time-left"></i> Afordable Courses</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Efficient & Flexible</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Lifetime Access</span></li>
                    </ul>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-with-bg-image ptb-100 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="feedback-slides feedback-slides-style-two owl-theme owl-carousel">
            <div class="single-feedback-item-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user1.jpg" alt="image">
                    <div class="title">
                        <h3>John Smith</h3>
                        <span>Python Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user2.jpg" alt="image">
                    <div class="title">
                        <h3>David Warner</h3>
                        <span>Java Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-feedback-item-box">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                <div class="client-info d-flex align-items-center">
                    <img src="../assets/img/user3.jpg" alt="image">
                    <div class="title">
                        <h3>Sarah Taylor</h3>
                        <span>PHP Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go At Your Own Pace</span>
            <h2>Top Selling Courses</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="../assets/img/courses/img1.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$39</div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <span>Alex Morgan</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Online Course For Korean Level 1</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                            <li><i class='flaticon-people'></i> 145 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="../assets/img/courses/img2.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$49</div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Online Course For Marathi Level 2</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 100 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="../assets/img/courses/img3.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$59</div>
                    </div>

                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3><a routerLink="/single-courses-1">Online Course For French Level 3</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 150 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <p>Get the most dedicated consultation for your life-changing course. Earn a certification for your effort and passion <a routerLink="/profile-authentication">Join Free Now</a>.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="funfacts-area bg-fffaf3">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="1926">00</span></h3>
                    <p>Finished Sessions</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="3279">00</span></h3>
                    <p>Enrolled Learners</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="250">00</span></h3>
                    <p>Online Instructors</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="100">00</span>%</h3>
                    <p>Satisfaction Rate</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="information-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="information-content">
                    <span class="sub-title">Information</span>
                    <h2>How To Apply?</h2>

                    <ul class="apply-details">
                        <li>
                            <div class="icon">
                                <i class="flaticon-checkmark"></i>
                            </div>
                            <h3>Select Suitable Course</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-webinar"></i>
                            </div>
                            <h3>Student Information</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-credit-card-1"></i>
                            </div>
                            <h3>Payment Information</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-verify"></i>
                            </div>
                            <h3>Register Now</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="information-image text-center">
                    <img src="../assets/img/information-img.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100 bg-fffaf3">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="free-trial-image text-center">
                    <img src="../assets/img/free-trial-img.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="free-trial-form">
                    <span class="sub-title">Free Trial</span>
                    <h2>Sign Up For A Free Trial</h2>

                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Name *">
                        </div>
                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Your Email *">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Phone *">
                        </div>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Subject *">
                        </div>
                        <button type="submit">Register Now</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="events-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Events</span>
            <h2>Our Upcoming Events</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-events-box">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="../assets/img/events/img1.jpg" alt="image">
                        </a>
                        <span class="date">Wed, 20 May, 2020</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/single-events">Global Conference on Business Management</a></h3>
                        <span class="location"><i class="bx bx-map"></i>Vancover, Canada</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-events-box">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="../assets/img/events/img2.jpg" alt="image">
                        </a>
                        <span class="date">Tue, 19 May, 2020</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/single-events">International Conference on Teacher Education</a></h3>
                        <span class="location"><i class="bx bx-map"></i>Sydney, Australia</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-events-box">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="../assets/img/events/img3.jpg" alt="image">
                        </a>
                        <span class="date">Mon, 18 May, 2020</span>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/single-events">International Conference on Special Needs Education</a></h3>
                        <span class="location"><i class="bx bx-map"></i>Istanbul, Turkey</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>

    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape13" data-speed="0.06" data-revert="true"><img src="../assets/img/shape12.png" alt="image"></div>
    <div class="shape14" data-speed="0.06" data-revert="true"><img src="../assets/img/shape13.png" alt="image"></div>
    <div class="shape15" data-speed="0.06" data-revert="true"><img src="../assets/img/shape14.png" alt="image"></div>
</section>

<section class="partner-area bg-fe4a55 ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</section>

<app-partner></app-partner>
<app-partner></app-partner>
<app-partner></app-partner>
<app-partner></app-partner>