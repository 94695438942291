import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { getAllGallery, getAllGalleryCatagories } from 'src/app/models/endpoints';
@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit {
    galleryList: any;
    galleryListCount = 0;
    galleryCatagories: any;
    baseURl = 'http://st.dizier-admin.cagliero.eu/app/assets/img/uploads/ps-gallery/';

    defaultImgURL = '../assets/img/courses/img1.jpg';

    noData = true;
    loading = true;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.callTo_getAllGalleryCatagories();
        this.http.get<any>(environment.baseUrl + getAllGallery).subscribe({
            next: (data) => {
                if (data.status == 1) {
                    var dataList = data.data;

                    if (dataList.length != 0) {
                        this.noData = false;
                    }

                    this.galleryList = dataList;
                    this.galleryListCount = this.galleryList.length;
                    this.loading = false;
                    setTimeout(() => {
                        document.getElementById('sortToAllGallery_btn').click();
                    }, 100);

                } else {
                    this.loading = false;
                }
            },
            error: (error) => {
                this.noData = true;
                this.loading = false;
                this.openSnackBar('Oops, Something went wrong. please try again later. ', 1);
                console.log(error.message);
            },
        });
    }

    callTo_getAllGalleryCatagories() {
        this.http.get<any>(environment.baseUrl + getAllGalleryCatagories).subscribe({
            next: (data) => {
                if (data.status == 1) {
                    this.galleryCatagories = data.data;
                }
            },
            error: (error) => {
                console.log(error.message);
            },
        });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, 'Dismiss', { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, 'Dismiss');
        }
    }

}
