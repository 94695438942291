<section class="courses-details-area">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12" style="margin: 6rem auto;">

                <div class="">

                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade  show active" id="dropDwn01" role="tabpanel">
                            <div class="internat-dropDwn01">
                                <div class="faq-accordion accordion" id="faqAccordion">

                                    <div class="card">
                                        <div class="card-header ">
                                            <button type="button" class="cu-clr-main" data-toggle="collapse"
                                                data-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                Présentation de l’internat
                                            </button>
                                        </div>

                                        <div id="collapseOne" class="collapse show" data-parent="#faqAccordion">
                                            <div class="card-body">
                                                <p>
                                                    L’internat du groupe scolaire Estic-Don Bosco accueille actuellement
                                                    84 élèves de la sixième au BTS.<br> Les chambres sont distribuées
                                                    sur
                                                    trois étages :
                                                </p>
                                                <ul class="my-4" style="list-style-type: circle;">
                                                    <li class="mb-1">1er étage : filles</li>
                                                    <li class="mb-1">2ème étage : collégiens et lycéens garçons</li>
                                                    <li class="mb-1">3ème étage : lycéens et BTS garçons</li>
                                                </ul>
                                                <p>
                                                    Les chambres sont de 2 ou 3 internes sauf pour les BTS pour qui l’on
                                                    privilégie des chambres individuelles. <br>

                                                    Mme Alison POETTE et deux autres surveillants encadrent avec
                                                    bienveillance les jeunes jusqu’au matin.<br> Ils sont présents, à
                                                    l’écoute edet disponibles à tout moment si les jeunes souhaitent les
                                                    rencontrer.

                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header ">
                                            <button type="button" class="cu-clr-main" data-toggle="collapse"
                                                data-target="#collapseTwo" aria-expanded="true"
                                                aria-controls="collapseTwo">
                                                Ma vie à l’internat Estic-Don Bosco
                                            </button>
                                        </div>

                                        <div id="collapseTwo" class="collapse" data-parent="#faqAccordion">
                                            <div class="card-body">
                                                <ul>
                                                    <li>
                                                        <strong>J’étudie</strong>
                                                        <ul class="pl-4 py-2" style="list-style-type: circle;">
                                                            <li>J’étudie en silence pendant l’étude</li>
                                                            <li>Je fais mon travail pour le lendemain</li>
                                                            <li>Je peux demander de l’aide à un camarade avec
                                                                l’autorisation de l’éducateur de vie scolaire.</li>
                                                            <li>Je m’avance dans mon travail</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Je me repose. Je dors</strong>
                                                        <ul class="pl-4 py-2" style="list-style-type: circle;">
                                                            <li>Je profite de mes temps calmes</li>
                                                            <li>Je me couche à heure fixe afin de respecter mon hygiène
                                                                de vie</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Je deviens autonome</strong>
                                                        <ul class="pl-4 py-2" style="list-style-type: circle;">
                                                            <li>Je m’avance dans mon travail</li>
                                                            <li>J’organise mon temps afin de respecter les horaires du
                                                                règlement</li>
                                                            <li>J’inclus les temps collectifs dans mon rythme quotidien
                                                            </li>

                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Je développe ma personnalité en apprenant le respect des
                                                            autres et de moi-même </strong>
                                                        <ul class="pl-4 py-2" style="list-style-type: circle;">
                                                            <li>Je respecte le règlement</li>
                                                            <li>Je respecte les différences</li>
                                                            <li>J’apprends à vivre en communauté</li>
                                                            <li>Je m’éveille aux valeurs humaines (intervention
                                                                hebdomadaire du Père Vincent pour les volontaires pour
                                                                des échanges, des discussions, des temps de musique, des
                                                                réflexions, etc.)</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Je partage des moments conviviaux </strong>
                                                        <ul class="pl-4 py-2" style="list-style-type: circle;">
                                                            <li>Je participe aux temps de célébration</li>
                                                            <li>J’allume mon lumignon le 8 décembre</li>
                                                            <li>Nous partageons le repas de Noël</li>
                                                            <li>Tous les mois, nous mangeons le gâteau des anniversaires
                                                                du mois</li>
                                                            <li>Je participe au Bingo de fin d’année</li>
                                                            <li>Je me divertis lors de la semaine salésienne</li>
                                                            <li>Je ris beaucoup à la kermesse </li>
                                                            <li>....</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Je pratique des activités culturelles et sportives
                                                        </strong>
                                                        <ul class="pl-4 py-2" style="list-style-type: circle;">
                                                            <li>Je regarde les pièces de théâtre qui sont présentées
                                                                dans notre salle de théâtre</li>
                                                            <li>Je participe aux divers tournois (futsal, volley,
                                                                badminton…)</li>
                                                            <li>Je vois les spectacles musicaux proposés</li>
                                                            <li>Je profite des soirées jeux de société</li>
                                                            <li>J’assiste aux soirées ciné-popcorn</li>
                                                            <li>Je m’aère lors des sorties en ville ou en forêt</li>
                                                        </ul>
                                                    </li>


                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header ">
                                            <button type="button" class="cu-clr-main" data-toggle="collapse"
                                                data-target="#collapseThree" aria-expanded="true"
                                                aria-controls="collapseThree">
                                                Un jour à l’internat Estic-Don Bosco
                                            </button>
                                        </div>

                                        <div id="collapseThree" class="collapse" data-parent="#faqAccordion">
                                            <div class="card-body">
                                                <ul>
                                                    <li>06h45 : Réveil</li>
                                                    <li>06h45 / 07h00 : Lever et préparation</li>
                                                    <li>07h00 / 07h30 : Petit-déjeuner au self</li>
                                                    <li>07h45 : Arrivée au collège</li>
                                                    <li>08h00 : Les mots du matin</li>
                                                    <li>08h10 : Première sonnerie. En rang dans la cour</li>
                                                </ul>

                                                <p class="pl-4 mt-4"><strong>(Journée de cours)</strong></p>

                                                <ul>
                                                    <li>16h50 : Aide aux devoirs</li>
                                                    <li>17h45 : Etudes du soir</li>
                                                    <li>19h00 : Dîner</li>
                                                    <li>19h45 : Les collégiens récupèrent leur téléphone</li>
                                                    <li>19h45 / 21h00 : Temps libre. Etude libre. Activité diverse du
                                                        jour.</li>
                                                    <li>21h00 : Retour en chambre. Douche</li>
                                                    <li>21h45 : Récupération des téléphones par les surveillants</li>
                                                    <li>22h00 : Extinction des lumières.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>