<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Galerie</li>
            </ul>
            <h2>Galerie</h2>
        </div>
        <img src="assets/img/illustrations/asset_selection.svg" alt="" style="height: 180px; width: auto"
            class="cs-display-bottomright pt-10 cs-hide-small" />
        <!-- <img src="assets/img/related/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10"> -->
    </div>

    <div class="shape9">
        <img src="assets/img/shape8.svg" alt="Lime color Plus Sign Icon" />
    </div>
</div>

<section class="events-area pb-70">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="shorting-menu shorting-menu-style-two">
                    <button class="filter" onclick="sortToAllGallery(this)" data-filter="all" id="sortToAllGallery_btn"
                        value="all">
                        Tous les souvenirs
                    </button>

                    <button class="filter" value="{{ galleryCat.cat_id }}" onclick="sortGallery(this)"
                        *ngFor="let galleryCat of galleryCatagories">
                        {{ galleryCat.cat_name }}
                    </button>
                </div>

                <div class="shorting">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-6 px-1 galleryImgContain gCat-{{gallery.category_id}}"
                            *ngFor="let gallery of galleryList">
                            <div class="single-gallery-item mb-2 cs-animate-zoom galleryImg ">
                                <a href="{{ baseURl }}{{ gallery.img_name }}"
                                    [attr.data-lightbox]="gallery.category_id">
                                    <img src="{{ baseURl }}{{ gallery.img_name }}" alt="image"
                                        onerror="onErrChangeSrc(this)"
                                        style="width: 750px;height: 230px;object-fit: cover;" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</section>