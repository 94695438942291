<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Membership Levels</li>
            </ul>
            <h2>Membership Levels</h2>
        </div>
    </div>

    <div class="shape9"><img src="../assets/img/shape8.svg" alt="image"></div>
</div>

<section class="membership-levels-area ptb-100">
    <div class="container">
        <div class="membership-levels-table table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <h2>$500</h2>
                            <h3>Silver Membership</h3>
                            <span class="desc">You can view few of courses</span>
                        </th>
                        <th>
                            <h2>$1,000</h2>
                            <h3>Gold Membership</h3>
                            <span class="desc">You can view most of courses</span>
                        </th>
                        <th>
                            <h2>$1,500</h2>
                            <h3>Diamond Membership</h3>
                            <span class="desc">You can view all of courses</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Number of courses</td>
                        <td>5</td>
                        <td>10</td>
                        <td>15</td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Investment Fundamentals & Data Analytics</a></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Hands-On Python & R In Data Science</a></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Complete Data Science Bootcamp</a></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Programming Masterclass for Developers</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Hands-On Artificial Neural Networks</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">PRINCE2® Practitioner Certification Training</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">PMI-RMP® Certification Training</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">PRINCE2® Foundation Certification Training</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">PMP® Plus</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">PMP® Renewal Pack</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Digital Project Manager</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Agile Project Management; Agile Delivery</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Vue JS 2 - The Complete Guide</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Everything You Need to Program in Python</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Mathematical Foundation For Machine Learning</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Drawing Course - Beginner to Advanced</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Deep Learning and Artificial Intelligence</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Information About UI/UX Design Degree</a></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Photography Photo modify and Beautiful</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td><a routerLink="/single-courses-1">Professional IT Expert Certificate Course</a></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-none"><i class='bx bx-x'></i></td>
                        <td class="item-check"><i class='bx bx-check'></i></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <a routerLink="/membership-levels" class="select-btn">Get it now</a>
                        </td>
                        <td>
                            <a routerLink="/membership-levels" class="select-btn">Get it now</a>
                        </td>
                        <td>
                            <a routerLink="/membership-levels" class="select-btn">Get it now</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</section>