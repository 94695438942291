import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getAllNewsAndEvents } from 'src/app/models/endpoints';

@Component({
    selector: 'app-news-and-events',
    templateUrl: './news-and-events.component.html',
    styleUrls: ['./news-and-events.component.scss']
})
export class NewsAndEventsComponent implements OnInit {

    defaultImgURL = '../assets/img/events/img8.jpg';
    eventList: any;
    eventListCount = 0;

    noData = true;
    loading = true;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        var dataList;
        this.http.get<any>(environment.baseUrl + getAllNewsAndEvents).subscribe({
            next: (data) => {
                if (data.status == 1) {
                    dataList = data.data;
                    const newArr = dataList.map(videoId);
                    function videoId(x) {
                        if (x.imageUrl == "" && x.videoUrl != "") {

                            if (x.videoUrl.includes("https://www.youtube.com/embed/")) {
                                var videoId = x.videoUrl.replace('https://www.youtube.com/embed/', '');
                                x.videoId = videoId;
                                x.videoThumbnail = "https://img.youtube.com/vi/" + videoId + "/0.jpg";
                                x.trueVideoUrl = "https://www.youtube.com/watch?v=" + videoId;
                                x.videoSource = "youtube";

                            } else if (x.videoUrl.includes("https://drive.google.com/file/d/")) {
                                var videoId = x.videoUrl.replace('https://drive.google.com/file/d/', '');
                                videoId = videoId.split("/preview")[0]
                                x.videoId = videoId;
                                x.videoThumbnail = "https://drive.google.com/thumbnail?id=" + videoId;
                                x.videoSource = "drive";
                            }


                        }
                        x.url = fixurl(x.title);
                        return x;
                    }

                    if (dataList.length != 0) {
                        this.noData = false;
                        this.loading = false;
                        this.eventList = newArr;
                    }
                    setTimeout(() => {
                        document.getElementById('sortToAllNewsEvents_btn').click();
                    }, 100);


                    function fixurl(txt) {
                        txt = txt.toLowerCase();
                        txt = txt.replace(/ /g, "-");
                        return txt;
                    }
                }
            },
            error: (error) => {
                this.noData = true;
                this.loading = false;
                this.openSnackBar('Oops, Something went wrong. please try again later. ', 1);
                console.log(error.message);
            },
        });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, 'Dismiss', { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, 'Dismiss');
        }
    }

    fixDate(dateValue) {
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        // const d = new Date(dateValue);
        var d = new Date(dateValue.replace(" ", "T") + "Z");

        var year = d.getFullYear();
        var month = months[d.getMonth()];
        var date = d.getDate();
        var day = days[d.getDay()];

        var fixed_date = day + ', ' + date + ' ' + month + ', ' + year;
        return fixed_date;
    }
}
