<div *ngIf="loading">
    <app-loader></app-loader>
</div>
<div *ngIf="!loading">
    <div *ngIf="!noData">
        <div id="recentNewsNEvents" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators  cs-text-black">
                <li data-target="#recentNewsNEvents" [attr.data-slide-to]="i" class="{{event.activeStatus}} cs-black"
                    *ngFor="let event of eventList; let i = index"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item {{event.activeStatus}}" *ngFor="let event of eventList;">
                    <div class="d-block w-100" style="padding: 20px 100px 50px 100px;">
                        <div class="about-content-box">
                            <h4>{{event.title}}</h4>

                            <p class="cs-tiny text-uppercase" *ngIf="event.type == 'news';"><i>{{event.type}}</i>
                            </p>
                            <p class="cs-tiny text-uppercase" *ngIf="event.type == 'event';"><i>{{event.status}}
                                    {{event.type}}</i></p>


                            <p>{{stripHtml(event.description)}}</p>
                            <p><strong style="float: left;" class=" cs-small">{{fixDate(event.createdAt)}}</strong></p>
                            <br>
                            <a routerLink="/news-and-events-details/{{event.id}}/{{event.url}}" class="link-btn"
                                target="_blank">Lire la suite...</a>
                        </div>
                    </div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#recentNewsNEvents" role="button" data-slide="prev">
                <button class="cs-button cs-text-black cs-round-xxlarge">&#10094;</button>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#recentNewsNEvents" role="button" data-slide="next">
                <button class="cs-button cs-text-black cs-round-xxlarge">&#10095;</button>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>

    <div *ngIf="noData">

    </div>
</div>