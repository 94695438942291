import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getAllNewsAndEvents } from 'src/app/models/endpoints';

@Component({
    selector: 'app-recent-news-n-events',
    templateUrl: './recent-news-n-events.component.html',
    styleUrls: ['./recent-news-n-events.component.scss']
})
export class RecentNewsNEventsComponent implements OnInit {

    eventList: any;
    eventListCount = 0;

    noData = true;
    loading = true;

    constructor(
        private router: Router,
        private http: HttpClient,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        var dataList;
        this.http.get<any>(environment.baseUrl + getAllNewsAndEvents).subscribe({
            next: (data) => {
                if (data.status == 1) {
                    dataList = data.data;
                    const newArr = dataList.map(videoId);
                    function videoId(x) {
                        if (x.imageUrl == "" && x.videoUrl != "") {

                            if (x.videoUrl.includes("https://www.youtube.com/embed/")) {
                                var videoId = x.videoUrl.replace('https://www.youtube.com/embed/', '');
                                x.videoId = videoId;
                                x.videoThumbnail = "https://img.youtube.com/vi/" + videoId + "/0.jpg";

                            } else if (x.videoUrl.includes("https://drive.google.com/file/d/")) {
                                var videoId = x.videoUrl.replace('https://drive.google.com/file/d/', '');
                                videoId = videoId.split("/preview")[0]
                                x.videoId = videoId;
                                x.videoThumbnail = "https://drive.google.com/thumbnail?id=" + videoId;
                            }


                        }
                        x.url = fixurl(x.title);
                        return x;
                    }

                    dataList[0].activeStatus = 'active';

                    if (dataList.length != 0) {
                        this.noData = false;
                        this.loading = false;
                        this.eventList = newArr;
                    }
                    setTimeout(() => {
                        document.getElementById('sortToAllNewsEvents_btn').click();
                    }, 100);


                    function fixurl(txt) {
                        txt = txt.toLowerCase();
                        txt = txt.replace(/ /g, "-");
                        return txt;
                    }
                }
            },
            error: (error) => {
                this.noData = true;
                this.loading = false;
                this.openSnackBar('Oops, Something went wrong. please try again later. ', 1);
                console.log(error.message);
            },
        });
    }

    openSnackBar(message, state) {
        if (state == 1) {
            this.snackBar.open(message, 'Dismiss', { duration: 5000 });
        } else if (state == 0) {
            this.snackBar.open(message, 'Dismiss');
        }
    }

    fixurl(txt) {
        txt = txt.toLowerCase();
        txt = txt.replace(/ /g, "-");
        return txt;
    }

    fixDate(dateValue) {
        const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];
        const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];

        const d = new Date(dateValue);
        var year = d.getFullYear();
        var month = months[d.getMonth()];
        var date = d.getDate();
        var day = days[d.getDay()];

        var fixed_date = day + ', ' + date + ' ' + month + ', ' + year;
        return fixed_date;
    }

    fixDescription(txt) {
        var txtLength = txt.length;
        if (txtLength > 250) {
            let result = txt.substring(0, 250);
            var lastSpace = result.lastIndexOf(" ");
            result = result.substring(0, lastSpace);
            txt = result + " ...";
        }
        return txt;
    }

    stripHtml(html) {
        let tmp = document.createElement("DIV");

        // assign your HTML to div's innerHTML
        tmp.innerHTML = html;

        var elements_1 = tmp.getElementsByTagName('h1');
        var elements_2 = tmp.getElementsByTagName('h2');
        var elements_3 = tmp.getElementsByTagName('h3');
        var elements_4 = tmp.getElementsByTagName('a');

        while (elements_1[0]) {
            elements_1[0].parentNode.removeChild(elements_1[0])
        }

        while (elements_2[0]) {
            elements_2[0].parentNode.removeChild(elements_2[0])
        }

        while (elements_3[0]) {
            elements_3[0].parentNode.removeChild(elements_3[0])
        }

        while (elements_4[0]) {
            elements_4[0].parentNode.removeChild(elements_4[0])
        }

        let text = this.fixDescription(tmp.textContent || tmp.innerText || "")
        return text;
    }

}
