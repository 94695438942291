<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px;">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Actualités et Événements</li>
            </ul>
            <h2>Actualités et Événements</h2>

        </div>
        <img src="assets/img/illustrations/events_re.svg" alt="" style="height: 180px;width: auto;"
            class="cs-display-bottomright pt-10 cs-hide-small">
        <!-- <img src="assets/img/related/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10"> -->
    </div>

    <div class="shape9"><img src="assets/img/shape8.svg" alt="Lime color Plus Sign Icon"></div>
</div>

<section class="events-area pb-70">
    <div class="container">
        <div *ngIf="loading">
            <app-loader></app-loader>
        </div>
        <div *ngIf="!loading">
            <div *ngIf="!noData">
                <div class="shorting-menu shorting-menu-style-two cs-transparent">
                    <button class="filter" onclick="sortNAE(this)" id="sortToAllNewsEvents_btn" value="ALL">Toutes les
                        actualités</button>
                    <button class="filter" value="nouvelles" onclick="sortNAE(this)">Actualités</button>
                    <!-- <button class="filter" value="en cours" onclick="sortNAE(this)">Evénements en cours</button> -->
                    <button class="filter" value="à venir" onclick="sortNAE(this)">Evénements à venir</button>
                    <button class="filter" value="passé" onclick="sortNAE(this)">Evénements révolus</button>
                </div>

                <div class="row cs-hide" id="nodata_div">
                    <div class="col-12" align="center">
                        <div class="ptb-70">
                            <img src="assets/img/illustrations/not_found.svg" alt="" style="height: 180px;width: auto;">
                            <h6 class="mt-5">No Data Found.</h6>
                        </div>
                    </div>
                </div>

                <!-- <div class="shorting">
                    <div class="row">
                        <div *ngFor="let event of eventList;" class="col-lg-4 col-md-6 col-sm-6 px-1 neContain neCat-{{event.eventCategory}}">
                            <div class="single-events-box cs-border cs-border-light-gray cs-round-medium cs-animate-zoom">
                                <div class="image">
                                    <a routerLink="/news-and-event-details/{{event.id}}/{{event.navUrl}}" class="d-block">
                                        <img src="{{event.smImgUrl}}" alt="image" onerror="onErrChangeSrc(this)" style="width: 750px;height: 230px;object-fit: cover;" />
                                    </a>
                                    <span class="date">{{fixDate(event.eventDate)}}</span>
                                </div>
                                <div class="content">
                                    <h3>
                                        <a routerLink="/news-and-event-details/{{event.id}}/{{event.navUrl}}">{{event.title}}</a>
                                    </h3>
                                    <p class="cs-tiny text-uppercase">
                                        <span *ngIf="event.typeName == 'EVENT' ">{{event.eventCategory}} {{event.typeName}}</span>
                                        <span *ngIf="event.typeName == 'NEWS' ">{{event.typeName}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="shorting">
                    <div class="row">
                        <div *ngFor="let event of eventList;"
                            class="col-lg-4 col-md-6 col-sm-6 px-1 neContain neCat-{{event.status}}">
                            <div
                                class="single-events-box cs-border cs-border-light-gray cs-round-medium cs-animate-zoom">
                                <div class="image">
                                    <a routerLink="/news-and-events-details/{{event.id}}/{{event.url}}" class="d-block">
                                        <img *ngIf="event.imageUrl != '' && event.videoUrl == '' "
                                            src="http://st.dizier-admin.cagliero.eu/app/assets/img/uploads/ps-news-events/{{event.imageUrl}}"
                                            alt="image" onerror="onErrChangeSrc(this)"
                                            style="width: 750px;height: 230px;object-fit: cover;" />


                                        <img *ngIf="event.imageUrl == '' && event.videoUrl != ''"
                                            src="{{event.videoThumbnail}}" alt="image" onerror="onErrChangeSrc(this)"
                                            style="width: 750px;height: 230px;object-fit: cover;" />


                                    </a>
                                    <span *ngIf="event.type == 'event'"
                                        class="date cu-bg-clr-3 cs-text-white cs-border">{{fixDate(event.dateTime)}}</span>
                                    <span *ngIf="event.type == 'news'"
                                        class="date cu-bg-clr-3 cs-text-white cs-border">{{fixDate(event.createdAt)}}</span>
                                </div>
                                <div class="content">
                                    <h3>
                                        <a
                                            routerLink="/news-and-events-details/{{event.id}}/{{event.url}}">{{event.title}}</a>
                                    </h3>
                                    <p class="cs-tiny text-uppercase">
                                        <span *ngIf="event.type == 'event' ">{{event.status}}
                                            {{event.type}}</span>
                                        <span *ngIf="event.type == 'news' ">{{event.status}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="noData">
                <app-data-not-found></app-data-not-found>
            </div>
        </div>
    </div>
</section>