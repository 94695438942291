<div class="page-title-area">
    <div class="container cs-display-container" style="height: 200px">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Accueil</a></li>
                <li>Contactez-nous</li>
            </ul>
            <h2>Contactez-nous</h2>
        </div>
        <img src="assets/img/illustrations/contact_us.svg" alt="" style="height: 180px; width: auto"
            class="cs-display-bottomright pt-10 cs-hide-small" />
        <!-- <img src="assets/img/related/DBIMA_Butterfly.png" alt="DBIMA Butterfly Icon" style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10"> -->
    </div>

    <div class="shape9">
        <img src="assets/img/shape8.svg" alt="Lime color Plus Sign Icon" />
    </div>
</div>

<section class="contact-area ptb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">COORDONNÉES</span>
                    <h2>Contactez-nous</h2>
                    <p>Le collège Salésien Don Bosco de Saint-Dizier, accueille des élèves à besoins
                        éducatifs de différentes régions ce qui est possible grâce à un internat.
                    </p>

                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Notre adresse</h3>
                            <p>1bis, rue du Maréchal de Lattre de Tassigny 52100 Saint-Dizier</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contact</h3>
                            <p>Téléphone: <a href="tel:+44457895789">03.25.56.51.73</a></p>
                            <p>Fax: <a>03.25.56.45.46</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Horaires d’ouverture et de fermeture</h3>
                            <p>Du lundi au vendredi - De 07h45 à 18h00</p>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <div id="contact_sec_1">
                        <h2>Ecrivez-nous</h2>
                        <p>Votre adresse électronique ne sera pas publiée. Les champs obligatoires sont marqués d'un *
                        </p>

                        <form id="contactForm">
                            <div class="row">
                                <input type="text" name="postContactMessage" id="" value="true" class="cs-hide">

                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="cm_name" required
                                            placeholder="Vos Nom et Prénom">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="cm_email" required
                                            placeholder="Votre adresse email">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="phone-number" id="cm_phone_number" pattern="[0-9]+"
                                            minlength="5" maxlength="20"
                                            title="Contact Number Should Only Contain numbers !" required
                                            placeholder="Votre numéro de téléphone">
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" id="cm_message" cols="30" rows="5" required
                                            minlength="10" placeholder="Ecrivez votre message..."></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <!-- <button type="submit" class="default-btn" id="postContactMessageBtn">
                                        Send Message<span></span>
                                    </button> -->
                                    <button type="button" class="default-btn" id="postContactMessageBtn"
                                        (click)="callToPostContactMessageFun()">Envoyer à Message<span></span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div id="contact_sec_2" class="cs-hide">
                        <div class="row cu-center">
                            <img src="assets/img/illustrations/message_sent.svg" alt=""
                                style="height: 190px;width: auto;">
                            <br>
                            <h2>Votre message a été envoyé</h2>
                            <p>Nous vous remercions de nous avoir contactés</p>
                            <p>Nous reviendrons vers vous dès que possible</p>
                            <hr>
                            <button type="button" class="default-btn" style="padding-left: 25px;"
                                onclick="navDiv('contact_sec', 1, 2);">Envoyer un nouveau message<span></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="map">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d659.1225859540345!2d4.948710129288617!3d48.638734084912315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47eb80ee98715555%3A0xd9379a85c88d1613!2sColl%C3%A8ge%20Don%20Bosco%20Saint-Dizier!5e0!3m2!1sen!2slk!4v1655823516043!5m2!1sen!2slk"></iframe>
</div>