import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DomSanitizer } from '@angular/platform-browser';

import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NavbarComponent } from './components/common/navbar/navbar.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { PremiumAccessComponent } from './components/common/premium-access/premium-access.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';

import { ErrorComponent } from './components/pages/error/error.component';

import { ElearningSchoolComponent } from './components/pages/template/elearning-school/elearning-school.component';
import { VendorCertificationEtrainingComponent } from './components/pages/template/vendor-certification-etraining/vendor-certification-etraining.component';
import { OnlineTrainingSchoolComponent } from './components/pages/template/online-training-school/online-training-school.component';
import { DistanceLearningComponent } from './components/pages/template/distance-learning/distance-learning.component';
import { LanguageSchoolComponent } from './components/pages/template/language-school/language-school.component';
import { ModernSchoolingComponent } from './components/pages/template/modern-schooling/modern-schooling.component';
import { YogaTrainingComponent } from './components/pages/template/yoga-training/yoga-training.component';
import { HealthCoachingComponent } from './components/pages/template/health-coaching/health-coaching.component';
import { KindergartenComponent } from './components/pages/template/kindergarten/kindergarten.component';
import { ContactUsComponent } from './components/pages/template/contact-us/contact-us.component';
// import { GalleryComponent } from './components/pages/template/gallery/gallery.component';
import { AboutStyleOneComponent } from './components/pages/template/about-style-one/about-style-one.component';
import { AboutStyleTwoComponent } from './components/pages/template/about-style-two/about-style-two.component';
import { AboutStyleThreeComponent } from './components/pages/template/about-style-three/about-style-three.component';
import { AboutStyleFourComponent } from './components/pages/template/about-style-four/about-style-four.component';
import { SuccessStoryComponent } from './components/pages/template/success-story/success-story.component';
import { TeacherComponent } from './components/pages/template/teacher/teacher.component';
import { ProfileAuthenticationComponent } from './components/pages/template/profile-authentication/profile-authentication.component';
// import { ErrorComponent } from './components/pages/template/error/error.component';
import { ComingSoonComponent } from './components/pages/template/coming-soon/coming-soon.component';
import { PurchaseGuideComponent } from './components/pages/template/purchase-guide/purchase-guide.component';
import { PrivacyPolicyComponent } from './components/pages/template/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './components/pages/template/terms-of-service/terms-of-service.component';
import { FaqComponent } from './components/pages/template/faq/faq.component';
import { CoursesStyleOneComponent } from './components/pages/template/courses-style-one/courses-style-one.component';
import { CoursesStyleTwoComponent } from './components/pages/template/courses-style-two/courses-style-two.component';
import { CoursesStyleThreeComponent } from './components/pages/template/courses-style-three/courses-style-three.component';
import { CoursesStyleFourComponent } from './components/pages/template/courses-style-four/courses-style-four.component';
import { CoursesStyleFiveComponent } from './components/pages/template/courses-style-five/courses-style-five.component';
import { CoursesStyleSixComponent } from './components/pages/template/courses-style-six/courses-style-six.component';
import { CoursesStyleSevenComponent } from './components/pages/template/courses-style-seven/courses-style-seven.component';
import { CoursesDetailsStyleOneComponent } from './components/pages/template/courses-details-style-one/courses-details-style-one.component';
import { CoursesDetailsStyleTwoComponent } from './components/pages/template/courses-details-style-two/courses-details-style-two.component';
import { ProfileComponent } from './components/pages/template/profile/profile.component';
import { ProfileQuizComponent } from './components/pages/template/profile-quiz/profile-quiz.component';
import { MembershipLevelsComponent } from './components/pages/template/membership-levels/membership-levels.component';
import { BecomeATeacherComponent } from './components/pages/template/become-a-teacher/become-a-teacher.component';
import { CategoriesComponent } from './components/pages/template/categories/categories.component';
// import { EventsComponent } from './components/pages/template/events/events.component';
import { EventsDetailsComponent } from './components/pages/template/events-details/events-details.component';
import { ProductsListStyleOneComponent } from './components/pages/template/products-list-style-one/products-list-style-one.component';
import { ProductsListStyleTwoComponent } from './components/pages/template/products-list-style-two/products-list-style-two.component';
import { CartComponent } from './components/pages/template/cart/cart.component';
import { CheckoutComponent } from './components/pages/template/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/template/products-details/products-details.component';
import { BlogStyleOneComponent } from './components/pages/template/blog-style-one/blog-style-one.component';
import { BlogStyleTwoComponent } from './components/pages/template/blog-style-two/blog-style-two.component';
import { BlogStyleThreeComponent } from './components/pages/template/blog-style-three/blog-style-three.component';
import { BlogStyleFourComponent } from './components/pages/template/blog-style-four/blog-style-four.component';
import { BlogStyleFiveComponent } from './components/pages/template/blog-style-five/blog-style-five.component';
import { BlogDetailsStyleOneComponent } from './components/pages/template/blog-details-style-one/blog-details-style-one.component';
import { BlogDetailsStyleTwoComponent } from './components/pages/template/blog-details-style-two/blog-details-style-two.component';
import { BlogDetailsStyleThreeComponent } from './components/pages/template/blog-details-style-three/blog-details-style-three.component';

import { HomeComponent } from './components/pages/home/home.component';
import { HomeBannerComponent } from './components/pages/home/home-banner/home-banner.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { PedagogyComponent } from './components/pages/pedagogy/pedagogy.component';
import { TimetableComponent } from './components/pages/timetable/timetable.component';
import { OpeningComponent } from './components/pages/opening/opening.component';
import { HistoryComponent } from './components/pages/history/history.component';
import { RegistrationComponent } from './components/pages/registration/registration.component';

import { NewsAndEventsComponent } from './components/pages/news-and-events/news-and-events.component';
import { RecentNewsNEventsComponent } from './components/common/recent-news-n-events/recent-news-n-events.component';
import { NewsAndEventDetailsComponent } from './components/pages/news-and-events/news-and-event-details/news-and-event-details.component';

import { TemporaryComponent } from './components/pages/temporary/temporary.component';
import { TemporaryFooterComponent } from './components/common/temporary-footer/temporary-footer.component';
import { LoaderComponent } from './components/common/other/loader/loader.component';
import { TemporaryNavbarComponent } from './components/common/temporary-navbar/temporary-navbar.component';
import { DataNotFoundComponent } from './components/common/other/data-not-found/data-not-found.component';
import { InternatComponent } from './components/pages/internat/internat.component';
import { TermsAndConditionsComponent } from './components/pages/company/terms-and-conditions/terms-and-conditions.component';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }
    transform(url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}

@NgModule({
    declarations: [
        SafePipe,
        AppComponent,
        NavbarComponent,
        FooterComponent,
        ElearningSchoolComponent,
        PartnerComponent,
        VendorCertificationEtrainingComponent,
        PremiumAccessComponent,
        SubscribeComponent,
        OnlineTrainingSchoolComponent,
        DistanceLearningComponent,
        LanguageSchoolComponent,
        ModernSchoolingComponent,
        YogaTrainingComponent,
        HealthCoachingComponent,
        KindergartenComponent,
        ContactUsComponent,
        AboutStyleOneComponent,
        AboutStyleTwoComponent,
        AboutStyleThreeComponent,
        AboutStyleFourComponent,
        SuccessStoryComponent,
        TeacherComponent,
        ProfileAuthenticationComponent,
        ComingSoonComponent,
        PurchaseGuideComponent,
        PrivacyPolicyComponent,
        TermsOfServiceComponent,
        FaqComponent,
        CoursesStyleOneComponent,
        CoursesStyleTwoComponent,
        CoursesStyleThreeComponent,
        CoursesStyleFourComponent,
        CoursesStyleFiveComponent,
        CoursesStyleSixComponent,
        CoursesStyleSevenComponent,
        CoursesDetailsStyleOneComponent,
        CoursesDetailsStyleTwoComponent,
        ProfileComponent,
        ProfileQuizComponent,
        MembershipLevelsComponent,
        BecomeATeacherComponent,
        CategoriesComponent,
        EventsDetailsComponent,
        ProductsListStyleOneComponent,
        ProductsListStyleTwoComponent,
        CartComponent,
        CheckoutComponent,
        ProductsDetailsComponent,
        BlogStyleOneComponent,
        BlogStyleTwoComponent,
        BlogStyleThreeComponent,
        BlogStyleFourComponent,
        BlogStyleFiveComponent,
        BlogDetailsStyleOneComponent,
        BlogDetailsStyleTwoComponent,
        BlogDetailsStyleThreeComponent,

        HomeComponent,
        HomeBannerComponent,
        GalleryComponent,
        PedagogyComponent,
        TimetableComponent,
        OpeningComponent,
        HistoryComponent,
        RegistrationComponent,

        NewsAndEventsComponent,
        RecentNewsNEventsComponent,
        NewsAndEventDetailsComponent,

        ErrorComponent,
        TemporaryComponent,
        TemporaryFooterComponent,
        LoaderComponent,
        TemporaryNavbarComponent,
        DataNotFoundComponent,
        InternatComponent,
        TermsAndConditionsComponent,

    ],
    imports: [MatSnackBarModule, BrowserModule, AppRoutingModule, HttpClientModule, BrowserAnimationsModule],
    exports: [MatSnackBarModule, HttpClientModule],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule { }
