import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  Nav_scroll(para) {
    document.getElementById(para).scrollIntoView({ behavior: "smooth" });
  }
}
