import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  Nav_scroll(para) {
    this.router.navigate(['']);
    setTimeout(() => {                           // <<<---using ()=> syntax
      document.getElementById(para).scrollIntoView({ behavior: "smooth" });
    }, 1000);

  }
}
