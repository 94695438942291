<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Success Story</li>
            </ul>
            <h2>Success Story</h2>
        </div>
    </div>

    <div class="shape9"><img src="../assets/img/shape8.svg" alt="image"></div>
</div>

<section class="success-story-area pb-100">
    <div class="container">
        <div class="video-box mt-0">
            <div class="image">
                <img src="../assets/img/success-story.jpg" class="shadow" alt="image">
            </div>
            <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
            <div class="shape10" data-speed="0.06" data-revert="true"><img src="../assets/img/shape9.png" alt="image"></div>
        </div>
    </div>

    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/shape8.svg" alt="image"></div>
</section>

<section class="testimonials-area ptb-100 bg-fef8ef">
    <div class="container">
        <div class="single-testimonials-box">
            <img src="../assets/img/user1.jpg" class="client-img" alt="image">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
            <h3>John Smith</h3>
            <span>Python Developer</span>
            <div class="shape-img">
                <img src="../assets/img/shape4.png" class="shape-1" alt="image">
                <img src="../assets/img/shape14.png" class="shape-2" alt="image">
                <img src="../assets/img/shape7.png" class="shape-3" alt="image">
            </div>
        </div>
    </div>
</section>

<section class="our-story-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">1</span> Inspirational stories are less about success</h3>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p><a routerLink="/">Odemy.com</a> began in 2005. After years in the web hosting industry, we realized that it was near impossible for the average Jane or Joe to create their own website. Traditional web hosting services were simply too
                        complicated, time consuming, and expensive to manage.</p>
                    <p>After seeing an increased need for eCommerce solutions, we developed one of the only fully-featured, free and commission-free online store builders, allowing business owners to launch their online business.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">2</span> Academic Excellence and Cultural Diversity</h3>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>We created the <a routerLink="/">Odemy.com</a> Site Builder with the user's perspective in mind. We wanted to offer a platform that would require no coding skills or design experience. We keep it simple, so users can focus on creating
                        an amazing website that reflects their brand. Best of all - it's free. You can get online, showcase your brand, or start selling products right away.</p>
                    <p>After seeing an increased need for eCommerce solutions, we developed one of the only fully-featured, free and commission-free online store builders, allowing business owners to launch their online business.</p>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="our-story-image">
                    <img src="../assets/img/story-img.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">3</span> A Classical Education for the Future</h3>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p><a routerLink="/">Odemy.com</a> began in 2005. After years in the web hosting industry, we realized that it was near impossible for the average Jane or Joe to create their own website. Traditional web hosting services were simply too
                        complicated, time consuming, and expensive to manage.</p>
                    <p>After seeing an increased need for eCommerce solutions, we developed one of the only fully-featured, free and commission-free online store builders, allowing business owners to launch their online business.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="our-story-title">
                    <h3><span class="number">4</span> A success-oriented learning environment</h3>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="our-story-content">
                    <p>We created the <a routerLink="/">Odemy.com</a> Site Builder with the user's perspective in mind. We wanted to offer a platform that would require no coding skills or design experience. We keep it simple, so users can focus on creating
                        an amazing website that reflects their brand. Best of all - it's free. You can get online, showcase your brand, or start selling products right away.</p>
                    <p>After seeing an increased need for eCommerce solutions, we developed one of the only fully-featured, free and commission-free online store builders, allowing business owners to launch their online business.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="start-with-success-area pt-100 bg-f8f9f8 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Education For Everyone</span>
            <h2>Start writing your own success story with a good beginning from Odemy help</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="start-with-success-box">
                    <div class="image">
                        <a routerLink="/success-story">
                            <img src="../assets/img/success-people/img1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <a routerLink="/success-story" class="link-btn"><i class="flaticon-right"></i></a>
                        <h3>Sarah Taylor</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="start-with-success-box">
                    <div class="image">
                        <a routerLink="/success-story">
                            <img src="../assets/img/success-people/img2.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <a routerLink="/success-story" class="link-btn"><i class="flaticon-right"></i></a>
                        <h3>Alex Maxwell</h3>
                        <span>Tutor</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="start-with-success-box">
                    <div class="image">
                        <a routerLink="/success-story">
                            <img src="../assets/img/success-people/img3.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <a routerLink="/success-story" class="link-btn"><i class="flaticon-right"></i></a>
                        <h3>Eva Lucy</h3>
                        <span>UX/UI Designer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="start-with-success-box">
                    <div class="image">
                        <a routerLink="/success-story">
                            <img src="../assets/img/success-people/img4.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <a routerLink="/success-story" class="link-btn"><i class="flaticon-right"></i></a>
                        <h3>Alina Smith</h3>
                        <span>Author</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="start-with-success-box">
                    <div class="image">
                        <a routerLink="/success-story">
                            <img src="../assets/img/success-people/img5.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <a routerLink="/success-story" class="link-btn"><i class="flaticon-right"></i></a>
                        <h3>James Anderson</h3>
                        <span>Designer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="start-with-success-box">
                    <div class="image">
                        <a routerLink="/success-story">
                            <img src="../assets/img/success-people/img6.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <a routerLink="/success-story" class="link-btn"><i class="flaticon-right"></i></a>
                        <h3>Meg Lanning</h3>
                        <span>Writer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>