<div class="health-coaching-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-banner-image">
                    <img src="../assets/img/site/MD9.jpg" alt="image" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-banner-content">
                    <h1 class="playfair-display-font">
                        L’éducation est une affaire de cœur.Don Bosco
                    </h1>
                    <!-- <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                    </p> -->
                </div>
            </div>
        </div>
    </div>

    <div class="health-coaching-shape1" data-speed="0.06" data-revert="true">
        <img src="../assets/img/bookStack.png" class="bannerImgOne" alt="image" />
    </div>
    <div class="health-coaching-shape2" data-speed="0.06" data-revert="true">
        <img src="../assets/img/kids.svg" alt="image" />
    </div>
    <div class="health-coaching-shape3" data-speed="0.06" data-revert="true">
        <img src="../assets/img/ww.png" class="bannerImgThree" alt="image" />
    </div>
    <div class="health-coaching-shape4" data-speed="0.06" data-revert="true">
        <img src="../assets/img/health-coaching-shape4.png" alt="image" />
    </div>
    <div class="health-coaching-shape5" data-speed="0.06" data-revert="true">
        <img src="../assets/img/health-coaching-shape5.png" alt="image" />
    </div>
    <div class="health-coaching-shape6" data-speed="0.06" data-revert="true">
        <img src="../assets/img/health-coaching-shape6.png" alt="image" />
    </div>
    <div class="health-coaching-shape7">
        <img src="../assets/img/health-coaching-shape7.png" alt="image" />
    </div>
    <div class="divider"></div>
</div>

<section class="lifestyle-area bg-f6f7fb pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="playfair-display-font">Système éducatif</h2>
            <p>
                Afin de renforcer les dispositifs pédagogiques autour de la
                mixité sociale et de l’école inclusive (respect des fragilités
                de chacun), les principales particularités du collège Don Bosco
                Saint-Dizier sont :
            </p>
        </div>

        <div class="row align-items-center justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="about-content">
                    <ul class="features-list">
                        <li>
                            <span><i class="flaticon-self-growth"></i>Un effectif
                                plus réduit par classe</span>
                        </li>
                        <li>
                            <span><i class="flaticon-self-growth"></i> Des
                                évaluations par compétences</span>
                        </li>
                        <li>
                            <span><i class="flaticon-self-growth"></i> Des
                                classes flexibles</span>
                        </li>
                        <li>
                            <span><i class="flaticon-self-growth"></i>L’accompagnement avec des temps d’échanges
                            </span>
                        </li>
                        <li>
                            <span><i class="flaticon-self-growth"></i> La mise en
                                place d’ateliers de méthodologie adaptée</span>
                        </li>
                        <li>
                            <span><i class="flaticon-self-growth"></i> Le
                                développement des apports socio-culturels dans
                                le cadre de projets spécifiques.
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="text-center ft3">
            <p>
                Cet accueil inconditionnel favorise le regard de confiance sur
                le potentiel de chaque jeune et sur sa réussite dans son
                parcours et sa formation. Aussi notre appartenance au réseau
                salésien nous permet de vivre une dimension internationale qui
                nous invite à développer nos liens avec d’autres établissements
                dans le monde.
            </p>
        </div>
    </div>
</section>

<!-- <section class="program-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="program-section-title">
                    <h2 class="playfair-display-font">Method Title 01</h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Dolor repellendus magnam, molestiae ratione placeat quae
                        saepe repudiandae reprehenderit itaque dolorem est, non,
                        dignissimos eligendi corrupti aperiam? Debitis ex libero
                        ut!Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Dolor repellendus magnam, molestiae ratione
                        placeat quae saepe repudiandae reprehenderit itaque
                        dolorem est, non, dignissimos eligendi corrupti aperiam?
                        Debitis ex libero ut!
                    </p>
                    <a routerLink="/profile-authentication" class="default-btn"><i
                            class="flaticon-user"></i>S'inscrire<span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="program-list">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-program-box">
                                <div class="shape">
                                    <img src="../assets/img/list-shape1.png" alt="image" />
                                </div>
                                <div class="icon">
                                    <i class="flaticon-artist"></i>
                                </div>
                                <h3 class="playfair-display-font">
                                    <a routerLink="/about-1">Method 01</a>
                                </h3>
                                <p>
                                    Lorem ipsum dolor sit amet ut, adipiscing
                                    elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua.
                                </p>
                            </div>

                            <div class="single-program-box">
                                <div class="shape">
                                    <img src="../assets/img/list-shape2.png" alt="image" />
                                </div>
                                <div class="icon">
                                    <i class="flaticon-certification-1"></i>
                                </div>
                                <h3 class="playfair-display-font">
                                    <a routerLink="/about-1">Method 02</a>
                                </h3>
                                <p>
                                    Lorem ipsum dolor sit amet ut, adipiscing
                                    elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua.
                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-program-box">
                                <div class="shape">
                                    <img src="../assets/img/list-shape3.png" alt="image" />
                                </div>
                                <div class="icon">
                                    <i class="flaticon-agenda"></i>
                                </div>
                                <h3 class="playfair-display-font">
                                    <a routerLink="/about-1">Method 03</a>
                                </h3>
                                <p>
                                    Lorem ipsum dolor sit amet ut, adipiscing
                                    elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="program-circle-shape">
                        <img src="../assets/img/circle-shape.png" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <section class="health-services-area bg-fcf7f3 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Do not wait for Tomorrow!</span>
            <h2 class="playfair-display-font">
                You’ve tried dieting a million times. Now try something
                different.
            </h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga-2"></i>
                    </div>
                    <h3 class="playfair-display-font">
                        <a routerLink="/courses-1">Daily Excersize</a>
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, conectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga"></i>
                    </div>
                    <h3 class="playfair-display-font">
                        <a routerLink="/courses-1">Find Your Balance</a>
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, conectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-lotus"></i>
                    </div>
                    <h3 class="playfair-display-font">
                        <a routerLink="/courses-1">Personal Program</a>
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, conectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-tomato"></i>
                    </div>
                    <h3 class="playfair-display-font">
                        <a routerLink="/courses-1">Natural Process</a>
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, conectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga-1"></i>
                    </div>
                    <h3 class="playfair-display-font">
                        <a routerLink="/courses-1">Immune System</a>
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, conectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse.
                    </p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-strawberry"></i>
                    </div>
                    <h3 class="playfair-display-font">
                        <a routerLink="/courses-1">Gives You Energy</a>
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet, conectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Quis ipsum suspendisse.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="experience-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="experience-content">
                    <div class="shape">
                        <img src="../assets/img/fruits.png" alt="image" />
                    </div>
                    <span class="sub-title"
                        >Join our World Famous Yoga Teacher Training Course...
                        Online!</span
                    >
                    <h2 class="playfair-display-font">
                        This Isn't a Diet, It's a Lifestyle
                    </h2>
                    <p>
                        Odemy training programs can bring you a super exciting
                        experience of learning through online! You never face
                        any negative experience while enjoying your classes
                        virtually by sitting in your comfort zone. Our flexible
                        learning initiatives will help you to learn better and
                        quicker than the traditional ways of learning skills.
                    </p>
                    <ul class="features-list">
                        <li><i class="bx bx-check"></i> Vegetable Intake</li>
                        <li><i class="bx bx-check"></i> An Apple a Day</li>
                        <li><i class="bx bx-check"></i> Good Nutrition</li>
                        <li><i class="bx bx-check"></i> Our Principles</li>
                        <li><i class="bx bx-check"></i> Healthy Life</li>
                        <li><i class="bx bx-check"></i> Personalized Plan</li>
                    </ul>
                    <a routerLink="/profile-authentication" class="default-btn"
                        ><i class="flaticon-user"></i>Get Started Now<span
                        ></span
                    ></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="experience-img">
                    <img src="../assets/img/experience-img2.jpg" alt="image" />
                    <span class="title"
                        ><span>15</span> Years of Experience</span
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="experience-shape1" data-speed="0.06" data-revert="true">
        <img src="../assets/img/experience-shape1.png" alt="image" />
    </div>
    <div class="experience-shape2" data-speed="0.06" data-revert="true">
        <img src="../assets/img/experience-shape2.png" alt="image" />
    </div>
</section>

<section class="courses-area bg-image ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Learn At Your Own Pace</span>
            <h2 class="playfair-display-font">Top Selling Courses</h2>
            <p>
                Explore all of our courses and pick your suitable ones to enroll
                and start learning with us! We ensure that you will never regret
                it!
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img
                                src="../assets/img/courses/health-img1.jpg"
                                alt="image"
                            />
                        </a>
                        <a routerLink="/" class="fav"
                            ><i class="flaticon-heart"></i
                        ></a>
                        <div class="price bg-45a393 shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img
                                src="../assets/img/user1.jpg"
                                class="rounded-circle"
                                alt="image"
                            />
                            <span>Alex Morgan</span>
                        </div>
                        <h3 class="playfair-display-font">
                            <a routerLink="/single-courses-1"
                                >Diet And Nutrition Coach Certification</a
                            >
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img
                                src="../assets/img/courses/health-img2.jpg"
                                alt="image"
                            />
                        </a>
                        <a routerLink="/" class="fav"
                            ><i class="flaticon-heart"></i
                        ></a>
                        <div class="price bg-45a393 shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img
                                src="../assets/img/user2.jpg"
                                class="rounded-circle"
                                alt="image"
                            />
                            <span>Sarah Taylor</span>
                        </div>
                        <h3 class="playfair-display-font">
                            <a routerLink="/single-courses-1"
                                >Internationally Accredited Diploma
                                Certificate</a
                            >
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img
                                src="../assets/img/courses/health-img3.jpg"
                                alt="image"
                            />
                        </a>
                        <a routerLink="/" class="fav"
                            ><i class="flaticon-heart"></i
                        ></a>
                        <div class="price bg-45a393 shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img
                                src="../assets/img/user3.jpg"
                                class="rounded-circle"
                                alt="image"
                            />
                            <span>David Warner</span>
                        </div>
                        <h3 class="playfair-display-font">
                            <a routerLink="/single-courses-1"
                                >Nutrition Certification Diet & Meal Panning</a
                            >
                        </h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <p>
                        Enjoy the top notch learning methods and achieve next
                        level skills! You are the creator of your own career &
                        we will guide you through that.
                        <a routerLink="/profile-authentication"
                            >Register Free Now!</a
                        >.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="health-coaching-feedback-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-feedback-image">
                    <img src="../assets/img/woman.jpg" alt="image" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-inner">
                    <h2 class="playfair-display-font">
                        More Than 200,000 People Agree That We are Different
                    </h2>
                    <div class="feedback-quote">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Quis ipsum suspendisse ultrices
                            gravida. Risus commodo viverra maecenas accumsan
                            lacus vel facilisis.
                        </p>
                        <div class="client-info">
                            <div
                                class="d-flex justify-content-center align-items-center"
                            >
                                <img
                                    src="../assets/img/user1.jpg"
                                    alt="image"
                                />
                                <div class="title">
                                    <h3>John Smith</h3>
                                    <span>Python Developer</span>
                                </div>
                            </div>
                        </div>
                        <div class="object1">
                            <img src="../assets/img/object1.png" alt="image" />
                        </div>
                        <div class="object2">
                            <img src="../assets/img/object2.png" alt="image" />
                        </div>
                        <div class="object3">
                            <img src="../assets/img/object3.png" alt="image" />
                        </div>
                        <div class="object4">
                            <img src="../assets/img/object4.png" alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="events-area bg-image bg-eee8df pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Events</span>
            <h2 class="playfair-display-font">Our Upcoming Events</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-events-box-item">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img
                                src="../assets/img/events/health-img1.jpg"
                                alt="image"
                            />
                        </a>
                        <span class="date">Wed, 20 May, 2020</span>
                    </div>

                    <div class="content">
                        <h3 class="playfair-display-font">
                            <a routerLink="/single-events"
                                >Global Conference on Business Management</a
                            >
                        </h3>
                        <span class="location"
                            ><i class="bx bx-map"></i>Vancover, Canada</span
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-events-box-item">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img
                                src="../assets/img/events/health-img2.jpg"
                                alt="image"
                            />
                        </a>
                        <span class="date">Tue, 19 May, 2020</span>
                    </div>

                    <div class="content">
                        <h3 class="playfair-display-font">
                            <a routerLink="/single-events"
                                >International Conference on Teacher
                                Education</a
                            >
                        </h3>
                        <span class="location"
                            ><i class="bx bx-map"></i>Sydney, Australia</span
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-events-box-item">
                    <div class="image">
                        <a routerLink="/single-events" class="d-block">
                            <img
                                src="../assets/img/events/health-img3.jpg"
                                alt="image"
                            />
                        </a>
                        <span class="date">Mon, 18 May, 2020</span>
                    </div>

                    <div class="content">
                        <h3 class="playfair-display-font">
                            <a routerLink="/single-events"
                                >International Conference on Special Needs
                                Education</a
                            >
                        </h3>
                        <span class="location"
                            ><i class="bx bx-map"></i>Istanbul, Turkey</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our New</span>
            <h2 class="playfair-display-font">Check Out Our Latest Blog</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img
                                src="../assets/img/health-blog-img1.jpg"
                                alt="image"
                            />
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Yoga</a>
                        <h3 class="playfair-display-font">
                            <a routerLink="/single-blog-1"
                                >Surprising SUP Yoga Poses You’ll Want to Try
                                This Summer</a
                            >
                        </h3>
                        <ul
                            class="post-content-footer d-flex justify-content-between align-items-center"
                        >
                            <li>
                                <div
                                    class="post-author d-flex align-items-center"
                                >
                                    <img
                                        src="../assets/img/user1.jpg"
                                        class="rounded-circle"
                                        alt="image"
                                    />
                                    <span>Alex Morgan</span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-calendar"></i> April 30, 2020
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img
                                src="../assets/img/health-blog-img2.jpg"
                                alt="image"
                            />
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">YTT</a>
                        <h3 class="playfair-display-font">
                            <a routerLink="/single-blog-1"
                                >7 Things I Learned From Doing One of Those
                                Social Media Yoga</a
                            >
                        </h3>
                        <ul
                            class="post-content-footer d-flex justify-content-between align-items-center"
                        >
                            <li>
                                <div
                                    class="post-author d-flex align-items-center"
                                >
                                    <img
                                        src="../assets/img/user2.jpg"
                                        class="rounded-circle"
                                        alt="image"
                                    />
                                    <span>Sarah Taylor</span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-calendar"></i> April 29, 2020
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post-box">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img
                                src="../assets/img/health-blog-img3.jpg"
                                alt="image"
                            />
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-1" class="category">Yoga</a>
                        <h3 class="playfair-display-font">
                            <a routerLink="/single-blog-1"
                                >10 Ways to Get Real About Your Body’s
                                Limitations & Avoid</a
                            >
                        </h3>
                        <ul
                            class="post-content-footer d-flex justify-content-between align-items-center"
                        >
                            <li>
                                <div
                                    class="post-author d-flex align-items-center"
                                >
                                    <img
                                        src="../assets/img/user3.jpg"
                                        class="rounded-circle"
                                        alt="image"
                                    />
                                    <span>David Warner</span>
                                </div>
                            </li>
                            <li>
                                <i class="flaticon-calendar"></i> April 28, 2020
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-post-info">
                    <p>
                        Get into details now?​
                        <a routerLink="/blog-1">View all posts</a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="blog-shape1">
        <img src="../assets/img/blog-shape1.png" alt="image" />
    </div>
    <div class="blog-shape2">
        <img src="../assets/img/blog-shape2.png" alt="image" />
    </div>
</section>

<section class="subscribe-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-image">
                    <img src="../assets/img/subscribe-img2.png" alt="image" />
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="subscribe-content">
                    <span class="sub-title">Go At Your Own Pace</span>
                    <h2 class="playfair-display-font">
                        Subscribe To Our Newsletter
                    </h2>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua.
                    </p>
                    <form class="newsletter-form">
                        <input
                            type="text"
                            class="input-newsletter"
                            placeholder="Enter your email address"
                            name="EMAIL"
                            required
                            autocomplete="off"
                        />
                        <button type="submit" class="default-btn">
                            <i class="flaticon-user"></i>Subscribe Now<span
                            ></span>
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="shape4" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape4.png" alt="image" />
    </div>
    <div class="shape13" data-speed="0.06" data-revert="true">
        <img src="../assets/img/shape12.png" alt="image" />
    </div>
</section> -->