<div *ngIf="loading">
    <app-loader></app-loader>
</div>
<div [ngClass]="{'cs-hide' : loading}">
    <div [ngClass]="{'cs-hide' : noData}">
        <div class="page-title-area">
            <div class="container cs-display-container" style="height: 200px;">
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Accueil</a></li>
                        <li><a routerLink="/news-and-events">News & Events</a></li>
                        <li>{{newsEventData.title}}</li>
                    </ul>
                    <h2>{{newsEventData.title}}</h2>

                </div>
                <img src="assets/img/illustrations/online_articles.svg" alt="" style="height: 180px;width: auto;"
                    class="cs-display-bottomright pt-10 cs-hide-small">
            </div>

            <div class="shape9"><img src="assets/img/shape8.svg" alt="Lime color Plus Sign Icon"></div>
        </div>
        <section class="events-details-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 col-md-12 ml-auto mr-auto">

                        <div class="cs-padding">
                            <div class="row">
                                <img [ngClass]="{'cs-hide' : newsEventData.imageUrl == '' && newsEventData.videoUrl != ''}"
                                    class=" mr-auto ml-auto cs-round-large" style="width: 100%;height: auto;"
                                    src="http://st.dizier-admin.cagliero.eu/app/assets/img/uploads/ps-news-events/{{newsEventData.imageUrl}}"
                                    onerror="onErrChangeSrc(this)" alt="image">
                            </div>

                            <div [ngClass]="{'cs-hide' : newsEventData.imageUrl != '' && newsEventData.videoUrl == ''}"
                                class="row align-items-center">



                                <div [ngClass]="{'cs-hide' : newsEventData.videoSource != 'youtube'}"
                                    class="col-lg-12 col-md-12 justify-content-center">
                                    <div class="about-video-box">
                                        <div class="image">
                                            <img src="{{newsEventData.videoThumbnail}}" alt="Video Thumbnail"
                                                class="newsVideo">
                                        </div>
                                        <a href="{{newsEventData.trueVideoUrl}}" class="video-btn popup-youtube"><i
                                                class="flaticon-play"></i></a>
                                    </div>
                                </div>

                                <div [ngClass]="{'cs-hide' : newsEventData.videoSource != 'drive'}"
                                    class="col-lg-12 col-md-12 justify-content-center">
                                    <div class="about-video-box">
                                        <div class="image">
                                            <img src="{{newsEventData.videoThumbnail}}" alt="Video Thumbnail"
                                                class="newsVideo">
                                        </div>
                                        <a href="{{newsEventData.videoUrl}}" class="video-btn popup-youtube"><i
                                                class="flaticon-play"></i></a>
                                    </div>
                                </div>


                            </div>

                        </div>



                        <div class="events-details-header cs-padding mb-0 mt-5">
                            <ul *ngIf="newsEventData.type == 'event'" style="width: fit-content;"
                                class="cs-border cs-round-large cs-white">
                                <li><i class="bx bx-calendar"></i>{{fixDate(newsEventData.dateTime)}}</li>
                                <li><i class="bx bx-map"></i>{{newsEventData.location}}</li>
                                <li><i class="bx bx-time"></i>{{setTime(newsEventData.dateTime)}}</li>
                            </ul>
                        </div>

                        <div class="cs-padding-64">
                            <div class="events-details-desc" id="content_html_div"></div>

                            <div class="products-details-desc">
                                <div class="products-meta">
                                    <span class="date cs-tiny">{{fixDate(newsEventData.createdAt)}}</span>
                                    <span>
                                        <h2 class=" text-capitalize cs-text-gray cs-tiny">
                                            <span *ngIf="newsEventData.type == 'event' ">{{newsEventData.status}}
                                                {{newsEventData.type}}</span>
                                            <span *ngIf="newsEventData.type == 'news' ">{{newsEventData.type}}</span>
                                        </h2>
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    </div>

    <div *ngIf="noData">
        <div class="page-title-area">
            <div class="container cs-display-container" style="height: 200px;">
                <div class="page-title-content">
                    <ul>
                        <li><a routerLink="/">Accueil</a></li>
                        <li><a routerLink="/news-and-events">News & Events</a></li>
                        <li>{{event_name}}</li>
                    </ul>
                    <h2>{{event_name}}</h2>

                </div>
                <img src="assets/img/illustrations/online_articles.svg" alt="" style="height: 180px;width: auto;"
                    class="cs-display-bottomright pt-10 cs-hide-small">
                <img src="assets/img/related/DBIMA_Butterfly.png" alt="Butterfly Icon"
                    style="height: 100px;width: auto;" class="cs-display-bottomleft pt-10">
            </div>

            <div class="shape9"><img src="assets/img/shape8.svg" alt="Lime color Plus Sign Icon"></div>
        </div>
        <div>
            <app-data-not-found></app-data-not-found>
        </div>

    </div>
</div>