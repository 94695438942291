<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Accueil</a></li>
                <li>Ouverture Internationale</li>
            </ul>
            <h2>Ouverture Internationale</h2>
        </div>
    </div>

    <div class="shape9"><img src="../assets/img/shape8.svg" alt="image" /></div>
</div>

<section class="products-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">

                    <img src="../assets/img/partner/Ouverture.jpg" alt="image" />
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="products-details-desc">
                    <h3>Ouverture Internationale</h3>
                    <p style="text-align: justify">Notre appartenance au réseau salésien nous permet de vivre une
                        dimension internationale qui nous invite à développer nos liens avec d’autres établissements
                        dans le monde.</p>
                    <p style="text-align: justify">Le collège Don Bosco Saint Dizier  va favoriser le développement
                        d’actions et de projets pour la meilleure ouverture internationale des jeunes, à chaque étape
                        de leur parcours, quel que soit leur âge, en relation avec des établissements salésiens en
                        intégrant le Campus International Don Bosco . En développant ces rencontres et les
                        mutualisations avec d’autres établissements salésiens du Campus International Don Bosco, le
                        collège Don Bosco Saint Dizier  renforcera son alliance avec les jeunes pour les inviter à
                        développer une meilleure connaissance de soi et leur confiance en soi.</p>
                    <p style="text-align: justify">Ces nouveaux projets dont certains pourront s’inscrire dans une
                        dimension solidaire, permettront à ces jeunes de sortir de leur quotidien social et scolaire et
                        d’aller à la rencontre de nouvelles cultures en s’appropriant d’autres modes de vie et en
                        approfondissant leurs compétences linguistiques.</p>
                    <p style="text-align: justify">Cette stratégie d’ouverture internationale permettra à nos jeunes de
                        relever les défis d’aujourd’hui et demain avec un regard positif sur le monde et sur notre
                        époque.</p>
                </div>
            </div>
        </div>
    </div>
</section>