<div class="banner-section">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="banner-content">
                    <h1>Project Management Certification Training </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="banner-image">
                    <img src="../assets/img/banner-img1.png" alt="image">

                    <div class="banner-shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/banner-shape4.png" alt="image"></div>
                    <div class="banner-shape5" data-speed="0.06" data-revert="true"><img src="../assets/img/banner-shape5.png" alt="image"></div>
                    <div class="banner-shape6" data-speed="0.06" data-revert="true"><img src="../assets/img/banner-shape6.png" alt="image"></div>
                    <div class="banner-shape7" data-speed="0.06" data-revert="true"><img src="../assets/img/banner-shape7.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="partner-area ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</section>

<section class="slogan-area mtb-100 bg-fffaf3 ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="slogan-image">
                    <img src="../assets/img/man2.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="slogan-content">
                    <p>Our project management training equips learners with the knowledge and discipline required to effectively plan, manage, execute, and control projects regardless of industry. You'll learn all about the most popular project management
                        methodologies that help organizations deliver successful projects.</p>
                    <h3>William James</h3>
                    <span class="sub-title">CEO at Odemy</span>
                </div>
            </div>
        </div>
    </div>

    <div class="divider2"></div>
    <div class="divider3"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/shape8.svg" alt="image"></div>
</section>

<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go at your own pace</span>
            <h2>Popular Project Management Courses</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box without-boxshadow">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="../assets/img/courses/img9.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                            <span>Alex Morgan</span>
                        </div>
                        <h3><a routerLink="/single-courses-2">PRINCE2® Practitioner Certification Training</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                            <li><i class='flaticon-people'></i> 145 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box without-boxshadow active">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="../assets/img/courses/img10.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses-2">PMI-RMP® Certification Training</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 100 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-courses-box without-boxshadow">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="../assets/img/courses/img11.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3><a routerLink="/single-courses-2">PRINCE2® Foundation Certification Training</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 150 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <a routerLink="/courses-2" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                    <p>Get into details now?​ <a routerLink="/courses-2">PM Master’s Program</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="about-area-two bg-fffaf3 pt-70 pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content-box">
                    <span class="sub-title">Distance Learning</span>
                    <h2>Build Your Project Management Skills Online, Anytime</h2>
                    <p>Want to learn and earn PDUs or CEUs on your schedule — anytime, anywhere? Or, pick up a new skill quickly like, project team leadership or agile? Browse our most popular online courses.</p>
                    <p><strong>Grow your knowledge and your opportunities with thought leadership, training and tools.</strong></p>
                    <a routerLink="/contact" class="link-btn">Explore Learning</a>
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="about-video-box">
                    <div class="image">
                        <img src="../assets/img/about-img5.jpg" alt="image">
                    </div>
                    <a href="https://www.youtube.com/watch?v=PWvPbGWVRrU" class="video-btn popup-youtube"><i class="flaticon-play"></i></a>
                    <div class="shape10" data-speed="0.06" data-revert="true"><img src="../assets/img/shape9.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="divider"></div>
    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape2" data-speed="0.06" data-revert="true"><img src="../assets/img/shape2.png" alt="image"></div>
</section>

<section class="funfacts-area bg-f5f7fa">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="1926">00</span></h3>
                    <p>Finished Sessions</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="3279">00</span></h3>
                    <p>Enrolled Learners</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="250">00</span></h3>
                    <p>Online Instructors</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts-item">
                    <h3><span class="odometer" data-count="100">00</span>%</h3>
                    <p>Satisfaction Rate</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go at your own pace</span>
            <h2>Project Management Master's Program</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box bg-color">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="../assets/img/courses/img6.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">PMP® Plus</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                            <li><i class='flaticon-people'></i> 145 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box bg-color">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="../assets/img/courses/img7.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">PMP® Renewal Pack</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 100 Students</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-courses-box bg-color">
                    <div class="courses-image">
                        <a routerLink="/single-courses-2" class="d-block image">
                            <img src="../assets/img/courses/img8.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <h3><a routerLink="/single-courses-2">Digital Project Manager</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                            <li><i class='flaticon-people'></i> 150 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape16"><img src="../assets/img/shape15.png" alt="image"></div>
</section>

<section class="advisor-area bg-f9f9f9 pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Instructor</span>
            <h2>Course Advisor</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="advisor-slides owl-carousel owl-theme">
            <div class="single-advisor-box">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="advisor-image">
                            <img src="../assets/img/advisor/img1.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8">
                        <div class="advisor-content">
                            <h3><a routerLink="/profile">William James</a></h3>
                            <span class="sub-title">Project Management Expert</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-advisor-box">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="advisor-image">
                            <img src="../assets/img/advisor/img2.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8">
                        <div class="advisor-content">
                            <h3><a routerLink="/profile">Sarah Taylor</a></h3>
                            <span class="sub-title">Agile Project Expert</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="single-advisor-box">
                <div class="row align-items-center">
                    <div class="col-lg-4 col-md-4">
                        <div class="advisor-image">
                            <img src="../assets/img/advisor/img3.jpg" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8">
                        <div class="advisor-content">
                            <h3><a routerLink="/profile">James Andy</a></h3>
                            <span class="sub-title">QA Project Expert</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol aliqua.</p>
                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="premium-access-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="premium-access-content">
            <span class="sub-title">Go at your own pace</span>
            <h2>Give their limitless potential unlimited access</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <a routerLink="/membership-levels" class="default-btn"><i class="flaticon-user"></i>Give Premium Access<span></span></a>
        </div>
    </div>

    <div class="shape3" data-speed="0.06" data-revert="true"><img src="../assets/img/shape3.png" alt="image"></div>
    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape8" data-speed="0.06" data-revert="true"><img src="../assets/img/shape7.png" alt="image"></div>
</section>

<section class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What People Say About Odemy</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="testimonials-slides owl-carousel owl-theme">
            <div class="single-testimonials-item">
                <img src="../assets/img/user1.jpg" class="client-img" alt="image">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <h3>John Smith</h3>
                <span>Python Developer</span>
                <div class="shape-img">
                    <img src="../assets/img/shape4.png" class="shape-1" alt="image">
                    <img src="../assets/img/shape14.png" class="shape-2" alt="image">
                    <img src="../assets/img/shape7.png" class="shape-3" alt="image">
                </div>
            </div>

            <div class="single-testimonials-item">
                <img src="../assets/img/user2.jpg" class="client-img" alt="image">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <h3>Sarah Taylor</h3>
                <span>PHP Developer</span>
                <div class="shape-img">
                    <img src="../assets/img/shape4.png" class="shape-1" alt="image">
                    <img src="../assets/img/shape14.png" class="shape-2" alt="image">
                    <img src="../assets/img/shape7.png" class="shape-3" alt="image">
                </div>
            </div>

            <div class="single-testimonials-item">
                <img src="../assets/img/user3.jpg" class="client-img" alt="image">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <h3>David Warner</h3>
                <span>QA Developer</span>
                <div class="shape-img">
                    <img src="../assets/img/shape4.png" class="shape-1" alt="image">
                    <img src="../assets/img/shape14.png" class="shape-2" alt="image">
                    <img src="../assets/img/shape7.png" class="shape-3" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="view-all-courses-area-two ptb-70 bg-fef8ef">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>Get ahead with Learning Paths. Stay Sharp.</h2>
                    <p>With The Open University you can study whenever and wherever you choose. We have students in over 128 countries, and a global reputation as a pioneer in the field of flexible learning. Our flexible teaching also means, if you travel
                        often or need to relocate, you can continue to study wherever you go.</p>
                    <a routerLink="/courses-2" class="default-btn"><i class="flaticon-agenda"></i>View All Courses<span></span></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-image">
                    <img src="../assets/img/woman-with-book.png" alt="image">
                    <div class="shape11" data-speed="0.06" data-revert="true"><img src="../assets/img/shape10.png" alt="image"></div>
                    <div class="shape12" data-speed="0.06" data-revert="true"><img src="../assets/img/shape11.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="shape1" data-speed="0.06" data-revert="true"><img src="../assets/img/shape1.png" alt="image"></div>
    <div class="shape9" data-speed="0.06" data-revert="true"><img src="../assets/img/shape8.svg" alt="image"></div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">News and Blogs</span>
            <h2>Check Out Our Latest Blog</h2>
            <p>We always give extra care to our student's skills improvements and feel excited to share our latest research and learnings!</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/blog/img1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-2" class="category">Education</a>
                        <h3><a routerLink="/single-blog-1">University Admissions Could Face Emergency Controls</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user1.jpg" class="rounded-circle" alt="image">
                                    <span>Alex Morgan</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/blog/img2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-2" class="category">Online</a>
                        <h3><a routerLink="/single-blog-1">Online Learning Can Prepare Students For A Fast-Changing</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user2.jpg" class="rounded-circle" alt="image">
                                    <span>Sarah Taylor</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 29, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post">
                    <div class="post-image">
                        <a routerLink="/single-blog-1" class="d-block">
                            <img src="../assets/img/blog/img3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="post-content">
                        <a routerLink="/blog-2" class="category">Learning</a>
                        <h3><a routerLink="/single-blog-1">As Learning Moves Online, Trigger Warnings Must Too</a></h3>
                        <ul class="post-content-footer d-flex justify-content-between align-items-center">
                            <li>
                                <div class="post-author d-flex align-items-center">
                                    <img src="../assets/img/user3.jpg" class="rounded-circle" alt="image">
                                    <span>David Warner</span>
                                </div>
                            </li>
                            <li><i class='flaticon-calendar'></i> April 28, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="blog-post-info">
                    <p>Get into details now?​ <a routerLink="/blog-2">View all posts</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>

    <div class="shape4" data-speed="0.06" data-revert="true"><img src="../assets/img/shape4.png" alt="image"></div>
    <div class="shape13" data-speed="0.06" data-revert="true"><img src="../assets/img/shape12.png" alt="image"></div>
    <div class="shape14" data-speed="0.06" data-revert="true"><img src="../assets/img/shape13.png" alt="image"></div>
    <div class="shape15" data-speed="0.06" data-revert="true"><img src="../assets/img/shape14.png" alt="image"></div>
</section>