<div class="navbar-area navArea tempNavBar" id="tempNavBar">
    <div
        class="row py-4 justify-content-center playfair-display-font text-center"
    >
        <h4 style="font-weight: 700">
            Le nouveau site du Collège Don Bosco Saint-Dizier est en cours de
            construction
        </h4>
    </div>
    <!-- <div
        class="row py-4 justify-content-center playfair-display-font text-center tempNavBarRow"
    >
        <div class="col-lg-5 col-md-7 text-right showDivBoarder">
            <h4 class="tempNav-title playfair-display-font text-center">
                Opening Soon..
            </h4>
        </div>
        <div class="col-lg-4 col-md-6 showDivBoarder mb-2">
            <p class="tempNavbar-data-title">Location:</p>
            <p class="tempNavbar-data">
                1 bis rue du Maréchal de Lattre de Tassigny 52100 SAINT DIZIER
            </p>
        </div>
        <div class="col-lg-3 col-md-5 mb-2">
            <p class="tempNavbar-data-title">Time:</p>
            <p class="tempNavbar-data">09h30 - 12h15 & 14h00 - 16h30</p>
        </div>
    </div>

    <div class="row justify-content-center tempNavBarRow mb-4" (click)="ggwp()">
        <div class="rounded-circle text-center tempNavCloseButton">
            <img
                src="../../../../assets/img/tempCross.png"
                style="width: 22px; padding-top: 0.7rem"
            />
        </div>
    </div> -->
</div>
