<div class="yoga-main-banner" style="height: 75%">
    <div class="container-fluid">
        <div
            class="content"
            style="
                width: 50%;
                text-align: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: rgba(0, 0, 0, 0.479);
                padding-top: 2rem;
                padding-bottom: 2rem;
                border-radius: 15px;
            "
        >
            <h1
                class="playfair-display-font text-white"
                style="font-size: 2.8rem; font-weight: 700"
            >
                Collège Salésien Don Bosco de Saint-Dizier
            </h1>
            <p style="color: white; padding-left: 1rem; padding-right: 1rem">
                Le collège Salésien Don Bosco de Saint-Dizier, acceuille des
                élèves à besoins éducatifs de différentes régions ce qui est
                possible grâce à un internat. Toute personne est accueillie et
                respectée dans sa dignité d’être humain, avec la richesse de son
                histoire personnelle. Le savoir-être est le cœur de ce
                développement éducatif.
            </p>

            <a
                href="https://preinscriptions.ecoledirecte.com/?RNE=0520968V&ETAB=04"
                target="_blank"
                class="default-btn tempButton"
            >
                <i class="flaticon-user"></i>
                Pré-inscriptions
                <span></span
            ></a>
            <br />
        </div>
    </div>
</div>

<section class="py-5 openDoors">
    <div class="row pt-4 pb-3 justify-content-center text-center tempNavBarRow">
        <div class="col-lg-4 text-right showDivBoarder">
            <h4 class="tempNav-title playfair-display-font text-center">
                Journée Portes Ouvertes
            </h4>
            <p class="text-white text-center my-1" style="font-weight: 700">
                09h30 - 12h15 & 14h00 -16h15
            </p>
            <p class="text-white text-center mb-1" style="font-weight: 700">
                5 MARS 2022
            </p>
        </div>
        <div class="col-lg-4 showDivBoarder mb-2">
            <p class="tempNavbar-data-title">Adresse:</p>
            <p class="tempNavbar-data">
                1 bis rue du Maréchal de Lattre de Tassigny 52100 SAINT DIZIER
            </p>
        </div>
        <div class="col-lg-4 mb-2" style="padding-top: 1.6rem">
            <a
                href="../../../../assets/pdf/Open House.pdf"
                class="px-2 py-2"
                target="_blank"
            >
                <button class="tempButton OpenDoorsBtn">
                    Télécharger le prospectus
                </button>
            </a>
        </div>
    </div>
</section>

<section class="training-area bg-f5f7fa pt-100 pb-5">
    <div
        class="container"
        data-aos="fade-up"
        data-aos-easing="linear"
        data-aos-duration="1500"
    >
        <div class="section-title">
            <h2 class="playfair-display-font">Système éducatif</h2>
            <p>
                Afin de renforcer les dispositifs pédagogiques autour de la
                mixité sociale et de l’école inclusive (respect des fragilités
                de chacun), les principales particularités du collège Don Bosco
                Saint-Dizier sont :
            </p>
        </div>

        <div class="row align-items-center justify-content-center">
            <div class="col-lg-10 col-md-10">
                <div class="about-content">
                    <ul class="features-list">
                        <li>
                            <span
                                ><i class="flaticon-self-growth"></i>Un effectif
                                plus réduit par classe</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="flaticon-self-growth"></i> Des
                                évaluations par compétences</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="flaticon-self-growth"></i> Des
                                classes flexibles</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="flaticon-self-growth"></i
                                >L’accompagnement avec des temps d’échanges
                            </span>
                        </li>
                        <li>
                            <span
                                ><i class="flaticon-self-growth"></i> La mise en
                                place d’ateliers de méthodologie adaptée</span
                            >
                        </li>
                        <li>
                            <span
                                ><i class="flaticon-self-growth"></i> Le
                                développement des apports socio-culturels dans
                                le cadre de projets spécifiques.
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="text-center ft3">
            <p>
                Cet accueil inconditionnel favorise le regard de confiance sur
                le potentiel de chaque jeune et sur sa réussite dans son
                parcours et sa formation. Aussi notre appartenance au réseau
                salésien nous permet de vivre une dimension internationale qui
                nous invite à développer nos liens avec d’autres établissements
                dans le monde.
            </p>
        </div>
    </div>

    <div class="tree-shape2">
        <img src="../assets/img/tree-shape2.png" alt="image" />
    </div>
    <div class="tree-shape3">
        <img src="../assets/img/tree-shape3.png" alt="image" />
    </div>
</section>

<section
    class="about-area bg-f5f7fa pb-100 text-center"
    style="padding-top: 20px"
>
    <div data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
        <div class="section-title">
            <h2 class="playfair-display-font">Téléchargements</h2>
        </div>

        <!-- <div class="row justify-content-center my-4">
            <div class="col-lg-3">
                <p class="tempDownTitle my-0">TARIFS COLLÈGE DON BOSCO</p>
                <a
                    href="../../../../assets/pdf/Rates.pdf"
                    class="px-2 py-2"
                    target="_blank"
                    ><button
                        class="btn fileDownBtn-red my-4"
                        style="width: 15rem; height: 5rem; font-weight: 700"
                    >
                        <i
                            class="bx bx-download text-white"
                            style="font-size: 25px"
                        ></i>
                        <p class="text-white">Voir les tarifs</p>
                    </button>
                </a>
            </div>
        </div> -->

        <div class="row justify-content-center">
            <div class="col-lg-6">
                <p class="tempDownTitle my-0">EMPLOIS DU TEMPS</p>
                <a
                    href="../../../../assets/pdf/EDT 6 DB.pdf"
                    class="px-2 py-2"
                    target="_blank"
                    ><button
                        class="btn fileDownBtn-blue"
                        style="width: 10rem; height: 5rem; font-weight: 700"
                    >
                        <i
                            class="bx bx-download text-white"
                            style="font-size: 25px"
                        ></i>
                        <p class="text-white">EDT 6ème</p>
                    </button>
                </a>

                <a
                    href="../../../../assets/pdf/EDT 5 DB.pdf"
                    class="px-2 py-2"
                    target="_blank"
                    ><button
                        class="btn fileDownBtn-blue"
                        style="width: 10rem; height: 5rem; font-weight: 700"
                    >
                        <i
                            class="bx bx-download text-white"
                            style="font-size: 25px"
                        ></i>
                        <p class="text-white">EDT 5ème</p>
                    </button>
                </a>
                <a
                    href="../../../../assets/pdf/EDT 4 DB.pdf"
                    class="px-2 py-2"
                    target="_blank"
                    ><button
                        class="btn fileDownBtn-blue my-4"
                        style="width: 10rem; height: 5rem; font-weight: 700"
                    >
                        <i
                            class="bx bx-download text-white"
                            style="font-size: 25px"
                        ></i>
                        <p class="text-white">EDT 4ème</p>
                    </button>
                </a>
                <a
                    href="../../../../assets/pdf/EDT 3 DB.pdf"
                    class="px-2 py-2"
                    target="_blank"
                    ><button
                        class="btn fileDownBtn-blue my-4"
                        style="width: 10rem; height: 5rem; font-weight: 700"
                    >
                        <i
                            class="bx bx-download text-white"
                            style="font-size: 25px"
                        ></i>
                        <p class="text-white">EDT 3ème</p>
                    </button>
                </a>
            </div>
        </div>
    </div>
</section>
