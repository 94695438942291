
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/pages/home/home.component';

import { GalleryComponent } from './components/pages/gallery/gallery.component';

import { NewsAndEventsComponent } from './components/pages/news-and-events/news-and-events.component';
import { NewsAndEventDetailsComponent } from './components/pages/news-and-events/news-and-event-details/news-and-event-details.component';

import { OpeningComponent } from './components/pages/opening/opening.component';
import { PedagogyComponent } from './components/pages/pedagogy/pedagogy.component';
import { TimetableComponent } from './components/pages/timetable/timetable.component';
import { HistoryComponent } from './components/pages/history/history.component';
import { RegistrationComponent } from './components/pages/registration/registration.component';
import { ContactUsComponent } from './components/pages/contact-us/contact-us.component';
import { TemporaryComponent } from './components/pages/temporary/temporary.component';
import { InternatComponent } from './components/pages/internat/internat.component';

import { PrivacyPolicyComponent } from './components/pages/company/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/pages/company/terms-and-conditions/terms-and-conditions.component';

import { ErrorComponent } from './components/pages/error/error.component';


const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'gallery', component: GalleryComponent },
    { path: 'opening', component: OpeningComponent },
    { path: 'pedagogy', component: PedagogyComponent },
    { path: 'timetable', component: TimetableComponent },
    { path: 'history', component: HistoryComponent },
    { path: 'registration', component: RegistrationComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'news-and-events', component: NewsAndEventsComponent },
    { path: 'news-and-events-details/:news_id/:news_title', component: NewsAndEventDetailsComponent },
    { path: 'temporary', component: TemporaryComponent },
    { path: 'internat', component: InternatComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'terms-and-conditions', component: TermsAndConditionsComponent },

    { path: 'contact', component: ContactUsComponent },

    { path: 'error', component: ErrorComponent },
    { path: "**", component: ErrorComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
